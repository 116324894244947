<template>
    <div>
		<div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
		<div v-else>
			<vs-row vs-w="12" vs-justify="center" vs-align="center">
				<vs-col vs-w="4">
					<indicator-subtitle :value="'$ ' + data.graphicsHeadersData.shippingCost.total.value" subtitle="Total Shipping Cost" v-if="data.graphicsHeadersData.shippingCost.total.value > 1000"/>
					<indicator-subtitle :value="'$ ' + data.graphicsHeadersData.shippingCost.total.value" subtitle="Total Shipping Cost" v-else/>
				</vs-col>
				<vs-col vs-w="8">
					<indicator-title title="Avg Shipping Cost/Order" colorClass="blue1" :value="'$ ' + data.graphicsHeadersData.shippingCost.average.value" :delta="data.graphicsHeadersData.shippingCost.average.delta"/>
					<indicator-title title="Avg Shipping Rev/Order" colorClass="blue2" :value="'$ ' + data.graphicsHeadersData.revenue.average.value" :delta="data.graphicsHeadersData.revenue.average.delta"/>
				</vs-col>
			</vs-row>
			<vs-divider></vs-divider>
			<vs-row vs-w="12" vs-justify="center" vs-align="center">
				<line-chart :labels="data.revenueLabels" :data="data.dataOrderChart" :options="data.options"/>
			</vs-row>
		</div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {
    props:['title'],
    data() {
        return {
            data: {
                graphicsHeadersData: [],
                revenueLabels:[],
                dataOrderChart:[],
                options: {              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{position:'bottom'},
                    scales: {
                        xAxes: [{
                            display: false
                        }],
                        yAxes: [{
                            gridLines: {
                                color: "#f6f6f6",
                            }
                        }]
                    }
                },
			},
			loading: false
        }
    },
    mounted() {
        this.fetch();
		this.$root.$on('update', this.fetch);
    },
    methods: {
        fetch() {
			this.loading = true;
			this.data.graphicsHeadersData = [];
			this.data.revenueLabels = [];
			this.data.dataOrderChart = [];
			
            let configMetrics = {
				fromDate:moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate:moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
				compare: this.$store.state.comparison
            }

			if(this.$store.state.comparison == 'PreviousPeriod'){
				let oneDay = 24*60*60*1000;
				let from = new Date(this.$store.state.from);
				let to = new Date(this.$store.state.to);

				let diff = Math.round(Math.abs((from.getTime() - to.getTime())/(oneDay)))

				configMetrics = {
					// fromDate: moment(new Date(this.$store.state.from).setDate(from.getDate() - diff)).format('MM/DD/YYYY'),
					fromDate: moment(new Date(this.$store.state.from).setDate(from.getDate())).format('MM/DD/YYYY'),
					// toDate: moment(new Date(this.$store.state.to).setDate(to.getDate() - diff)).format('MM/DD/YYYY'),
					toDate: moment(new Date(this.$store.state.to).setDate(to.getDate())).format('MM/DD/YYYY'),
					compare: this.$store.state.comparison
				}
			}

			if(this.$store.state.comparison == 'PreviousYear'){
				let from = new Date(this.$store.state.from);
				let to = new Date(this.$store.state.to);

				configMetrics = {
					// fromDate: moment(new Date(this.$store.state.from).setFullYear(from.getFullYear() - 1)).format('MM/DD/YYYY'),
					fromDate: moment(new Date(this.$store.state.from).setFullYear(from.getFullYear())).format('MM/DD/YYYY'),
					// toDate: moment(new Date(this.$store.state.to).setFullYear(to.getFullYear() - 1)).format('MM/DD/YYYY'),
					toDate: moment(new Date(this.$store.state.to).setFullYear(to.getFullYear())).format('MM/DD/YYYY'),
					compare: this.$store.state.comparison	
				}
			}

			axiosInstance("GET", process.env.VUE_APP_ENDPOINT+ 'orders/metrics', configMetrics).then((response) => {
                this.data.graphicsHeadersData = response.data;
            });

            let configShippedForBiggerAverageRevenue = {
				fromDate:moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate:moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
				compare: this.$store.state.comparison
			}

			axiosInstance("GET", process.env.VUE_APP_ENDPOINT+ 'orders/metrics/details', configShippedForBiggerAverageRevenue).then((response) => {
				let data = response.data;
				let qty = [];
				let qty2 = [];
				this.revenueLabels = [];
				
				data.currentData.forEach((item) => {
					this.data.revenueLabels.push(item.date)
					qty.push(item.averageCost);
					qty2.push(item.averageRevenue);
				})
				

				this.data.dataOrderChart.push({
					label: 'Avg Shipping Cost/Order',
					borderColor: '#59BAEF',
					backgroundColor: '#59BAEF',
					fill:false,
					tension:0,
					data: qty
				});

				this.data.dataOrderChart.push({
					label: 'Avg Revenue/Order',
					borderColor: '#1f66a6',
					backgroundColor: '#1f66a6',
					fill:false,
					tension:0,
					data: qty2
				});
				this.loading = false;
                this.$root.$emit('finishedFetch');
			});
        }
    }
}
</script>


<template>
  <div class="sidebar-footer">
     
  </div>
</template>

<script> 
export default {
  name: 'SideNavFooter',
  components: {  },
  props: {
    showQuickSetup: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>


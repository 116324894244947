<template>
  <header class="container-fluid table-page-header">
    <div class="row">
      <div class="col-auto heading">
        <h2>{{ heading }}</h2>
        <slot name="heading-link"></slot>
      </div>
      <div class="col search" v-if="showSearch">
        <slot name="search"></slot>
      </div>
      <div class="col actions">
        <slot name="action"></slot>
      </div>
    </div>
    <div class="row" v-show="description">
      <div class="col description">
        <p>{{ description }}</p>
      </div>
    </div>
    <div class="row" v-show="paragraph1">
      <div class="col description">
        <p>{{ paragraph1 }}</p>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'BaseHeader',
  props: {
    heading: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    paragraph1: {
      type: String,
      required: false
    },
    showSearch:false
  }
}
</script>

<style scoped lang="scss">
  @import '@/../assets/scss/shipbob-custom/components/dashboard/_base-header-template.scss';
</style>

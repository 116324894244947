<template>
  <div>
    <h4 class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        {{str}}
        <info
          style="margin-left:5px;"
          v-if="paragraph !== undefined"
          :header="header"
          :paragraph="paragraph"
          :paragraph1="paragraph1"
          :paragraph2="paragraph2"
          :paragraph3="paragraph3"
          :footer="footer"
        ></info>
      </div>
      <div style="display: flex; align-items: center;">
        <div style="margin-right: 10px" v-if="dataName !== undefined">
         
          <download-data-button :dataName="dataName"></download-data-button>
        
        </div>
        <div style="display: flex; align-items: center;">
          <div
            style="margin-right: 10px"
            v-if="topRightText !== undefined && dataName === undefined"
          >
            <h6 class="h6-Color">{{topRightText}}</h6>
          </div>
        </div>
      </div>
    </h4>
  
    <div
      style="display: flex; align-items: center;justify-content: flex-end;"
      v-if="topRightText !== undefined && dataName !== undefined"
    >
      <div style="margin-right: 10px">
        <h6 class="h6-Color">{{topRightText}}</h6>
      </div>
    </div>
  </div>
</template>
<script>
import json2csv from "csvjson-json2csv";
//const json2csv = require('./json2csv.js');
export default {
  props: [
    "str",
    "header",
    "paragraph",
    "paragraph1",
    "paragraph2",
    "paragraph3",
    "footer",
    "ttPosition",
    "dataName",
    "topRightText"
  ],
  data() {
    return {
     
    };
  },
  mounted() { 
  },
  methods: {
     
  }
};
</script>

<style scoped lang='scss'>
.h6 {
  font-size: 1rem;
  margin-bottom: 0px;
  text-align: center;
}
.h6-Color {
  font-size: 0.75rem;
  margin-bottom: 0px;
  text-align: center;
  color: #b1b1b1;
}
</style>
 


import { render, staticRenderFns } from "./BreakDown.vue?vue&type=template&id=3e4b9ff8&scoped=true&"
var script = {}
import style0 from "./BreakDown.vue?vue&type=style&index=0&id=3e4b9ff8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e4b9ff8",
  null
  
)

component.options.__file = "BreakDown.vue"
export default component.exports
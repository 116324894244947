<template>
    <div>
		<div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
		<div v-else>
			<vs-row vs-w="12" vs-justify="center" vs-align="center">
				<vs-col vs-w="4">
					<vs-row vs-justify="center" vs-offset="">
						<vs-col vs-w="12">
							<indicator-subtitle :value="data.graphicsHeadersData.shipped.total.value" subtitle="Total Orders" :colorClass="'blue-primary'"/>
						</vs-col>
					</vs-row>
				</vs-col>
				<vs-col vs-w="7">
					<indicator-title title="Avg Shipping Zone" :value="data.graphicsHeadersData.shippingZone.average.value" :delta="data.graphicsHeadersData.shippingZone.average.delta"/>
					<indicator-title title="Avg Units/Order" :value="data.graphicsHeadersData.unit.average.value" :delta="data.graphicsHeadersData.unit.average.delta"/>
				</vs-col>
			</vs-row>
			<vs-divider></vs-divider>
			<vs-row vs-w="12" vs-justify="center" vs-align="center">
				<line-chart :labels="data.configShippedLabels" :data="data.dataShippedChart" :options="data.options"/>
			</vs-row>
		</div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {
    props:['title'],
    data() {
        return {
            data: {
                graphicsHeadersData: [],
                dataShippedChart: [],
                strLabelPrevious:'',
                strLabelCurrent: '',
                configShippedLabels:[],
                options: {              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{position:'bottom'},
                    scales: {
                        xAxes: [{
                            display: false
                        }],
                        yAxes: [{
                            gridLines: {
                                color: "#f6f6f6",
                            }
                        }]
                    }
                },
			},
			loading: false
        }
    },
    mounted() {
		this.fetch();
		this.$root.$on('update', this.fetch);
    },
    methods: {
        fetch() {
			this.loading = true;
			this.data.graphicsHeadersData = [];
			this.data.dataShippedChart = [];
			this.data.configShippedLabels = [];
			
            let configMetrics = {
				fromDate:moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate:moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
				compare: this.$store.state.comparison
            }
            
            let configShippedForBiggerGraphicsPrevious = {
				fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
				compare: this.$store.state.comparison
			}

			if(this.$store.state.comparison == 'PreviousPeriod'){
				let oneDay = 24*60*60*1000;
				let from = new Date(this.$store.state.from);
				let to = new Date(this.$store.state.to);

				let diff = Math.round(Math.abs((from.getTime() - to.getTime())/(oneDay)))

				configMetrics = {
					// fromDate: moment(new Date(this.$store.state.from).setDate(from.getDate() - diff)).format('MM/DD/YYYY'),
					fromDate: moment(new Date(this.$store.state.from).setDate(from.getDate())).format('MM/DD/YYYY'),
					// toDate: moment(new Date(this.$store.state.to).setDate(to.getDate() - diff)).format('MM/DD/YYYY'),
					toDate: moment(new Date(this.$store.state.to).setDate(to.getDate())).format('MM/DD/YYYY'),
					compare: this.$store.state.comparison
				}
			}

			if(this.$store.state.comparison == 'PreviousYear'){
				let from = new Date(this.$store.state.from);
				let to = new Date(this.$store.state.to);

				configMetrics = {
					fromDate: moment(new Date(this.$store.state.from).setFullYear(from.getFullYear())).format('MM/DD/YYYY'),
					toDate: moment(new Date(this.$store.state.to).setFullYear(to.getFullYear())).format('MM/DD/YYYY'),
					compare: this.$store.state.comparison	
				}
			}

			axiosInstance("GET", process.env.VUE_APP_ENDPOINT+ 'orders/metrics', configMetrics).then((response) => {
                this.data.graphicsHeadersData = response.data;
            });


			if(this.$store.state.comparison == 'PreviousPeriod'){
				let oneDay = 24*60*60*1000;
				let from = new Date(this.$store.state.from);
				let to = new Date(this.$store.state.to);

				let diff = Math.round(Math.abs((from.getTime() - to.getTime())/(oneDay)))

				configShippedForBiggerGraphicsPrevious = {
					fromDate: moment(new Date(this.$store.state.from).setDate(from.getDate() - diff)).format('MM/DD/YYYY'),
					// fromDate: moment(new Date(this.$store.state.from).setDate(from.getDate())).format('MM/DD/YYYY'),
					toDate: moment(new Date(this.$store.state.to).setDate(to.getDate() - diff)).format('MM/DD/YYYY'),
					// toDate: moment(new Date(this.$store.state.to).setDate(to.getDate())).format('MM/DD/YYYY'),
					compare: this.$store.state.comparison
				}

				this.data.strLabelPrevious = moment(new Date(configShippedForBiggerGraphicsPrevious.fromDate)).format('MMM Do YY') + '-' + moment(new Date(configShippedForBiggerGraphicsPrevious.toDate)).format('MMM Do YY');
			}

            let configShippedForBiggerGraphics = {
				fromDate:moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate:moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
				compare: this.$store.state.comparison
			}

			this.data.strLabelCurrent = moment(new Date(this.$store.state.from)).format('MMM Do YY') + '-' + moment(new Date(this.$store.state.to)).format('MMM Do YY');
			
			axiosInstance("GET", process.env.VUE_APP_ENDPOINT+ 'orders/completed', configShippedForBiggerGraphics).then((response) => {
				let data = response.data;
				let qty = [];
                let qty2 = [];

				data.histories.forEach((item) => {
					this.data.configShippedLabels.push(item.date);
					qty.push(item.previousShipped);
					qty2.push(item.shipped);
				})

				this.data.dataShippedChart.push({
					label: this.data.strLabelPrevious,
					borderColor: '#1f66a6',
					backgroundColor: '#1f66a6',
					fill:false,
					tension:0,
					data: qty
				});

				this.data.dataShippedChart.push({
					label: this.data.strLabelCurrent,
					borderColor: '#59BAEF',
					backgroundColor: '#59BAEF',
					fill:false,
					tension:0,
					data: qty2
				})
				this.loading = false;
            	this.$root.$emit('finishedFetch');

			});
        }
    }
}
</script>


<template>
    <div class="accotation-item">
        <vs-row vs-justify="center">
            <vs-col class="centered" vs-justify="center">
                <h4 class="accotation-title">{{title}}</h4>
                <span> {{percentage}} %</span>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['color', 'title', 'percentage', 'requiredDropdown', 'showPercent'],
    data() {
        return {
            isActive: false,
            enabled: true,
            selectedItem:0,
            items: [{
                value:1,
                text: "NO"
            },
            {
                value:0,
                text: "YES"
            }]
        }
    },
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        toggleAccotation() {
            this.isActive = false;
            
            if(this.selectedItem === 0) {
                this.enabled = false;
            } else {
                this.enabled = true;
            }

            let varName = "";


            if(this.title !== undefined){
                varName = 'setideal_' + this.title.toLowerCase();
            } else {
                varName = "default"
            }
            this.$store.commit(varName, this.selectedItem);

            this.$root.$emit("accotationChanged");
        }
    } 
}
</script>
<style scoped lang='scss'>
 
 .h6{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
 }
  .h6-Color{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
    color: #b1b1b1;
 }

</style>
 

import { render, staticRenderFns } from "./OrderStatus.vue?vue&type=template&id=1a45236e&scoped=true&"
import script from "./OrderStatus.vue?vue&type=script&lang=js&"
export * from "./OrderStatus.vue?vue&type=script&lang=js&"
import style0 from "./OrderStatus.vue?vue&type=style&index=0&id=1a45236e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a45236e",
  null
  
)

component.options.__file = "OrderStatus.vue"
export default component.exports
<template>
    <div>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                <v-select :options="items" label="text" v-model="selected" placeholder="Inventory Number ID" @input="changeItem()" style="width:100%; padding: 10px"></v-select>
            </vs-col>            
        </vs-row>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    props:['items'],
    data() {
        return {
            selected:0,
            loading: false
        }
    },
    methods: {
        update() {
            this.loading = true;
            this.$root.$emit('updateSalesSku');
        },
        changeItem() {
            this.$store.commit('setsales_itemID', this.selected.value);
        },
    },
    mounted() {
        this.$root.$on('finishedFetch', () => { this.loading = false; });
    }
}
</script>


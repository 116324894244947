<template>
    <div> 
        <vs-row vs-w="12" class="my-3" vs-type="flex" vs-justify="space-between" vs-align="center">
            <vs-col vs-w="12">
                <b-form inline vs-justify="space-between">
                    <sb-dropdown :options="options1" v-model="selectOptions"  class="dropdown-filter filter-shipping-method-small" placeholder="All shipping methods" >
                        <sb-selected-option slot="selection" slot-scope="{ selected }" >
                            {{selectOptions1.length }} Filters Selected
                        </sb-selected-option>
                        <sb-options>
                            <sb-option slot-scope="{ option }" :value="option">
                                {{ option.text }}
                            </sb-option>
                        </sb-options>
                    </sb-dropdown> 
                </b-form>
            </vs-col>
        </vs-row>
        <vs-row vs-w="12" class="mb-3" vs-type="flex" vs-justify="space-between" vs-align="center">
            <vs-col vs-w="12">
                <SbDismissibleChip v-for="f of selectOptions1" v-on:close="removeSelectedOption(f)" class="SbDismissibleChip-wrapper">
                    {{f.text}}
                </SbDismissibleChip>
            </vs-col>
        </vs-row>
     
                    <sb-table
      :columnDefinitions="columnDefinitions"
      :rows="tableHelper.pagedData"
      :loadingPromise="loadingPromise"
      @sort="handleSort"
      :defaultSortColumn="defaultSortColumn"
      :defaultSortOrdering="defaultSortOrdering"
    >
      <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
      
         
      </sb-toolbar>
      <sb-table-row slot="sb-table-row" slot-scope="{row}"> 
        <sb-table-cell  >
                                    {{row.state}}
                              </sb-table-cell>
                                 <sb-table-cell  >
                                    {{row.totalOrders}}
                             </sb-table-cell>
                               <sb-table-cell  >
                                    {{row.percentageOfTotal}} %
                               </sb-table-cell>
                                <sb-table-cell  >
                                   $ {{row.avgShippingCost}}
                               </sb-table-cell>
                               <sb-table-cell  >
                                    {{row.avgDaysInTransit}}
                              </sb-table-cell>
                                <sb-table-cell  >
                                    {{row.avgZone}}
                              </sb-table-cell>
      </sb-table-row>
      <sb-table-empty-state slot="sb-table-empty-state" icon-name="products" label="No Results">
        <p>Select inventory to see all inventory movements for the specified period.</p>
      </sb-table-empty-state>
      <pagination-wrapper
        slot="pagination"
        @fetchPage="handleFetchPage"
        :pageSize="tableHelper.pagination.pageSize"
        :currentPage="tableHelper.pagination.currentPage"
        :totalPages="tableHelper.pagination.totalPages"
        :totalRecords="tableHelper.pagination.totalRecords"
        :visibleRecords="tableHelper.pagination.visibleRecords"
      ></pagination-wrapper>
    </sb-table> 
 
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment';
import Vue from 'vue';
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
    components: {
    "pagination-wrapper": PaginationWrapper
  },
    data() {
        return {
            selectedDates: '',
            options1: [],
            selectOptions1: [],  
            loadingPromise: null,
      columnDefinitions: [
        { name: "State", field: "state", sortable: true }, 
         { name: "Order CT", field: "totalOrders", sortable: true }, 
          { name: "% of Total", field: "percentageOfTotal", sortable: true }, 
           { name: "Avg Shipping Cost", field: "avgShippingCost", sortable: true }, 
            { name: "Avg Business Days in Transit", field: "avgDaysInTransit", sortable: true }, 
             { name: "Avg Zone", field: "avgZone", sortable: true }, 
      ],
      tableHelper: new tabler(this, 12)
        }
    },
     mounted() {
    this.loadingPromise = this.fetch(1);
     this.getSelectsOptions();
        this.getSelectedDates()
        this.$root.$on('update', this.update); 
  }, 
    computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    },
     selectOptions: {
                get () {
                return this.selectOptions1
                },
                set (selection) {   
                this.addSelection(selection);
                this.update();
                }
            } 
  },
    methods: { 
         handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    map(item) {
      return {
             state: item.state,
                        totalOrders: this.numberWithCommas(Math.round(item.totalOrders)),
                        percentageOfTotal: this.numberWithCommas(Math.round(item.percentageOfTotal)),
                        avgShippingCost: this.numberWithCommas(item.avgShippingCost.toFixed(2)),
                        avgDaysInTransit: this.numberWithCommas(item.avgDaysInTransit.toFixed(1)),
                        avgZone: this.numberWithCommas(item.avgZone.toFixed(1)),
      };
    },
        removeSelectedOption(filter){ 
            const index = this.selectOptions1.findIndex(f => f.text == filter.text)
            this.selectOptions1.splice(index, 1);
            this.fetch(1);
        },
         addSelection(selection)
        { 
            this.selectOptions1=[];
            selection.forEach(item=>{
                this.selectOptions1.push(item);
            }); 
        },
        numberWithCommas(x) { 
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        changeMaxItems() {
            if(this.maxItemsAux > 0){
                this.maxItems = this.maxItemsAux;
            }
        },
        getSelectedDates(){
             let config = {
                fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY')
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/sum/0', config).then((response) => {
                let data = response.data.data;
                this.selectedDates= data.selectedDates;
            });
        },
        getSelectsOptions () {
            this.options1 = [];

            let storesShippingConfig = {
				from: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
                to: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
                // from: '2019-01-01',
                // to: '2019-01-20',
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'Lookup/storeshippingmethods', storesShippingConfig).then((response) => {
                let data = response.data; 
                data.data.forEach((item) => {
                    this.options1.push({ text: item.displayValue, value: item.storeShippingMethod})
                });
            });
        },
        update(){
           this.loadingPromise =  this.fetch(1);
            this.getSelectsOptions();
            this.getSelectedDates(); 
        },
        load() {
         this.loadingPromise = this.fetch(1);
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    }, 
    fetch: async function(p) {
      await new Promise(res => {
        this.tableHelper.preLoadReset();

            let strStoreShippingMethod  = '';
            if(this.selectOptions1.length > 0){
                this.selectOptions1.forEach((item, index) => {
                    strStoreShippingMethod += item.value;
                    if(index < (this.selectOptions1.length -1)){
                        strStoreShippingMethod += ',';
                    }
                });
            } else {
                strStoreShippingMethod  = '0';
            }


            let config = {
                from: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
                to: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
                storeShippingMethod: strStoreShippingMethod
                // fromDate:' 2019-01-01',
                // toDate: '2019-01-25',
                // storeShippingMethod: 'Standard International'
            }



            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/tracking/daysInTransitbyShippingMethodState', config).then((response) => {
                let data = response.data.data;
                
                 this.tableHelper.loadData(data, p); 
                this.$store.commit('setDataStateDetails', this.tableHelper.receivedData);
                this.$root.$emit('finishedFetch');
                res(true);
        }); 
      });
        }
    }
}
</script>

export const mapLoggedInAndImpersonatedUserFromApi = ({
    ImpersonatedUser, LoggedInUser, preLaunchUser
  }) => ({
    UserId: LoggedInUser.UserId,
    UserRoleId: LoggedInUser.UserRole,
    CityID: LoggedInUser.CityID,
    FirstName: LoggedInUser.FirstName,
    LastName: LoggedInUser.LastName,
    Email: LoggedInUser.Email,
    LastCompletedStep: LoggedInUser.LastCompletedStep,
    ImpersonatedUserId: ImpersonatedUser.UserId,
    ImpersonatedEmail: ImpersonatedUser.Email,
    IsAdmin: LoggedInUser.IsAdmin,
    preLaunchUser
  })
  
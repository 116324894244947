<template>
    <div>
         <sb-toolbar slot="sb-toolbar" class="customers-toolbar"> 
      <from-date-filter></from-date-filter>
     <span class="date-filter-span"> to </span>
       <to-date-filter></to-date-filter>  
      <sb-button @click="update" variation="primary" size="medium" @click.prevent>Apply</sb-button> 
    </sb-toolbar> 
    </div>
</template>
<script>
import moment from 'moment';


export default {
    props:['items', 'items2', 'additionals', 'kind', 'name1', 'name2'],
    data() {
        return {
            loading:false
        }
    },
    mounted() {
        this.$root.$on('finishedFetch', () => { this.loading = false; });
    },
    methods:{
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        update(){
            this.loading = true;
            this.$root.$emit('updateOrderTurnAroundTime');
        }
    },
    watch:{
        selected1 : (val) => {

        }
    }
}
</script>

</script>
 <style scoped lang='scss'>
.customers-toolbar {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
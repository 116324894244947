import Vue from 'vue';
import Router from 'vue-router';
import Login from './components/Login';
import DashboardV2 from './components/DashboardV2';
import SlaExceptions from './components/SlaExceptions';
import UndeliveredOrders from './components/UndeliveredOrders';
import performance from './components/performance';
import OnHandRedirectToInventoryStatus from './components/OnHandRedirectToInventoryStatus';
import Reconciliation from './components/Reconciliation';
import History from './components/History';
import Skuperformance from './components/Skuperformance';
import Planning from './components/Planning';
import MapAndDistribution from './components/MapAndDistribution';
import ShopifyProfitability from './components/ShopifyProfitability';
import BreakDown from './components/BreakDown';
import Storage from './components/Storage';
import Reports from './components/Reports';
import Forbidden from './components/general/Forbidden';
import store from './store';
import { ThirdPartyToolsManager } from '@/utils/thirdPartyToolsManager';
import VendorsManager from '@/utils/vendorsManager';

Vue.use(Router);

let router = new Router({
    mode:'history',
    routes: [      
        {
            path: '/',          
            name: 'Login',
            component: Login,             
        },
        {
          path: '/dashboard',
          component: DashboardV2,
          name: 'Dashboard'
        },
        {
          path: '/fulfillment/sla-exceptions',
          component: SlaExceptions,
          name: 'SlaExceptions'
        },
        {
          path: '/shipping/undelivered-shipments',
          component: UndeliveredOrders,
          name: 'UndeliveredOrders'
        },
        {
          path: '/shipping/performance',
          component: performance,
          name: 'performance'
        },
        {
          path: '/inventory/on-hand',
          component: OnHandRedirectToInventoryStatus,
          name: 'OnHand'
        },
        {
          path: '/inventory/reconciliation',
          component: Reconciliation,
          name: 'Reconciliation'
        },
        {
          path: '/inventory/history',
          component: History,
          name: 'History'
        },
        {
          path: '/inventory/sku-performance',
          component: Skuperformance,
          name: 'Skuperformance'
        },
        {
          path: '/inventory/planning',
          component: Planning,
          name: 'Planning'
        },
        {
          path: '/inventory/map-and-distribution',
          component: MapAndDistribution,
          name: 'MapAndDistribution'
        },
        {
          path: '/accounting/profitability',
          component: ShopifyProfitability,
          name: 'ShopifyProfitability'
        },
        {
          path: '/accounting/break-down',
          component: BreakDown,
          name: 'BreakDown'
        },
        {
          path: '/accounting/storage',
          component: Storage,
          name: 'Storage'
        },
        {
          path: '/reports',
          component: Reports,
          name: 'Reports'
        },
        {
          path:'/Forbidden',
          component: Forbidden,
          name: 'Forbidden'
        }
    ]
});

const billingRestrictedRoutes = [ "Storage", "BreakDown", "ShopifyProfitability", "performance"];

const authenticated = async (routeTo) => { 
    if (store.state.authenticated) {
      return true
    }
    return await  store.dispatch('authenticate')
  }


const setupSettings = async (routeTo) => {
    if (!ThirdPartyToolsManager.initialized) { 
      ThirdPartyToolsManager.init({
        appSettings: { pendoOn: true},
        userData: await store.dispatch('fetchDetailedUser')
      })
    }
    ThirdPartyToolsManager.routeTo(routeTo)

    VendorsManager.start()
}

const isRestricted = (routeTo) => {  
  return store.state.isBillingRestrictedCustomer && billingRestrictedRoutes.includes(routeTo);
}

router.beforeEach(async (to, from, next) => {
   const routeTo = to.name 

    if(await authenticated(routeTo)){
      await setupSettings(routeTo)
    }else {
      await store.dispatch('fetchDetailedUser')
    }
   
    if(isRestricted(routeTo)) {
      next({ name: 'Forbidden' });
   } else {
      next()
   }   
})
  

export default router;
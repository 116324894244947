var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{staticClass:"search-bar-2",attrs:{"inline":""}},[_c('b-input-group',{staticClass:"my-2 mr-sm-2 mb-sm-0",attrs:{"left":"@"}},[_c('date-filter')],1)],1),_c('b-form',{staticClass:"search-bar-2 my-2",attrs:{"inline":""}},[_c('div',{staticStyle:{"padding":"2em","background-color":"#f9fafb"}},[_c('sb-dropdown-search',{staticClass:"select-size-400",attrs:{"options":_vm.options1,"minSearchTermLength":0,"showSelectedOptions":true,"comparator":_vm.comparator,"loading":false,"placeholder":"Search by inventory name, or ShipBob ID.."},on:{"input":function($event){_vm.changeItem1()},"search":_vm.handleSearch},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
return _c('sb-selected-dropdown-search-option',{staticClass:"selection-placeholder",attrs:{"value":selection.text || 'Search by inventory name, or ShipBob ID..'}})}},{key:"default",fn:function(ref){
var option = ref.option;
return _c('sb-dropdown-option',{},[_vm._v(_vm._s(option.value==0?option.text: option.value + " - " + option.text)+"  ")])}}]),model:{value:(_vm.selected1),callback:function ($$v) {_vm.selected1=$$v},expression:"selected1"}})],1),_c('div',{staticStyle:{"padding":"2em","background-color":"#f9fafb"}},[_c('sb-dropdown',{staticClass:"select-size-200",attrs:{"options":_vm.items2,"placeholder":"Select a fulfillment center.."},on:{"input":function($event){_vm.changeItem2()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selected = ref.selected;
return _c('sb-selected-option',{},[_vm._v("\n            "+_vm._s(selected.text)+"\n          ")])}}]),model:{value:(_vm.selected2),callback:function ($$v) {_vm.selected2=$$v},expression:"selected2"}},[_c('sb-options',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return _c('sb-option',{attrs:{"value":option}},[_vm._v("\n             "+_vm._s(option.text)+"\n            ")])}}])})],1)],1),_c('b-input-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"left":"@"}},[_c('sb-button',{attrs:{"variation":"primary","size":"medium"},on:{"click":[_vm.update,function($event){$event.preventDefault();}]}},[_vm._v("Apply")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
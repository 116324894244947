<template>
    <div>
        <vs-row vs-w="12" vs-justify="left" vs-align="center">
            <vs-col vs-w="12">
                <p > 
                Deviations may occur at packaging due to manual measurements when not all items are cubic scanned with validated dimmensions for the order. 
<br>The list below provides orders with deviations to the most frequent billable weight used. <br>
<br>
                </p>
            </vs-col>
        </vs-row>
        <div v-if="this.loading" class="spin-center-ideal-distribution center-table">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else class="px-1">
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="12">
                    <vs-table search :max-items="maxItems" maxHeight="400px" hoverFlat pagination :data="receivedData" class="table-OrdersAnomalies">
                        <template slot="thead">
                            <vs-th  key="Order ID" sort-key="Order ID" class="table-OrdersAnomalies-OrderID">Order ID</vs-th>
                            <vs-th  key="Order Combination" sort-key="Order Combination" class="table-OrdersAnomalies-OrderCombination">Order Combination</vs-th>
                            <vs-th  key="Billable Weight (BW)" sort-key="Billable Weight (BW)">Billable Weight (BW)</vs-th>
                            <vs-th  key="Most Frequent BW" sort-key="Most Frequent BW">Most Frequent BW</vs-th>
                            <vs-th  key="Delta BW" sort-key="Delta BW">Delta BW</vs-th>
                            <vs-th  key="Shipping info" sort-key="Shipping info" class="table-OrdersAnomalies-ShippingInfo">Shipping info</vs-th>
                            <vs-th  key="Fulfilled Date" sort-key="Fulfilled Date" class="table-OrdersAnomalies-FulfilledDate">Fulfilled Date</vs-th>
                            <vs-th  key="Fulfillment Cost" sort-key="Fulfillment Cost">Fulfillment Cost</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="tr['Order ID']" class="table-OrdersAnomalies-OrderID">
                                    <a :href="app_link+'Orders/'+tr['Order ID']" target="_blank" rel="noopener noreferrer">{{tr['Order ID']}}</a>
                                </vs-td>
                                <vs-td :data="tr['Order Combination']" class="table-OrdersAnomalies-OrderCombination">
                                    <template v-for="itemx2 in Object.keys(tr['Order Combination'])">
                                        <vs-tooltip :key="itemx2" :text="tr['Order Combination'][itemx2]['display']" position="top" >
                                            <p class="mb-0 ellipsis">
                                                {{tr['Order Combination'][itemx2]['display']}}
                                            </p>
                                        </vs-tooltip>
                                    </template>
                                </vs-td>
                                <vs-td :data="tr['Billable Weight (BW)']">
                                    {{tr['Billable Weight (BW)']}}
                                </vs-td>
                                <vs-td :data="tr['Most Frequent BW']">
                                    {{tr['Most Frequent BW']}}
                                </vs-td>
                                <vs-td :data="tr['Delta BW']">
                                    {{tr['Delta BW']}}
                                </vs-td>
                                <vs-td :data="tr['Shipping info']" class="table-OrdersAnomalies-ShippingInfo">
                                    <template v-for="itemx2 in Object.keys(tr['Shipping info'])">
                                        <vs-tooltip :key="itemx2" :text="tr['Shipping info'][itemx2]" position="top" >
                                            <p :key="itemx2" class="mb-0">
                                                {{tr['Shipping info'][itemx2]}}
                                            </p>
                                        </vs-tooltip>
                                    </template>
                                </vs-td>
                                <vs-td :data="tr['Fulfilled Date']" class="table-OrdersAnomalies-FulfilledDate">
                                    {{tr['Fulfilled Date']}}
                                </vs-td>
                                <vs-td :data="tr['Fulfillment Cost']">
                                    $ {{tr['Fulfillment Cost']}}
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>
            <vs-row class="px-2 pb-3">
                <vs-col class="d-flex justify-content-end align-items-center">
                    <label class="mb-0" style="font-size: 12px" for="numbre">Show:  </label>
                    <input type="number" min="1" class="form-control p-1 ml-1" style="max-width: 45px" v-model="maxItemsAux" @change="changeMaxItems">
                </vs-col>
            </vs-row>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {
    data() {
        return {
            maxItems: 20,
            maxItemsAux: 20,
            receivedData: [],
            app_link: process.env.VUE_APP_SHIPBOB_LINK+'App/Merchant/#/',
            data: {
                columns: ["Order ID", "Order Combination", "Billable Weight (BW)", "Most Frequent BW", "Delta BW", "Shipping info", "Fulfilled Date", "Fulfillment Cost"],
            },
            loading: false
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        changeMaxItems() {
            if(this.maxItemsAux > 0){
                this.maxItems = this.maxItemsAux;
            }
        },
        fetch(){
            this.loading = true;
            this.receivedData = [];
            

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/anomaliesincost').then((response) => {
                let OrdersAnomalies = [];
                OrdersAnomalies = response.data.items;

                OrdersAnomalies.forEach((item) => {
                    let shipping = [item.carrier, item.carrierService, 'Zone '+item.shipbobZoneUsed];

                    this.receivedData.push({
                        "Order ID": item.shipmentId,
                        "Order Combination": item.inventoryItems,
                        "Billable Weight (BW)": item.billableWeightUsed,
                        "Most Frequent BW": item.mostCommonBillableWeightUsed,
                        "Delta BW": item.deltaBillableWeight,
                        "Shipping info": shipping,
                        "Fulfilled Date": moment(new Date(item.shippedDate)).format('MM/DD/YYYY'),
                        "Fulfillment Cost": item.fulfillmentCost,
                    })
                });
                
                this.loading = false;
                this.$store.commit('setDataOrdersAnomalies', this.receivedData);
                this.$root.$emit('finishedFetch');
            });
        }
    }
}
</script>

<template>
    <div>
        <!-- <vs-row vs-w="12" vs-justify="center">
            <vs-col vs-w="5" vs-justify="flex-end">
                <h1 class="mb-0" style="text-align:center" :class="colorClass">{{numberWithCommas(value)}}</h1>
                <p style="text-align:center">{{subtitle}}</p>
            </vs-col>
            <vs-col vs-w="1" vs-justify="flex-start" style="margin-left: 0px">
                <sup-breadcrumb :delta="delta" v-if="delta != undefined"></sup-breadcrumb>
            </vs-col>
        </vs-row> -->
        <vs-row vs-w="12" vs-justify="center">
            <div class="d-flex justify-content-center">
                <div>
                    <h1 class="mb-0" style="text-align:center" :class="colorClass">{{value==0?"-":numberWithCommas(value)}}</h1>
                    <p style="text-align:center">{{subtitle}}</p>
                </div>
                <sup-breadcrumb :delta="delta" v-if="delta != undefined"></sup-breadcrumb>
            </div>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['value', 'subtitle', 'colorClass', 'delta', 'showSimbol'],
    methods: {
        numberWithCommas(x) {
            if(this.showSimbol === false)
                return  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            else
                return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        }
    }
}
</script>
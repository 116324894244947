<template>
<ErrorBase
  errorCode="403"
  title="Forbidden"
  subTitle="You don't have access to this page"
>
</ErrorBase>
</template>

<script>

export default {
  name: 'Forbidden'
}
</script>

<style scoped lang="scss">
</style>
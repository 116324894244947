<template>
  <div>
    <sb-table
      :columnDefinitions="columnDefinitions"
      :rows="tableHelper.pagedData"
      :loadingPromise="loadingPromise"
      @sort="handleSort"
      :defaultSortColumn="defaultSortColumn"
      :defaultSortOrdering="defaultSortOrdering"
    >
      <sb-toolbar slot="sb-toolbar">
        <!-- <h3 class="title"></h3> -->
        <!-- <div class="search-wrapper"></div> -->
        
        
          <from-date-filter></from-date-filter>
    <span class="date-filter-span"> to </span>
       <to-date-filter></to-date-filter> 
      
        <sb-dropdown-search
          v-model="selected1"
          :options="options1"
          @input="changeItem1()"
          :minSearchTermLength="0"
          @search="handleSearch"
          :showSelectedOptions="true"
          :comparator="comparator"
          :loading="false"
          class="select-size-400"
          placeholder="Search by inventory name, or ShipBob ID.."
        >
          <sb-selected-dropdown-search-option
            slot="selection"
            slot-scope="{ selection }"
            class="selection-placeholder"
            :value="selection.text || 'Search by inventory name, or ShipBob ID..'"
          />
          <sb-dropdown-option
            slot-scope="{ option }"
          >{{ option.value==0?option.text: option.value + " - " + option.text }}</sb-dropdown-option>
        </sb-dropdown-search>

        <sb-dropdown
          :options="options2"
          v-model="selected2"
          @input="changeItem2()"
          class="select-size-200"
          placeholder="Select a fulfillment center.."
        >
          <sb-selected-option slot="selection" slot-scope="{ selected }">{{ selected.text }}</sb-selected-option>
          <sb-options>
            <sb-option slot-scope="{ option }" :value="option">{{ option.text }}</sb-option>
          </sb-options>
        </sb-dropdown>
 

        <sb-button @click="load()" variation="primary" size="medium" @click.prevent>Apply</sb-button>
      </sb-toolbar>
      <sb-table-row slot="sb-table-row" slot-scope="{row}">
        <sb-table-cell  >
          <a :href="app_link+row.inventoryId" target="_blank" rel="noopener noreferrer">
            <vs-tooltip :text="row.inventoryId + ' : ' + row.inventoryName" position="top">
              <p class="mb-0">{{row.inventoryName}}</p>
            </vs-tooltip>
          </a>
        </sb-table-cell>
        <sb-table-cell  >{{row.fulfillmentCenter}}</sb-table-cell>
        <sb-table-cell  >{{row.date}}</sb-table-cell>
        <sb-table-cell>{{row.beginingSnapShot}}</sb-table-cell>
        <sb-table-cell>{{row.received}}</sb-table-cell>
        <sb-table-cell>{{row.picked}}</sb-table-cell>
        <sb-table-cell>{{row.pickedExternalInternalTrans}}</sb-table-cell>
        <sb-table-cell>{{row.spotCheck}}</sb-table-cell>
        <sb-table-cell>{{row.restockInventoryMerge}}</sb-table-cell>
        <sb-table-cell>{{row.endingSnapshot}}</sb-table-cell>        
        <sb-table-cell>{{row.varianceCheck}}</sb-table-cell>
        <sb-table-cell>{{row.transit}}</sb-table-cell>
      </sb-table-row>
      <sb-table-empty-state slot="sb-table-empty-state" icon-name="products" label="No Results">
        <p>Select inventory to see all inventory movements for the specified period.</p>
      </sb-table-empty-state>
      <pagination-wrapper
        slot="pagination"
        @fetchPage="handleFetchPage"
        :pageSize="tableHelper.pagination.pageSize"
        :currentPage="tableHelper.pagination.currentPage"
        :totalPages="tableHelper.pagination.totalPages"
        :totalRecords="tableHelper.pagination.totalRecords"
        :visibleRecords="tableHelper.pagination.visibleRecords"
      ></pagination-wrapper>
    </sb-table> 
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import { cloneDeep } from "lodash";
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
  components: {
    "pagination-wrapper": PaginationWrapper
  },
  data() {
    return {
      selected1: {},
      selected2: 0, 
         app_link: process.env.VUE_APP_SHIPBOB_LINK+'app/Merchant/#/Products/',
      comparator(a, b) {
        return a.id === b.id;
      },
      recievedOptions1: [],
      options1: [],
      options2: [], 
      loadingPromise: null,
      columnDefinitions: [
        { name: "Inventory Name", field: "inventoryName", sortable: true },
        {
          name: "Fulfillment Center",
          field: "fulfillmentCenter",
          sortable: true
        },
        { name: "Date", field: "date", sortable: true },
        { name: "Start Count", field: "beginingSnapShot", sortable: true },
        { name: "Received", field: "received", sortable: true },
        { name: "Picked", field: "picked", sortable: true },
        {
          name: "FC Transit",
          field: "pickedExternalInternalTrans",
          sortable: true
        },
        { name: "Spot Check", field: "spotCheck", sortable: true },
        {
          name: "Restock/ Merge",
          field: "restockInventoryMerge",
          sortable: true
        },
        { name: "Ending Count", field: "endingSnapshot", sortable: true }, 
        { name: "Variance Check", field: "varianceCheck", sortable: true },
        { name: "In Transit", field: "transit", sortable: true }
      ],
      tableHelper: new tabler(this, 30)
    };
  },
  mounted() {
    this.loadingPromise = this.fetch(1);
    this.getSalesItems();
    this.getSelectFCs();
  }, 
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },
  methods: {
      handleSearch ({ searchTerm }) {
          if (searchTerm.length > 1) {
           searchTerm = searchTerm.toLowerCase();
            this.options1 = cloneDeep(this.recievedOptions1.filter(x => (x.text.toLowerCase().indexOf(searchTerm) !== -1) || (x.value.toString().indexOf(searchTerm) !== -1) ))
          }
          else this.options1 = this.recievedOptions1
        },
    handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    map(item) {
      return {
        inventoryId: item.inventoryId,
        inventoryName: item.inventoryName,
        fulfillmentCenter: item.fulfillmentCenter,
        date: this.dateFormat(item.date),
        beginingSnapShot: this.numberWithCommas(item.beginingSnapShot),
        received: this.numberWithCommas(item.received),
        picked: this.numberWithCommas(
          item.picked
        ),
        pickedExternalInternalTrans: this.numberWithCommas(
          item.pickedExternalInternalTrans
        ),
        spotCheck: this.numberWithCommas(item.spotCheck),
        restockInventoryMerge: this.numberWithCommas(
          item.restockInventoryMerge
        ),
        endingSnapshot: this.numberWithCommas(item.endingSnapshot),
        varianceCheck: this.numberWithCommas(item.varianceCheck),
        transit: this.numberWithCommas(item.transit)
      };
    },
    numberWithCommas(x) {
      return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    dateFormat(date) {
      return moment(new Date(date)).format("MM/DD/YYYY");
    },
    changeItem1() {
      let value = this.selected1 !== null ? this.selected1.value : "0";
      this.$store.commit("set_ir_itemID", value);
      this.$root.$emit("changeIrItemId");
    },
    changeItem2() {
      let value = this.selected2 !== null ? this.selected2.value : "";
      this.$store.commit("set_ir_fulfillmentCenter", value);
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    load() {
         this.loadingPromise = this.fetch(1);
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    }, 
    fetch: async function(p) {
      await new Promise(res => {
        this.tableHelper.preLoadReset();

        let inventoryReconciliationConfig = {
          startDate: moment(new Date(this.$store.state.from)).format(
            "MM/DD/YYYY"
          ),
          endDate: moment(new Date(this.$store.state.to)).format("MM/DD/YYYY")
        };
        if (
          this.$store.state.ir_fulfillmentCenter !== "" &&
          this.$store.state.ir_fulfillmentCenter !== undefined
        ) {
          inventoryReconciliationConfig.fcGroupId = this.$store.state.ir_fulfillmentCenter;
        }

        var paramsURL = "";
        if (this.$store.state.ir_itemID == undefined) {
          paramsURL = "inventory/history/reconciliation/";
        } else {
          paramsURL =
            "inventory/" +
            this.$store.state.ir_itemID +
            "/history/reconciliation/";
        }
        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + paramsURL,
          inventoryReconciliationConfig
        ).then(response => {
          var InventoryReconciliation = response.data.data; 
          this.tableHelper.loadData(InventoryReconciliation, p);
           
          this.$store.commit(
            "setDataInventoryReconciliation",
            this.tableHelper.receivedData
          );
          this.$root.$emit("finishedFetch");
            res(true);
        }); 
      });
    },
    getSalesItems() {
      this.options1 = [];
       this.recievedOptions1 = [];
      let inventoryItemsConfig = {
        IsActive: true,
        IsMerged: false,
        IsBundle: false
      };

      axiosInstance(
        "GET",
        process.env.VUE_APP_ENDPOINT + "inventory/",
        inventoryItemsConfig
      ).then(response => {
        this.options1.push({ text: "All Inventory", value: 0 });
        this.recievedOptions1.push({ text: "All Inventory", value: 0 });
        response.data.data.forEach(item => {
            this.recievedOptions1.push({ text: item.name, value: item.inventoryId });
          this.options1.push({ text: item.name, value: item.inventoryId });
        });
        this.$store.commit("setSalesItems", response.data.data);
        this.$root.$emit("finishedFetch");
      });
    },
    getSelectFCs () {
        this.options2 = [];

        axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'fulfillment').then((response) => {
            let data = response.data; 
            data.data.forEach((item) => {
                this.options2.push({ text: item.groupName, value: item.groupId})
            });
        });
    }
  }
};
</script>

<style scoped lang='scss'>
 
</style>
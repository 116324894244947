<template>
    <div>
        <b-form inline class="search-bar-2">
            <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                <date-filter-7-days></date-filter-7-days>
            </b-input-group>
        </b-form>
        <b-form inline class="search-bar-2">
            <b-input-group left="@">
                <comparison-filter-no-caption :items="items" :selected="'all'" :name="'ifc_itemID'"></comparison-filter-no-caption>
            </b-input-group>
            <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                <vs-checkbox v-model="checkBox1FC" color="#2570af" @change="toggleCheckInternalFC()"> Include Inventory in Internal Transfers</vs-checkbox>
            </b-input-group>
            <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                <vs-checkbox v-model="checkBox2FC" color="#2570af" @change="toggleCheckExternalFC()"> Include Inventory in External Transfers</vs-checkbox>
            </b-input-group>
            <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                <vs-button color="primary" type="button" @click="update" class="ld-ext-right">Apply </vs-button>
            </b-input-group>
        </b-form>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    props:['items', 'additionals', 'kind'],
    data() {
        return {
            checkBox1FC: false,
            checkBox2FC: false,
            loading: false
        }
    },
    mounted() {
        this.$root.$on('finishedFetch', () => { this.loading = false; });
    },
    methods:{        
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        update(){
            this.loading = true;
            this.$root.$emit('updateInventoryFC');
        },
        toggleCheckInternalFC() {
            this.$store.commit("setincludeInventoryInInternalTransfersFC", this.checkBox1FC);
        },
        toggleCheckExternalFC() {
            this.$store.commit("setincludeInventoryInExternalTransfersFC", this.checkBox2FC);
        }
    }
}
</script>


<template>
    <div>
       
        <div class="fulfillment-metrics-header">
             <h4>Exception Summary (All Orders)</h4>
              <div class="float-right">
                   <a href="/fulfillment/sla-exceptions" class="link-more-details">View More</a>
              </div>
        </div>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else class="fulfillment-metrics-container">
            <vs-row class="mb-3">
                <vs-col vs-type="flex" vs-w="12">
                    <vs-row vs-justify="space-around" vs-align="flex-start">
                        <vs-col vs-justify="center" vs-w="4" vs-align="center">
                          
                            <indicator-subtitle-h2 :value="data.totalOrders" :colorClass="'disabled'" subtitle="Total Exception Orders" type='normal-text'/>
                        </vs-col> 
                        <vs-col vs-justify="center" vs-w="8" class="divider-vertical"> 
                                <vs-col vs-justify="center" vs-w="6">
                                    <indicator-subtitle-h2 :value="data.totalOrdersOutOfStock" :colorClass="'blue2'" subtitle="Out of Stock Orders" type='normal-text'/>
                                </vs-col>
                                <vs-col vs-justify="center" vs-w="6">
                                    <indicator-subtitle-h2 :value="data.totalOrderCountDueToOtherReason" :colorClass="'disabled'" subtitle="Other Exception Orders" type='normal-text'/>
                                </vs-col> 
                        </vs-col> 
                    </vs-row>
                </vs-col>
                
            </vs-row>
        </div>
     </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {
    props:['title'],
    data() {
        return {
            data: {
                totalOrders: 0,
                totalOrdersOutOfStock: 0,
                totalOrderCountDueToOtherReason: 0,
                totalProductCountOutOfStock: 0,
                totalItemQuantity: 0,
            },
            loading: false 
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        fetch() {
            this.loading = true;
            let configShippedDate = new Date();
			let configShipped = {
					toDate: moment(configShippedDate.setDate(configShippedDate.getDate())).format('MM/DD/YYYY'),
					fromDate: moment(configShippedDate.setDate(configShippedDate.getDate() - 7)).format('MM/DD/YYYY'),
					compare: this.$store.state.comparison
				
			}
			axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/exceptions/summary', configShipped).then((response) => {
                    let data = response.data;

                    if(data.totalOrders !== 0 && data.totalOrders !== null){
                        this.data.totalOrders = this.numberWithCommas(data.totalOrders);
                        this.data.totalOrdersOutOfStock = this.numberWithCommas(data.totalOrdersOutOfStock);
                        this.data.totalOrderCountDueToOtherReason = this.numberWithCommas(data.totalOrderCountDueToOtherReason);
                        this.data.totalProductCountOutOfStock = this.numberWithCommas(data.totalProductCountOutOfStock);
                        this.data.totalItemQuantity = this.numberWithCommas(data.totalItemQuantity);
                        this.$store.commit("setShowExceptionSummary", true);
                        
                    } else {
                        this.$store.commit("setShowExceptionSummary", false);
                    }

                    this.loading = false;    
                    this.$root.$emit('showExceptionSummary');
                    this.$root.$emit('finishedFetch');
            });
        }
    }
}
</script>
 <style scoped lang='scss'>
@import '@/../assets/scss/shipbob-custom/components/dashboard/_dashboard-panel-template.scss';
 @import '@/../assets/scss/shipbob-custom/components/dashboard/_panel-fulfillment-metrics.scss';
   
    .fulfillment-metrics-container
   {
       padding-bottom: 0px;
   }
</style>
 
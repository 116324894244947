<template>
    <div>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="6">
                    <indicator-subtitle-delta :value="data.totalOrdersReceived" :colorClass="'blue1'" :delta="data.totalOrdersReceivedDelta" subtitle="Total Orders Received" :showSimbol="false"/>
                </vs-col>
                <vs-col vs-w="6">
                    <indicator-subtitle-delta :value="data.totalOrdersShipped" :colorClass="'blue2'" :delta="data.totalOrdersShippedDelta" subtitle="Total Orders Shipped" :showSimbol="false"/>
                </vs-col>
            </vs-row>
            <vs-divider></vs-divider>
            <vs-row vs-w="12" vs-justify="center" vs-align="center" style="height: 455px">
                <line-chart :labels="data.completedLabels" :data="data.completedDataset" :options="data.completedOptions"/>
            </vs-row>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment';

export default {
    props:['title'],
    data() {
        return {
            data: {
                totalOrdersReceived:0,
                totalOrdersShipped:0,
                totalOrdersReceivedDelta:0,
                totalOrdersShippedDelta:0,
                completedLabels: [],
                completedDataset: [],
                completedOptions: {              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{position:'bottom'},
                    scales: {
                        xAxes: [{
                            display: false
                        }],
                        yAxes: [{
                            gridLines: {
                                color: "#f6f6f6",
                            }
                        }]
                    }
                },
            },
            loading: false
        }
    },
    mounted() {
        this.fetch();
        this.$root.$on('update', this.fetch);
    },
    methods: {
        fetch() {
            this.loading =  true;
            this.data.completedDataset = [];
            this.data.completedLabels = [];
            
            let configShippingMethod = {
				fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
                toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
                compare: this.$store.state.comparison
            }
            
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/metrics', configShippingMethod).then((response) => {
				/** COMPLETED ORDERS CHART HEADER **/
				let OrdersMetrics = response.data;

				this.data.totalOrdersReceived = OrdersMetrics.received.total.value;
				this.data.totalOrdersShipped = OrdersMetrics.shipped.total.value;
				this.data.totalOrdersReceivedDelta = OrdersMetrics.received.total.delta;
                this.data.totalOrdersShippedDelta = OrdersMetrics.shipped.total.delta;
                
            });
            
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/completed', configShippingMethod).then((response) => {
				/** COMPLETED ORDERS CHART DATASETS **/
				let OrdersCompleted = response.data;

				let qty = [];
				let qty2 = [];
				let qty3 = [];
				let qty4 = [];

				OrdersCompleted.histories.forEach((item) => {
					this.data.completedLabels.push(item.date);
					qty.push(item.shipped);
					qty2.push(item.received);
					qty3.push(item.previousShipped);
					qty4.push(item.previousReceived);
				});

				this.data.completedDataset.push({
					label: 'Shipped',
					backgroundColor: '#59BAEF',
					borderColor: '#59BAEF',
					fill:false,
					tension:0,
					data: qty
				});

				this.data.completedDataset.push({
					label: 'Received',
					backgroundColor: '#1f66a6',
					borderColor: '#1f66a6',
					fill:false,
					tension:0,
					data: qty2
				});

				this.data.completedDataset.push({
					label: 'Previous Received',
					fill:false,
					tension:0,
					data: qty4
                });

                this.loading = false;
                this.$root.$emit('finishedFetch');                
			});
        }
    }
}
</script>

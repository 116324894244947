<template>
  <div>
    <vs-row vs-w="12" vs-justify="center" vs-align="center">
      <vs-col vs-w="9">
        <order-turn-around-time-search-bar></order-turn-around-time-search-bar>
      </vs-col>
      <vs-col vs-w="3" vs-align="center">
       <div v-if="this.turnaroundtimesLoading" class="spin-center-ideal-distribution spin-sla-exception">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else>
        <indicator-subtitle-h2
          :value="data.fulfilledOnTime"
          :simbol="'%'"
          :tooltip="this.data.tooltip"
          :colorClass="'blue2'"
          subtitle="Fulfilled On time"
        />
        </div>
      </vs-col>
    </vs-row> 
     <div>Displaying Late and On Hold orders which did not meet SLA commitments</div>
    <sb-table
      :columnDefinitions="columnDefinitions"
      :rows="tableHelper.pagedData"
      :loadingPromise="loadingPromise"
      @sort="handleSort"
      :defaultSortColumn="defaultSortColumn"
      :defaultSortOrdering="defaultSortOrdering"
    >
      <sb-table-row slot="sb-table-row" slot-scope="{row}">
        <sb-table-cell>
          <a
            :href="app_link+'Orders/'+row.orderId"
            target="_blank"
            rel="noopener noreferrer"
          >{{row.orderId}}</a>
        </sb-table-cell>
        <sb-table-cell >{{row.fc}}</sb-table-cell>        
        <sb-table-cell>{{row.shouldBeFulfilledOn}}</sb-table-cell>
        <sb-table-cell>{{row.fulfilledOn}}</sb-table-cell>
        <sb-table-cell>{{row.daysLate}}</sb-table-cell>
        <sb-table-cell>{{row.status}}</sb-table-cell>
      </sb-table-row>
      <sb-table-empty-state slot="sb-table-empty-state" icon-name="products" label="No Late Orders">
        <p>Take a break! All orders are being fulfilled on time!</p>
      </sb-table-empty-state>
      <pagination-wrapper
        slot="pagination"
        @fetchPage="handleFetchPage"
        :pageSize="tableHelper.pagination.pageSize"
        :currentPage="tableHelper.pagination.currentPage"
        :totalPages="tableHelper.pagination.totalPages"
        :totalRecords="tableHelper.pagination.totalRecords"
        :visibleRecords="tableHelper.pagination.visibleRecords"
      ></pagination-wrapper>
    </sb-table>
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
  props: ["title"],
  components: {
    "pagination-wrapper": PaginationWrapper
  },
  data() {
    return {
      app_link: process.env.VUE_APP_SHIPBOB_LINK + "App/Merchant/#/",
      data: {
        count: 0,
        fulfilledOnTime: 0,
        tooltip: ""
      },
      turnaroundtimesLoading: false,
      loadingPromise: null,
      columnDefinitions: [
        { name: "Order ID", field: "orderId", sortable: true },
        {
          name: "FC",
          field: "fc",
          sortable: true
        }, 
        {
          name: "Should Be Fulfilled On",
          field: "shouldBeFulfilledOn",
          sortable: true
        },
        { name: "Fulfilled On", field: "fulfilledOn", sortable: true },
        {
          name: "Days Late",
          field: "daysLate",
          sortable: true
        },
        {
          name: "Status",
          field: "status",
          sortable: true
        }
      ],
      tableHelper: new tabler(this, 12)
    };
  },
  mounted() {
    this.loadingPromise = this.fetch(1);
    this.$root.$on("updateOrderTurnAroundTime", this.update);
  },
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },
  methods: {
    dateFormat(date) {
      if (date == null) {
        return "";
      }
      return moment(new Date(date)).format("MM/DD/YYYY");
    },
    numberWithCommas(x) {
      return x == undefined
        ? x
        : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    update() {
      this.loadingPromise = this.fetch(1);
    },
    handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    map(item) {
      return {
        orderId: item.orderId,
        fc: item.fc,
        status: item.status,
        shouldBeFulfilledOn: this.dateFormat(item.shouldBeFulfilledOn),
        fulfilledOn: this.dateFormat(item.fulfilledOn),
        daysLate: item.daysLate
      };
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    },
    fetch: async function(p) {
      this.turnaroundtimesLoading = true;
      await new Promise(res => {
        this.tableHelper.preLoadReset();
        let configOrderDate = new Date();
        let configOrderTurnAround = {
          from: moment(new Date(this.$store.state.from)).format("MM/DD/YYYY"),
          to: moment(new Date(this.$store.state.to)).format("MM/DD/YYYY")
          // from: '2019-01-01',
          // to: '2019-01-25',
        };

        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "Fulfillment/turnaroundtimessummary",
          configOrderTurnAround
        ).then(response => {
          let dataFulfillement = response.data.data;
          let totalFulfillment = 0;
          let totalOntime = 0;
          let totalLate = 0;
          dataFulfillement.forEach(item => {
            totalOntime += item.status === "OnTime" ? item.orders : 0;
            totalLate += item.status === "Late" ? item.orders : 0;
          });
          totalFulfillment = totalOntime + totalLate;
          this.data.tooltip = `Late: ${this.numberWithCommas(
            totalLate
          )}. On time: ${this.numberWithCommas(totalOntime)}`;
          this.data.fulfilledOnTime = (
            (totalOntime / totalFulfillment) *
            100
          ).toFixed(1);
        });

        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "fulfillment/turnaroundtimes",
          configOrderTurnAround
        ).then(response => {
          let data = response.data.data;
          this.tableHelper.loadData(data, p);

          this.$store.commit(
            "setDataOrderTurnAroundTime",
            this.tableHelper.receivedData
          );

          this.turnaroundtimesLoading = false;
          this.$root.$emit("finishedFetch");
          res(true);
        }).catch((error) => {
            this.turnaroundtimesLoading = false;
        });
      });
    }
  }
};
</script>
<template>
    <div>
       
                    <sb-table
          :columnDefinitions="columnDefinitions"
          :rows="tableHelper.pagedData" 
          :loadingPromise="loadingPromise"
          @sort="handleSort"
          :defaultSortColumn="defaultSortColumn"
          :defaultSortOrdering="defaultSortOrdering" 
        >
        <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
               
        <h3 class="title">Units Sold Performance</h3>
        <div class="search-wrapper"> 
      </div>
            <download-data-button :dataName="'dataUnitsSoldPerformance'"   ></download-data-button>

      
         <vs-row>
                <vs-col vs-type="flex" vs-justify="flex-start">
                    <p class="p-info pl-5">Hover on the time ranges below to highlight the data:</p>
                </vs-col>
            </vs-row>
            <vs-row vs-w="12" class="mt-3 mb-4">
                <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                    <div @mouseover="checkActive('dates')" :class="{ 'text-active' : compareDates.activeDates}"  class="p-3" style="cursor: pointer">
                        <h3 class="h3">Selected dates:</h3>
                        <h2 class="title-dates mt-0">{{compareDates.selectedDates}}</h2>
                    </div>
                </vs-col>
                <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                    <div @mouseover="checkActive('period')" :class="{ 'text-active' : compareDates.activePeriod}"  class="p-3" style="cursor: pointer">
                        <h3 class="h3">Previous Period:</h3>
                        <h2 class="title-dates mt-0">{{compareDates.previousPeriod}}</h2>
                    </div>
                </vs-col>
                <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
                    <div @mouseover="checkActive('year')" :class="{ 'text-active' : compareDates.activeYear}"  class="p-3" style="cursor: pointer">
                        <h3 class="h3">Previous Year:</h3>
                        <h2 class="title-dates mt-0">{{compareDates.previousYear}}</h2>
                    </div>
                </vs-col>
            </vs-row>
      </sb-toolbar> 
          <sb-table-row slot="sb-table-row" slot-scope="{row}">  
            <sb-table-cell class='column-28 text-left'>
                                 
                                       <a
            :href="app_link+row.inventoryid"
            target="_blank"
            rel="noopener noreferrer"
          > 
              <vs-tooltip   :text="row.inventoryid + ' : ' + row.itemname"   position="top" >
                                            <p class="mb-0">
                                                {{row.itemname}}
                                            </p>
                                        </vs-tooltip> </a>
                                </sb-table-cell>
                            
                               <!-- <sb-table-cell :class="{ 'text-active' : compareDates.activeDates}">
                                    {{row.selectedPeriod_UnitsShipped}}
                                </sb-table-cell> -->
                                <sb-table-cell :class="{ 'text-active' : compareDates.activeDates}" class='column-10'>
                                    {{row.selectedPeriod_UnitsSold}}
                                </sb-table-cell>
                                <sb-table-cell :class="{ 'text-active' : compareDates.activeDates}" class='column-10'>
                                    {{row.selectedPeriod_PercentofTotalSold}} %
                                </sb-table-cell>
                                <sb-table-cell :class="{ 'text-active' : compareDates.activePeriod}" class='column-10'>
                                    {{row.previousPeriod_UnistsSold}}
                                </sb-table-cell>
                                <sb-table-cell :class="{ 'text-active' : compareDates.activePeriod}" class='column-10'>
                                    <span v-if="row.selectedPeriod_vs_PreviousPeriod < 0" style="color:red;">
                                        <i class="material-icons icon-arrows">arrow_downward</i>
                                        {{row.selectedPeriod_vs_PreviousPeriod}} %
                                    </span>
                                    <span v-else style="color:green;">
                                        <i class="material-icons icon-arrows">arrow_upward</i>
                                        {{row.selectedPeriod_vs_PreviousPeriod}} %
                                    </span>
                               </sb-table-cell>
                               <sb-table-cell :class="{ 'text-active' : compareDates.activeYear}" class='column-10'>
                                    {{row.previousYear_UnitsSold}}
                               </sb-table-cell>
                                <sb-table-cell :class="{ 'text-active' : compareDates.activeYear}" class='column-12'>
                                    <span v-if="row.selectedPeriod_vs_PreviousYear < 0" style="color:red;">
                                        <i class="material-icons icon-arrows">arrow_downward</i>
                                        {{row.selectedPeriod_vs_PreviousYear}} %
                                    </span>
                                    <span v-else style="color:green;">
                                        <i class="material-icons icon-arrows">arrow_upward</i>
                                        {{row.selectedPeriod_vs_PreviousYear}} %
                                    </span>
                               </sb-table-cell>
          </sb-table-row>
          
            <pagination-wrapper
            slot="pagination"
            @fetchPage="handleFetchPage"
            :pageSize="tableHelper.pagination.pageSize"
            :currentPage="tableHelper.pagination.currentPage"
            :totalPages="tableHelper.pagination.totalPages"
            :totalRecords="tableHelper.pagination.totalRecords"
            :visibleRecords="tableHelper.pagination.visibleRecords"
          ></pagination-wrapper>
        </sb-table> 
             
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'
import tabler from "@/utils/tablehelper"; 
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
       components: {
    "pagination-wrapper": PaginationWrapper
  },
    data() {
        return { 
            app_link: process.env.VUE_APP_SHIPBOB_LINK+'app/Merchant/#/Products/',
            compareDates: {
                selectedDates: '',
                activeDates: true,
                previousPeriod: '',
                activePeriod: false,
                previousYear: '',
                activeYear: false
            },
            loadingPromise: null,
            columnDefinitions: [ 
                { name: "Inventory Name", field: "itemname", sortable: true},
                // { name: "Shipped", field: "selectedPeriod_UnitsShipped", sortable: true},
                { name: "Sold", field: "selectedPeriod_UnitsSold", sortable: true},
                { name: "% of Total Sold", field: "selectedPeriod_PercentofTotalSold", sortable: true},
                { name: "Previous Sold", field: "previousPeriod_UnistsSold", sortable: true},
                { name: "Previous Sold vs Selected", field: "selectedPeriod_vs_PreviousPeriod", sortable: true},
                { name: "Previous Year Sold", field: "previousYear_UnitsSold", sortable: true},
                { name: "Previous Year Sold vs Selected", field: "selectedPeriod_vs_PreviousYear", sortable: true},
                           
            ], 
            tableHelper: new tabler(this,12) 
        }
    },   
     mounted() {
     this.loadingPromise = this.fetch(1);
      this.$root.$on('update', this.update);
  },
   computed: {
     defaultSortColumn () {
      return null;
    },
    defaultSortOrdering () {
      return null;
    } 
  },
    methods: { 
        handleSort ({ sortColumn, descending }) {
            this.tableHelper.handleSort({ sortColumn:sortColumn, descending:descending });
        }, 
        map(item)
        { 
            return { 
                 itemname: item.itemname,
                        inventoryid: item.inventoryid,
                        selectedPeriod_UnitsShipped: this.numberWithCommas(item.selectedPeriod_UnitsShipped),
                        selectedPeriod_UnitsSold: this.numberWithCommas(item.selectedPeriod_UnitsSold),
                        selectedPeriod_PercentofTotalSold: item.selectedPeriod_PercentofTotalSold,
                        previousPeriod_UnistsSold: this.numberWithCommas(item.previousPeriod_UnistsSold),
                        selectedPeriod_vs_PreviousPeriod: item.selectedPeriod_vs_PreviousPeriod,
                        previousYear_UnitsSold: item.previousYear_UnitsSold,
                        selectedPeriod_vs_PreviousYear: item.selectedPeriod_vs_PreviousYear,
            };  
        },
        dateFormat(date) {
            return moment(new Date(date)).format('MM/DD/YYYY')
        },
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        checkActive(opc){
            this.compareDates.activeDates = (opc === 'dates') ? true : false;
            this.compareDates.activePeriod = (opc === 'period') ? true : false;
            this.compareDates.activeYear = (opc === 'year') ? true : false;
        },
         handleFetchPage(p) {
            this.loadingPromise = this.tableHelper.loadData(this.tableHelper.receivedData, p.page);
        },
        update()
        { 
            this.loadingPromise = this.fetch(1);
        },
        fetch: async function(p) {
            await new Promise(res => { 
            
            this.tableHelper.preLoadReset();
            
            let config = {
				fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
                toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY')
                // fromDate:' 2019-01-01',
                // toDate: '2019-01-25',
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/v2/0', config).then((response) => {
                let data = response.data;
                
                this.compareDates.selectedDates = data.compareDates.selectedDates;
                this.compareDates.previousPeriod = data.compareDates.previousPeriod;
                this.compareDates.previousYear = data.compareDates.previousYear;
                  this.tableHelper.loadData( data.data, p);   
                this.$store.commit('setDataUnitsSoldPerformance', this.tableHelper.receivedData);
                this.loading = false;
                this.$root.$emit('finishedFetch');
                 res(true);
            });
            
            });
        }
    }
}
</script>
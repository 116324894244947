<template>
    <div v-if="this.loadingCard" class="spin-center-ideal-distribution center">
        <div class="ld ld-ring ld-spin running"></div>
    </div>
    <div   v-else>
        <p style="padding: 0px 15px">See which fulfillment centers would have shipped your <b>last {{data.idealNumberOrders}} orders</b> and how much it would have cost you based on your selections.</p>
        <vs-row  >
            <vs-col vs-w="8">
                <div v-if="this.loading" class="spin-center-ideal-distribution">
                    <div class="ld ld-ring ld-spin running"></div>
                </div>
                <div class="ideal-distribution-container canvas" v-else>
                <doughnut-chart :data="data.idealDistributionDataset" :options="data.idealDistributionOptions" 
                :labels="data.idealDistributionLabels" ></doughnut-chart>
                </div>
            </vs-col>
               <vs-col   vs-w="4" vs-align="center" vs-type="flex"> 
                  <div style="margin:auto;">
                <accotation-vertical v-for="item in data.idealDistributionAccotations" :title="item.title" :percentage="item.percentage" :key="item.id" :internalValue="item.fcId" :color="item.colorClass" :requiredDropdown="true"></accotation-vertical>
             </div>
            </vs-col>
        </vs-row>
  
        <vs-divider></vs-divider>
        
        <vs-row vs-justify="center">
            <vs-col vs-w="7">
                <indicator-subtitle-h2 :value="data.idealTotalShippingCost" :subtitle="'Fulfillment Cost'"/>
            </vs-col>
            <vs-col vs-w="5">
                <indicator-subtitle-h2 :value="data.idealNumberOrders" :subtitle="'Number of Orders'"/>
            </vs-col>
        </vs-row>
        
        <vs-divider class="blank-divider"></vs-divider>
        
        <vs-row vs-justify="flex-start">
            <vs-col vs-w="7">
                <indicator-subtitle-h2 :value="data.idealShippingCost" :subtitle="'Fulfillment Cost / Order'" :colorClass="'blue2'"/>
            </vs-col>
            <vs-col vs-w="5">
                 <indicator-subtitle-h2 :value="data.idealAvgZone" :subtitle="'Average Zone'"/>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment';

export default {
    data() {
        return {
            data: {
                idealTotalShippingCost: '$ 8,911 - $ 9,345',
                idealNumberOrders: 857,
                idealShippingCost: '$ 10.39 - $ 10.90',
                idealAvgZone: 3.2,
                idealDistributionDataset:[],
                idealDistributionLabels:[],
                idealDistributionOptions:{        
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{display:false}, 
                    cutoutPercentage: 50
                },
                idealDistributionAccotations: [],
                availableColors: ['green1', 'blue3', 'blue2', 'gray', 'gray2'],
                queryValues: []
            },
            loading:false,
            loadingCard: true
        }
    },
    beforeMount() { 
        this.queryValues = [7,8,9,10,11,13,14,15,16,17,18,19,20,21,22,24,25,27,29];
    },
    mounted() {
        this.fetch(); 
        this.$root.$on('update', this.fetch);
        this.$root.$on('accotationChanged', (a) => { 
               
            this.setQueryValues(a);
            this.fetch();
        });
    },
    destroyed(){
        this.$root.$off('update', this.fetch);
        this.$root.$off('accotationChanged', (a) => { 
            this.setQueryValues(a); 
            this.fetch();
        });
    },
    methods: {
        setQueryValues(a){ 
            if(a.selectValue==true)
            { 
                if(!this.queryValues.includes(a.contextValue)){
                        this.queryValues.push(a.contextValue);
                }
            }else{ 
                const index = this.queryValues.indexOf(a.contextValue);
                if (index > -1) {
                    this.queryValues.splice(index, 1);
                } 
            }  
        },
        fetch() {
            //this.setQueryValues();
            this.loading = true;
            this.data.idealDistributionDataset = [];
            this.data.idealDistributionLabels = [];

            let idealDistributionConfig = {
				fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
                toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
                includedFcs: this.queryValues.join()
            }
            
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/distribution/ideal', idealDistributionConfig).then((response) => {
                this.data.idealDistributionAccotations = [];
 
                let dists = response.data.distributions;
               
                let i = 0;
                let dataidd = [];
                let dataidl = [];
                let dataColors = []; 
                for(let item in dists) {
                    var record = dists[Object.keys(dists)[i]]; 
                    this.data.idealDistributionAccotations.push({
                        id: i,
                        title: item.toUpperCase(),
                        fcId:record.fcId,
                        percentage: record.count,
                        colorClass: this.getColor(record.fcName.toUpperCase(), true)
                    });
                    dataidd.push(record.count);
                    dataidl.push(record.fcName.toUpperCase());
                    dataColors.push(this.getColor(record.fcName.toUpperCase(), false));
                    i++;
                }

                this.data.idealTotalShippingCost = '$ ' + Math.round(response.data.totalCost.high);
                this.data.idealNumberOrders = response.data.numberOfOrders;
                this.data.idealAvgZone = response.data.averageZone;
                this.data.idealShippingCost = '$ ' + response.data.costPerOrder.high;

                this.data.idealDistributionDataset = [{
                    data: dataidd,
                    backgroundColor: dataColors
                }];

                this.data.idealDistributionLabels = dataidl;

                this.loading = false;
                this.loadingCard = false;
                this.$root.$emit('finishedFetch');
                 this.$store.commit('setIdealDistNumOrder', this.data.idealNumberOrders);
                this.$root.$emit("finishedsetIdealDistNumOrder");
            });
        },
        getColor(key, flag) {
            let color = "";
            switch (key) {
                case 'CICERO (IL)': color = (flag) ? 'color-chi' : '#2470AF'; break;
                case 'BETHLEHEM (PA)': color = (flag) ? 'color-pa' : '#92EFAC'; break;
                case 'MORENO VALLEY (CA)': color = (flag) ? 'color-mv' : '#59BAEF'; break;
                case 'CHATTANOOGA (TN)': color = (flag) ? 'color-sf' : '#509B63'; break;
                case 'GRAPEVINE 1 (TX)': color = (flag) ? 'color-da' : '#343E4F'; break;
                case 'ST. PETERSBURG (FL)': color = (flag) ? 'color-pt' : '#F28482'; break;
                case 'GRAPEVINE 2 (TX)': color = (flag) ? 'color-da2' : '#F6BD60'; break;
                case 'OTTAWA (ON)': color = (flag) ? 'color-ott' : '#F6BD60'; break;
                case 'KILKENNY (IE)': color = (flag) ? 'color-kil' : '#84A59D'; break;
                case 'CARROLLTON (TX)': color = (flag) ? 'color-car' : '#E6AF2E'; break;
                case 'WOODRIDGE (IL)': color = (flag) ? 'color-wrg' : '#BEB7A4'; break;
                case 'EDISON (NJ)': color = (flag) ? 'color-nje' : '#16BAC5'; break;
                case 'TWIN LAKES (WI)': color = (flag) ? 'color-wi' : '#4E3D42'; break;
                case 'FELTHAM (UK)': color = (flag) ? 'color-flt' : '#6D6466'; break; 
                case 'LOUISVILLE (KY)': color = (flag) ? 'color-lsv' : '#c59630'; break; 
                case 'PHOENIX (AZ)': color = (flag) ? 'color-phx' : '#C9D5B5'; break;
                case 'BUDD LAKE (NJ)': color = (flag) ? 'color-bdl' : '#E3DBDB'; break;
                case 'BETHLEHEM 2 (PA)': color = (flag) ? 'color-pa2' : '#0B3C49'; break;
                case 'YORKVILLE (IL)': color = (flag) ? 'color-ykv' : '#731963'; break;
                default: color = (flag) ? 'gray2' : '#888a8e';
            }
            return color;
        }
    } 
}
</script>
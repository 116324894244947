<template>
  <div>
    <PowerBI :reportName="'InventoryDistribution'" class="full-height" ></PowerBI>
  </div>
</template>
<script>    
import * as client from 'powerbi-client'
import PowerBI from "@/components/shared/PowerBI";

const pbi = window.powerbi
const models = client.models
 

export default {
components: {
PowerBI 
  },
	data() {
		return {    
		} 
	},
	  mounted() {
     
  }
  ,
 computed: {  
   reportId()
   {
    return "";
   },
  },
	methods: { 
   
	}
	  
}
</script>
<style scoped lang='scss'>
@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss";
@import "@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss";
.main-content {
  background-color: #f2f3f5;
  
}  
.full-height {
  height: 100vh;
}

</style>
 
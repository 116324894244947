import Vue from 'vue'
import Vuex from 'vuex'
import { user } from '@/stores/user'
import AuthorizationService from '@/services/authorization'
import UserService from '@/services/user'  
import PowerBIService from '@/services/PowerBIService' 
import LookupService from '@/services/lookup' 
import { USER_ROLE_ENUM } from '@/models/user/userRoles'

Vue.use(Vuex)

let date = new Date();

export default new Vuex.Store({
  state: {
    title: 'Default',
    env:'development',
    to:date.setDate(date.getDate()),
    from: date.setDate(date.getDate() - 30),
    to_7: date.setDate(date.getDate()),
    from_7: date.setDate(date.getDate() - 6), 
    comparison:'PreviousPeriod',
    mapboxApiKey:'pk.eyJ1IjoiZmVyZ29jZWpvIiwiYSI6ImNqcGlwaXZpczE5c3QzcXA1enpveTk4eTEifQ.00Rt4HNTmc85I7EeRjsJ2w#4',
    skuVelocityDropdown1Text:'7 Days',
    skuVelocityDropdown1Value:'7days',
    skuVelocityDropdown2Text:'30 Days',
    skuVelocityDropdown2Value:'30days',
    includeB2BSales:false,
    includeInventoryInInternalTransfersItem:false,
    includeInventoryInExternalTransfersItem:false,
    includeInventoryInInternalTransfersFC:false,
    includeInventoryInExternalTransfersFC:false,
    ihid_itemID:0,
    ihid_lotid:0,
    ifc_itemID:0,
    sales_itemID:0,
    ideal_chi:1,
    ideal_da:1,
    ideal_la:1,
    ideal_pa:1,
    ideal_sf:1,
    ideal_mv:1,
    di_select:"top",
    di_time:"7",
    di_in_items: "",
    di_timeInventorySummary: "30",
    salesItems: [],
    showExceptionSummary: true,
    isAdmin: null,
    authenticated: false,
    impersonatedUser:{},
    loggedInUser:{},
    isBillingRestrictedCustomer: false
  },
  mutations: {
    setTitle(state, value) {
      state.title = value;
    },
    setFrom(state, value){
      state.from = value;
    },
    setTo(state, value){
      state.to = value;
    },
    setFrom7(state, value){
      state.from_7 = value;
    },
    setTo7(state, value){
      state.to_7 = value;
    },
    setFrom60(state, value){
      state.from_60 = value;
    },
    setComparison(state, value){
      state.comparison = value;
    },
    setSkuVelocityDropdown1Text(state, value){
      state.skuVelocityDropdown1Text = value;
    },
    setSkuVelocityDropdown2Text(state, value){
      state.skuVelocityDropdown2Text = value;
    },
    setSkuVelocityDropdown1Value(state, value){
      state.skuVelocityDropdown1Value = value;
    },
    setSkuVelocityDropdown2Value(state, value){
      state.skuVelocityDropdown2Value = value;
    },
    setihid_itemID(state, value) {
      state.ihid_itemID = value;
    },
    setihid_lotid(state, value) {
      state.ihid_lotid = value;
    },
    setifc_itemID(state, value) {
      state.ifc_itemID = value;
    },
    set_ir_itemID(state, value) {
      state.ir_itemID =  value;
    },
    set_ir_fulfillmentCenter(state, value) {
      state.ir_fulfillmentCenter = value;
    },
    setsales_itemID(state, value) {
      state.sales_itemID = value;
    },
    setsales_itemIDInventorySummary(state, value) {
      state.sales_itemIDInventorySummary = value;
    },
    setideal_chi(state, value) {
      state.ideal_chi = value;
    },
    setideal_da(state, value) {
      state.ideal_da = value;
    },
    setideal_la(state, value) {
      state.ideal_la = value;
    },
    setideal_pa(state, value) {
      state.ideal_pa = value;
    },
    setideal_sf(state, value) {
      state.ideal_sf = value;
    },
    setideal_mv(state, value) {
      state.ideal_mv = value;
    },
    setdi_select(state, value) {
      state.di_select = value;
    },
    setdi_time(state, value) {
      state.di_time = value;
    },
    setdi_timeInventorySummary(state, value) {
      state.di_timeInventorySummary = value;
    },
    setdi_kind(state, value) {
      state.di_time = value;
    },
    setdi_in_items(state, value) {
      state.di_in_items = value;
    },
    setinclude_b2b_sales(state, value) {
      state.includeB2BSales = value;
    },
    setinclude_b2b_salesInventorySummary(state, value){
      state.includeB2BSalesInventorySummary = value;
    },
    setincludeInventoryInInternalTransfersItem(state, value){
      state.includeInventoryInInternalTransfersItem = value;
    },
    setincludeInventoryInExternalTransfersItem(state, value){
      state.includeInventoryInExternalTransfersItem = value;
    },
    setincludeInventoryInInternalTransfersFC(state, value){
      state.includeInventoryInInternalTransfersFC = value;
    },
    setincludeInventoryInExternalTransfersFC(state, value){
      state.includeInventoryInExternalTransfersFC = value;
    },
    setSalesItems(state, value) {
      state.salesItems = value;
    },
    setpercent_inventorySummary (state, value) {
      state.percent_inventorySummary = value;
    },
    setDataExceptions(state, value) {
      state.dataExceptions =  value;
    },
    setDataInventoryHistoryByItem(state, value) {
      state.dataInventoryHistoryByItem = value;
    },
    setDataInventoryHistoryByFC(state, value) {
      state.dataInventoryHistoryByFC = value;
    },
    setDataUnitSold(state, value) {
      state.dataUnitSold = value;
    },
    setDataSkuVelocity(state, value) {
      state.dataSkuVelocity = value;
    },
    setDataSalesBySku(state, value) {
      state.dataSalesBySku = value;
    },
    setDataAgeOrders(state, value) {
      state.dataAgeOrders = value;
    },
    setDataOrdersAnomalies(state, value) {
      state.dataOrdersAnomalies = value;
    },
    setDataFrequentlyPurchased(state, value){
      state.dataFrequentlyPurchased = value;
    },
    setDataCostGoodsSold(state, value){
      state.dataCostGoodsSold =  value;
    },
    setDataBillingBreakDown(state, value){
      state.dataBillingBreakDown = value;
    },
    setDataInventoryReconciliation(state, value){
      state.dataInventoryReconciliation = value;
    },
    setDataOrderTurnAroundTime(state, value){
      state.dataOrderTurnAroundTime = value;
    },
    setDataStateDetails(state, value){
      state.dataStateDetails = value;
    },
    setDataStorage(state, value){
      state.dataStorage =  value;
    },
    setDataStorageCost(state, value) {
      state.dataStorageCost = value;
    },
    setDataStorageHistorical(state, value){
      state.dataStorageHistorical = value;
    },
    setDataStorageInvoices(state, value){
      state.dataStorageInvoices = value;
    },
    setDataUnitsSoldPerformance(state, value){
      state.dataUnitsSoldPerformance = value;
    },
    setDataUnitsSoldShipped(state, value) {
      state.dataUnitsSoldShipped = value;
    },
    setDataUnitsSoldChannel(state, value){
      state.dataUnitsSoldChannel = value;
    },
    setDataSkuVelocityDaysInventory(state, value){
      state.dataSkuVelocityDaysInventor = value;
    },
    setShowExceptionSummary(state, value){
      state.showExceptionSummary = value;
    },
    isAdmin: (state, {IsAdmin}) => {
      state.isAdmin = IsAdmin
    },
    setAuthentication (state, auth) {
      state.authenticated = auth
    },
    resetAuthentication (state) {
      state.authenticated = false
    },
    setImpersonatedUser (state, value) {
      state.impersonatedUser = value; 
    },
    setLoggedInUser (state, value) {
      state.loggedInUser = value; 
    },
    setTransitTimeDates (state, value) { 
      state.transitTimeDates = value; 
    } ,
    setMapSelectedDates (state, value) { 
      state.mapSelectedDates = value; 
    } ,
    setCurrentDistNumOrder (state, value) { 
      state.curtDistNumOrder = value; 
    },
    setIdealDistNumOrder (state, value) { 
      state.idealDistNumOrder = value; 
    },
    setskuPerformanceDates (state, value) { 
      state.skuPerformanceDates = value; 
    }  ,
    setAvgfulfillmentCostPerOrder (state, value) { 
      state.avgfulfillmentCostPerOrder = value; 
    } ,
    setuserHasShipped(state, value) { 
      state.userHasShipped = value; 
    } ,
    setPowerBIEnabled(state, value) { 
      state.powerBIEnabled = value; 
    },
    setIsBillingRestrictedCustomer(state, value){ 
      state.isBillingRestrictedCustomer = value === USER_ROLE_ENUM.BILLING_RESTRICTED_CUSTOMER
    },   
  },
  actions: {
    update({commit}) {
      console.log("Update ready !!!")
    },
    isAdmin: async (context) => {
      if (context.state.isAdmin !== null) return
      let request = {
        Url: process.env.Shipbob_Site +  '/api/admin/isAdmin'
      }
      let res = await HttpUtil.Get(request)
      context.commit('isAdmin', {IsAdmin: res.data})
    }, 
    async authenticate (context) {
      var authorized = await AuthorizationService.authenticate()
      if (!authorized) {
        authorized = await AuthorizationService.refreshToken() 
      }
      context.commit('setAuthentication', authorized)
      return authorized
    },
    resetAuthentication (context) {
      context.commit('resetAuthentication')
    },
    async fetchDetailedUser (context) {
      var userData = await UserService.getDetailedUser();
      context.commit('setIsBillingRestrictedCustomer', userData.UserRole);
      return userData;
    } ,
    async getEmbededReportConfig ({ commit, state },req){
      var response = await PowerBIService.GetReport(req); 
      return response;
    },
    async getSettingIsEnabled ({ commit, state },req){
      var response = await LookupService.GetSettingIsEnabled(req); 
      return response;
    }
  },
  modules: {
    user: user
  },
  getters : {
    isAdmin: state => state.isAdmin,
    impersonatedUser: state => state.impersonatedUser,
    loggedInUser: state => state.loggedInUser,
    getDataBillingBreakDown: state => state.dataBillingBreakDown
  } 
})

<template>
  <div> 
    
    <sb-table
      :columnDefinitions="columnDefinitions"
      :rows="tableHelper.pagedData"
      :loadingPromise="loadingPromise"
      @sort="handleSort"
      :defaultSortColumn="defaultSortColumn"
      :defaultSortOrdering="defaultSortOrdering"
    >
      <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
        <h3 class="title">SKU Velocity</h3>
        <div class="search-wrapper"></div>
            <download-data-button :dataName="'dataSkuVelocityDaysInventor'"   ></download-data-button>
 
        <vs-row>
          <vs-col vs-type="flex" vs-justify="flex-start">
            <h3 class="h3 pl-5 gray2 mb-3">End of Day Inventory as of: {{date}}</h3>
          </vs-col>
        </vs-row>
        <vs-row>
          <p class="p-info pl-5">Hover over the date ranges below to highlight the corresponding data, which shows how much you’ve sold over four time period options: 7 days, 30 days, 60 days, or 90 days. Scroll down to find a more detailed inventory summary.</p>
          <vs-col vs-type="flex" vs-justify="flex-start"></vs-col>
        </vs-row>
        <vs-row vs-w="12" class="mt-3 mb-4">
          <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center">
            <div
              @mouseover="checkActive('days7')"
              :class="{ 'text-active' : compareDates.activeDays7}"
              class="p-3"
              style="cursor: pointer"
            >
              <h3 class="h3">Based on last 7 days:</h3>
              <h2 class="title-dates mt-0">{{compareDates.days7}}</h2>
            </div>
          </vs-col>
          <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center">
            <div
              @mouseover="checkActive('days30')"
              :class="{ 'text-active' : compareDates.activeDays30}"
              class="p-3"
              style="cursor: pointer"
            >
              <h3 class="h3">Based on last 30 days:</h3>
              <h2 class="title-dates mt-0">{{compareDates.days30}}</h2>
            </div>
          </vs-col>
          <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center">
            <div
              @mouseover="checkActive('days60')"
              :class="{ 'text-active' : compareDates.activeDays60}"
              class="p-3"
              style="cursor: pointer"
            >
              <h3 class="h3">Based on last 60 days</h3>
              <h2 class="title-dates mt-0">{{compareDates.days60}}</h2>
            </div>
          </vs-col>
          <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center">
            <div
              @mouseover="checkActive('days90')"
              :class="{ 'text-active' : compareDates.activeDays90}"
              class="p-3"
              style="cursor: pointer"
            >
              <h3 class="h3">Based on last 90 days</h3>
              <h2 class="title-dates mt-0">{{compareDates.days90}}</h2>
            </div>
          </vs-col>
        </vs-row>
      </sb-toolbar>
      <sb-table-row slot="sb-table-row" slot-scope="{row}">
        <sb-table-cell class="column-25"> 
             <a
            :href="app_link+row.inventoryid"
            target="_blank"
            rel="noopener noreferrer"
          > 
              <vs-tooltip   :text="row.inventoryid + ' : ' + row.itemname"   position="top" >
                                            <p class="mb-0">
                                                {{row.itemname}}
                                            </p>
                                        </vs-tooltip> </a>
                                    
        </sb-table-cell> 
        <sb-table-cell class="column-7-5">{{row.onhand}}</sb-table-cell>
        <sb-table-cell
          :class="{ 'text-active' : compareDates.activeDays7}"
          class="column-7-5"
        >{{row.last7Days_AvgUnitSoldperDay}}</sb-table-cell>
        <sb-table-cell
          :class="{ 'text-active' : compareDates.activeDays7}"
          class="column-7-5"
        >{{row.last7Days_DaysofInventory}}</sb-table-cell>
        <sb-table-cell
          :class="{ 'text-active' : compareDates.activeDays30}"
          class="column-7-5"
        >{{row.last30Days_AvgUnitSoldperDay}}</sb-table-cell>
        <sb-table-cell
          :class="{ 'text-active' : compareDates.activeDays30}"
          class="column-7-5"
        >{{row.last30Days_DaysofInventory}}</sb-table-cell>
        <sb-table-cell
          :class="{ 'text-active' : compareDates.activeDays60}"
          class="column-7-5"
        >{{row.last60Days_AvgUnitSoldperDay}}</sb-table-cell>
        <sb-table-cell
          :class="{ 'text-active' : compareDates.activeDays60}"
          class="column-7-5"
        >{{row.last60Days_DaysofInventory}}</sb-table-cell>
        <sb-table-cell
          :class="{ 'text-active' : compareDates.activeDays90}"
          class="column-7-5"
        >{{row.last90Days_AvgUnitSoldperDay}}</sb-table-cell>
        <sb-table-cell
          :class="{ 'text-active' : compareDates.activeDays90}"
          class="column-7-5"
        >{{row.last90Days_DaysofInventory}}</sb-table-cell>
      </sb-table-row>

      <pagination-wrapper
        slot="pagination"
        @fetchPage="handleFetchPage"
        :pageSize="tableHelper.pagination.pageSize"
        :currentPage="tableHelper.pagination.currentPage"
        :totalPages="tableHelper.pagination.totalPages"
        :totalRecords="tableHelper.pagination.totalRecords"
        :visibleRecords="tableHelper.pagination.visibleRecords"
      ></pagination-wrapper>
    </sb-table>

     
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
  components: {
    "pagination-wrapper": PaginationWrapper
  },
  data() {
    return {
      app_link: process.env.VUE_APP_SHIPBOB_LINK + "app/Merchant/#/Products/",
      date: "",
      compareDates: {
        days7: "",
        activeDays7: true,
        days30: "",
        activeDays30: false,
        days60: "",
        activeDays60: false,
        days90: "",
        activeDays90: false
      },
      loadingPromise: null,
      columnDefinitions: [
        { name: "Inventory Name", field: "itemname", sortable: true },
   
        { name: "EOD CT", field: "onhand", sortable: true },
        {
          name: "Daily Avg Sold (7 d)",
          field: "last7Days_AvgUnitSoldperDay",
          sortable: true
        },
        {
          name: "Days Left",
          field: "last7Days_DaysofInventory",
          sortable: true
        },
        ,
        {
          name: "Daily Avg Sold (30 d)",
          field: "last30Days_AvgUnitSoldperDay",
          sortable: true
        },
        {
          name: "Days Left",
          field: "last30Days_DaysofInventory",
          sortable: true
        },
        ,
        {
          name: "Daily Avg Sold (60 d)",
          field: "last60Days_AvgUnitSoldperDay",
          sortable: true
        },
        {
          name: "Days Left",
          field: "last60Days_DaysofInventory",
          sortable: true
        },
        ,
        {
          name: "Daily Avg Sold 90 d)",
          field: "last90Days_AvgUnitSoldperDay",
          sortable: true
        },
        {
          name: "Days Left",
          field: "last90Days_DaysofInventory",
          sortable: true
        }
      ],
      tableHelper: new tabler(this, 12)
    };
  }, 
  mounted() {
    this.loadingPromise = this.fetch(1);
     ///this.$root.$on("update", this.fetch(1));
  },
  destroyed() {
    //this.$root.$off("update", this.fetch(1));
  },
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },
  methods: {
    handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    map(item) {
      return {
        itemname: item.itemname,
        inventoryid: item.inventoryid,
        onhand: item.onhand,
        last7Days_AvgUnitSoldperDay: item.last7Days_AvgUnitSoldperDay,
        last7Days_DaysofInventory: item.last7Days_DaysofInventory,
        last30Days_AvgUnitSoldperDay: item.last30Days_AvgUnitSoldperDay,
        last30Days_DaysofInventory: item.last30Days_DaysofInventory,
        last60Days_AvgUnitSoldperDay: item.last60Days_AvgUnitSoldperDay,
        last60Days_DaysofInventory: item.last60Days_DaysofInventory,
        last90Days_AvgUnitSoldperDay: item.last90Days_AvgUnitSoldperDay,
        last90Days_DaysofInventory: item.last90Days_DaysofInventory
      };
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    },
    dateFormat(date) {
      return moment(new Date(date)).format("MM/DD/YYYY");
    },
    checkActive(opc) {
      this.compareDates.activeDays7 = opc === "days7" ? true : false;
      this.compareDates.activeDays30 = opc === "days30" ? true : false;
      this.compareDates.activeDays60 = opc === "days60" ? true : false;
      this.compareDates.activeDays90 = opc === "days90" ? true : false;
    },
    fetch: async function(p) {
      await new Promise(res => {
        this.tableHelper.preLoadReset();

        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "inventory/sales/velocity"
        ).then(response => {
          let data = response.data;
          this.tableHelper.loadData(data.data, p);

          this.date = this.dateFormat(data.onHandAsOf);
          this.compareDates.days7 = data.compareDates.seven;
          this.compareDates.days30 = data.compareDates.thirty;
          this.compareDates.days60 = data.compareDates.sixty;
          this.compareDates.days90 = data.compareDates.ninety;

          this.$store.commit(
            "setDataSkuVelocityDaysInventory",
            this.tableHelper.receivedData
          );
          this.$root.$emit("finishedFetch");
          res(true);
        });
        
      });
    }
  }
};
</script>
 <style scoped lang='scss'> 

.customers-toolbar {
  padding-left: 0px;
}  
</style>
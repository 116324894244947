import { render, staticRenderFns } from "./PowerBI.vue?vue&type=template&id=09e5f2f3&scoped=true&"
import script from "./PowerBI.vue?vue&type=script&lang=js&"
export * from "./PowerBI.vue?vue&type=script&lang=js&"
import style0 from "./PowerBI.vue?vue&type=style&index=0&id=09e5f2f3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09e5f2f3",
  null
  
)

component.options.__file = "PowerBI.vue"
export default component.exports
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vs-row',{attrs:{"vs-w":"12","vs-justify":"flex-start","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-w":"8","vs-justify":"flex-start"}},[_c('b-form',{staticClass:"search-bar-2 my-2",attrs:{"inline":""}},[_c('b-input-group',{staticClass:"mr-3",attrs:{"left":"@"}},[_c('sb-dropdown-search',{staticClass:"dropdown-filter filter-inventory",attrs:{"options":_vm.options1,"minSearchTermLength":0,"showSelectedOptions":true,"comparator":_vm.comparator,"loading":false,"placeholder":"Search by inventory name, or ShipBob ID.."},on:{"search":_vm.handleSearch},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
return _c('sb-selected-dropdown-search-option',{staticClass:"selection-placeholder",attrs:{"value":selection.text || 'Search by inventory name, or ShipBob ID..'}})}},{key:"default",fn:function(ref){
var option = ref.option;
return _c('sb-dropdown-option',{},[_vm._v(_vm._s(option.value==0?option.text: option.value + " - " + option.text)+"  ")])}}]),model:{value:(_vm.selected1),callback:function ($$v) {_vm.selected1=$$v},expression:"selected1"}})],1),_c('b-input-group',{attrs:{"left":"@"}},[_c('sb-dropdown',{staticClass:"dropdown-filter filter-short",attrs:{"options":_vm.options2},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selected = ref.selected;
return _c('sb-selected-option',{},[_vm._v("\n                            "+_vm._s(selected.text)+"\n                        ")])}}]),model:{value:(_vm.selected2),callback:function ($$v) {_vm.selected2=$$v},expression:"selected2"}},[_c('sb-options',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return _c('sb-option',{attrs:{"value":option}},[_vm._v("\n                                "+_vm._s(option.text)+"\n                            ")])}}])})],1)],1),_c('b-input-group',{staticClass:"search-bar-btn",attrs:{"left":"@"}},[_c('sb-button',{attrs:{"variation":"primary","size":"small"},on:{"click":[_vm.update,function($event){$event.preventDefault();}]}},[_vm._v("Apply")])],1)],1)],1)],1),(this.loading)?_c('div',{staticClass:"spin-center-ideal-distribution center"},[_c('div',{staticClass:"ld ld-ring ld-spin running"})]):_c('div',[_c('vs-divider'),_c('vs-row',{attrs:{"vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('header',{staticClass:"vs-card-header"},[_c('title-component',{attrs:{"str":"","dataName":"dataUnitsSoldChannel"}})],1)])],1),_c('vs-row',[_c('vs-col',{attrs:{"vs-w":"4","vs-justify":"flex-start"}},[_c('vs-row',{attrs:{"vs-align":"flex-end"}},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('indicator-subtitle',{attrs:{"value":_vm.data.totalUnits,"colorClass":'blue2',"subtitle":_vm.data.textTotalUnits}})],1)],1)],1),_c('vs-col',{attrs:{"vs-w":"8"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"horizontal-barchart-title"},[_vm._v("\n                        By channel:\n                       ")])]),_c('stacked-horizontal-bar-chart',{attrs:{"labels":_vm.dataBarChart.labels,"data":_vm.dataBarChart.dataset,"options":_vm.dataBarChart.options}})],1)],1),_c('vs-divider'),_c('vs-row',{attrs:{"vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{staticClass:"px-3",attrs:{"vs-w":"12"}},[_c('title-component',{attrs:{"str":_vm.data.textHistorical,"dataName":"dataUnitsSoldPerformance"}})],1)],1),_c('vs-row',{staticClass:"mt-5",attrs:{"vs-w":"12","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('line-chart',{attrs:{"labels":_vm.dataLineChart.labels,"data":_vm.dataLineChart.dataset,"options":_vm.dataLineChart.options}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
    name: 'insta-focus',
    directive: {
      inserted: function (el, binding) {
        el.blur() // Prevents mobile keyboards from popping up.
        if (binding.value === undefined || binding.value) {
          el.focus()
        }
      }
    }
  }
  
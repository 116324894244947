<template>
    <div>
        
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="6">
                    <indicator-subtitle-h2 :value="data.totalOrdersOutOfStock" :colorClass="'blue2'" subtitle="Out of Stock Orders"/>
                </vs-col>
                <vs-col vs-w="6">
                    <indicator-subtitle-h2 :value="data.totalItemQuantity" :colorClass="'blue2'" subtitle="Out of Stock Quantity"/>
                </vs-col>
            </vs-row>
            <vs-divider></vs-divider>
             <sb-table
      :columnDefinitions="columnDefinitions"
      :rows="tableHelper.pagedData"
      :loadingPromise="loadingPromise"
      @sort="handleSort"
      :defaultSortColumn="defaultSortColumn"
      :defaultSortOrdering="defaultSortOrdering"
    >
      <sb-table-row slot="sb-table-row" slot-scope="{row}">
        <sb-table-cell  >
          <a :href="app_link+row.id" target="_blank" rel="noopener noreferrer">
            <vs-tooltip :text="row.id + ' : ' + row.name" position="top">
              <p class="mb-0">{{row.name}}</p>
            </vs-tooltip>
          </a> 
        </sb-table-cell>
        <sb-table-cell  >{{row.items}}</sb-table-cell>
        <sb-table-cell>{{row.orders}}</sb-table-cell>  
      </sb-table-row>
      <sb-table-empty-state slot="sb-table-empty-state" icon-name="products" label="No Exception Orders">
        <p>Take a break! All orders have enough inventory!</p>
      </sb-table-empty-state>
      <pagination-wrapper
        slot="pagination"
        @fetchPage="handleFetchPage"
        :pageSize="tableHelper.pagination.pageSize"
        :currentPage="tableHelper.pagination.currentPage"
        :totalPages="tableHelper.pagination.totalPages"
        :totalRecords="tableHelper.pagination.totalRecords"
        :visibleRecords="tableHelper.pagination.visibleRecords"
      ></pagination-wrapper>
    </sb-table>
            
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
     components: {
    "pagination-wrapper": PaginationWrapper
  },
    data() {
        return { 
            data: {
			    totalOrdersOutOfStock:0,
                totalItemQuantity:0 
            },
             app_link: process.env.VUE_APP_SHIPBOB_LINK+'app/Merchant/#/Products/',
            loadingPromise: null,
      columnDefinitions: [
        { name: "Inventory Name", field: "name", sortable: true }, 
        { name: "Items in Exception", field: "items", sortable: true }, 
        { name: "Total Orders Affected", field: "orders", sortable: true } 
      ],
      tableHelper: new tabler(this, 12)
        }
    },
    mounted() {
       this.loadingPromise = this.fetch(1); 
    },
     computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },
    methods: {
       handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    map(item) {
      return {
                        id: item.id,
                        name: item.name,
                        items: item.items,
                        orders: item.orders
      };
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    },
    fetch: async function(p) {
      await new Promise(res => {
        this.tableHelper.preLoadReset();
            let configShippingMethod = {
                fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
                toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
                compare: this.$store.state.comparison,
                pageSize:10000,
                pageNumber: 1
            }
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/exceptions', configShippingMethod).then((response) => {
                let OrdersExceptions = response.data; 
                 this.tableHelper.loadData(OrdersExceptions.data, p);
                this.$store.commit('setDataExceptions', OrdersExceptions.data);
            });
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/exceptions/summary', configShippingMethod).then((response) => {
                let OrdersExceptionsSummary = response.data;
                this.data.totalOrdersOutOfStock = OrdersExceptionsSummary.totalOrdersOutOfStock;
                this.data.totalItemQuantity = OrdersExceptionsSummary.totalItemQuantity;
                this.loading = false;
                this.$root.$emit('finishedFetch');
            res(true);
        });
      });
        }
    }
}
</script>
import { render, staticRenderFns } from "./Forbidden.vue?vue&type=template&id=cca272c0&scoped=true&"
import script from "./Forbidden.vue?vue&type=script&lang=js&"
export * from "./Forbidden.vue?vue&type=script&lang=js&"
import style0 from "./Forbidden.vue?vue&type=style&index=0&id=cca272c0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cca272c0",
  null
  
)

component.options.__file = "Forbidden.vue"
export default component.exports
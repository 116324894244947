<template> 
    <div class="main-content">
      <div class="header-container">
        <base-header :heading="'Daily Inventory History'" :description="'Check how much inventory you were holding at any ShipBob fulfillment center at any point in time. Search by item, filter by a specific lot number, and account for inventory in transit.'" slot="header">
          <div slot="action">
            <div class="btn-group btn-group-nav" role="group">
              <div class="btn-group btn-group-nav" role="group">
                  <download-data-button :dataName="'dataInventoryHistoryByItem'"   ></download-data-button>
         
              </div>
            </div>
          </div>
        </base-header>
      </div>
 
         <component :is="'inventory-history-item'"   />
       
  </div>
</template>
<script>
export default {
  data() {
    return {
     
    };
  },
  mounted() { 
  },
  methods: {
    getDate() {
      var options1 = { year: "numeric", month: "short", day: "numeric" };
      var options2 = { hour: "numeric", minute: "numeric" };

      return (
        "Last Updated: " +
        new Date().toLocaleDateString("en-US", options1) +
        " at " +
        new Date().toLocaleTimeString("en-US", options2)
      );
    }
  }
};
</script>
<style scoped lang='scss'>
@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss";
@import "@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss";
//@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard-panel-template.scss";
.main-content {
  background-color: #fff;
}

</style>
 

<template>     
      <div class="main-content">
        <div class="header-container"> 
	   <base-header :heading="'Analytics Dashboard'"
                 :description="''"
                 slot="header"
                 > 
				 <div slot="action">
        <div class="btn-group btn-group-nav" role="group">
          <div class="btn-group btn-group-nav" role="group">
           <div><span class="dashboard-lastupdate-tag">{{this.getDate()}}</span> 
		   </div> 
          </div>
        </div>
      </div>
    	</base-header>
		</div>
       <div class="dashboard-container"> 
						<card-full-width 
							:title="'Today\'s B2C Order SnapShot'" 
							:kindTemplate="'order-status'" 
							:tooltipPosition="'right'"
							:disableHeader="true"
							:disableFooter="true"
						/>
				 
						<card-half-width 
							:title="'Exception Summary (All Orders)'" 
							:kindTemplate="'exception-summary'"
							:disableHeader="true"
							:disableFooter="true"
						/>
					 
						<card-half-width 
							:title="'Out of Stock Details (All Orders)'" 
							:kindTemplate="'exception-outofstock'"
							:disableHeader="true"
							:disableFooter="true"
						/>
				 
						<card-half-width
							:title="'B2C Fulfillment Metrics'" 
							:kindTemplate="'key-metrics'"
							:disableHeader="true"
							:disableFooter="true"
						/>
				 
						<card-half-width
							:title="'B2C Transit Time (Last 30 Days)'" 
							:kindTemplate="'transit-time'"
							:disableHeader="true"
							:disableFooter="true"
						/>
				  
		</div>
		</div> 
	 
</template>
<script>   

export default {
components: {
	 
  
  },
	data() {
		return {
			showExceptionSummary: true, 
			value1:'',
			select1:[],
			options1:[
				{text:'Previous period',value:'PreviousPeriod'},
				{text:'Previous year',value:'PreviousYear'},
			],
			 showTransitionModal: false ,
			   breakpoints: {
          wrap: el => el.width < 500,
          full: el => el.width >= 500
		}
		} 
	},
	mounted() {
		this.$root.$on('showExceptionSummary', this.showCards);
		this.showCards();
	},
	methods: {
		getDate()
		{
			var options1 = { year: 'numeric', month: 'short', day: 'numeric'};
			var options2 = { hour:'numeric',minute:'numeric' };
			 
			return "Last Updated: " + new Date().toLocaleDateString("en-US", options1) + " at " + new Date().toLocaleTimeString("en-US", options2);
		},
		showCards() {
			this.showExceptionSummary = this.$store.state.showExceptionSummary;
		},
		 wrapStyles (el) {
          let baseStyles = 'justify-content: space-between;'
          if (el.is.wrap) return `${baseStyles} flex-wrap: wrap; flex-direction: column-reverse;`
          return `${baseStyles}`
        },
        mainContentStyles (el) {
          if (el.is.wrap) return ''
          return 'max-width: 900px;'
        },
        subPanelStyles (el) {
          const baseStyles = 'flex-direction: column;'
          if (el.is.wrap) return `${baseStyles} margin-bottom: 1em;`
          return `${baseStyles} max-width: 300px; margin-left: 2em;`
        }
	}
	  
}
</script>
<style scoped lang='scss'>
  @import '@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss';
  @import '@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss'; 
.main-content {
  background-color: #f2f3f5
} 
// .dashboard-panel-large
// {
//     flex: 0 0 96%
// }
// .dashboard-panel-medium {
//     flex: 0 0 46%
// }
// @media all and  (max-width: 1300px) {
// .dashboard-panel-medium  {
//     -webkit-box-flex: 0;
//     -ms-flex: 0 0 95%;
//     flex: 0 0 95%;
// }}

</style>
 
<template>
    <div>
        <vs-row vs-justify="space-around">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                <!-- <v-select :options="salesItems" label="text" v-model="selectedIventoryID" placeholder="Inventory Number ID" @input="changeItem()" style="width:100%; padding: 10px"></v-select> -->
                <sb-dropdown-search v-model="selectedIventoryID" :options="options1" @input="changeItem()" :minSearchTermLength="0" @search="handleSearch" :showSelectedOptions="true" :comparator = comparator :loading="false" class="dropdown-filter filter-inventory" placeholder="Search by inventory name, or ShipBob ID..">
                    <sb-selected-dropdown-search-option slot="selection" slot-scope="{ selection }" class="selection-placeholder" :value="selection.text || 'Search by inventory name or ShipBob ID.'"/>
                    <sb-dropdown-option slot-scope="{ option }">{{ option.value==0?option.text: option.value + " - " + option.text }}  </sb-dropdown-option>

                </sb-dropdown-search>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <span class="di-legend"> Based on the past </span>
                <!-- <select class="form-control select-card" @change="changeComparisonTime" label="Figuras" v-model="selectBasedOn" >
                    <option :key="index" :value=item.value :vs-text="item.text" v-for="(item,index) in basedOnItems">{{item.text}}</option>>
                </select> -->
                <sb-dropdown :options="basedOnItems" v-model="selectBasedOn" @input="changeComparisonTime()"   class="dropdown-filter filter-short">
                    <sb-selected-option slot="selection" slot-scope="{ selected }">
                        {{ selected.text }}
                    </sb-selected-option>
                    <sb-options>
                        <sb-option slot-scope="{ option }" :value="option">
                            {{ option.text }}
                        </sb-option>
                    </sb-options>
                </sb-dropdown>
            </vs-col>
            <vs-col class="ml-2" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                <span>Add Expected % increase of units sold per day</span>
                <input type="text" name="percent"  @change="changePercentInventorySummary" class="form-control" style="max-width: 100px" v-model="inputPercent">
            </vs-col>           
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
                <sb-button @click="update"  variation="primary"   size="medium" @click.prevent="" >Apply</sb-button>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                The % increase will be added to the Avg Units Sold per Day number
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
import moment from 'moment';
import {cloneDeep } from 'lodash';

export default {
    props:['salesItems', 'basedOnItems'],
    data() {
        return {
            selectedIventoryID:0,
            options1: [],
            checkBox1:false,
            selectBasedOn: {text:'30 Days',value:'30'},
            inputPercent: '0',
            loading: false,
            comparator(a, b) {
              return a.id === b.id
            },
        }
    },
    methods: {
        handleSearch ({ searchTerm }) {
          if (searchTerm.length > 1) {
           searchTerm = searchTerm.toLowerCase();
            this.options1 = cloneDeep(this.salesItems.filter(x => (x.text.toLowerCase().indexOf(searchTerm) !== -1) || (x.value.toString().indexOf(searchTerm) !== -1) ))
          }
          else this.options1 = this.salesItems
        },
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        update() {
            this.loading = true;
            this.$root.$emit('updateInventorySummary');
        },
        changeItem() {
            this.$store.commit('setsales_itemIDInventorySummary', this.selectedIventoryID.value);
        },
        changeComparisonTime(){
            this.$store.commit("setdi_timeInventorySummary", this.selectBasedOn.value)
        },
        changePercentInventorySummary(){
            this.$store.commit("setpercent_inventorySummary", this.inputPercent);
        }
    },
    mounted() {
        this.$store.commit('setsales_itemIDInventorySummary', this.selectedIventoryID.value);
        this.$store.commit("setdi_timeInventorySummary", this.selectBasedOn);
        this.$store.commit("setinclude_b2b_salesInventorySummary", this.checkBox1);
        this.$store.commit("setpercent_inventorySummary", this.inputPercent);
        
        this.$root.$on('finishedFetch', () => { this.loading = false; this.options1 = this.salesItems});
    }
}
</script>


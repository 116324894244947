<template>
     <div v-if="this.loading" class="spin-center-ideal-distribution center">
        <div class="ld ld-ring ld-spin running"></div>
    </div>
    <div v-else class="mb-4">
        <vs-row vs-w="12" vs-justify="center" vs-align="center">
            <vs-col vs-w="6">
                <indicator-subtitle-delta :value="data.inventorySalesUnitsSold" subtitle="Total Units Sold" :delta="data.inventorySalesUnitsSoldDelta" :showSimbol="false"/>
            </vs-col>
        </vs-row>
        <vs-divider></vs-divider>
        <vs-row vs-w="12" vs-justify="center" vs-align="center">
            <vs-col vs-w="12">
                <vs-table search :max-items="maxItemsUnit" maxHeight="230px" hoverFlat pagination :data="receivedData" class="table-Unitssold">
                    <template slot="thead">
                        <template v-for="item in data.unitsSoldColumns">
                            <vs-th :key="item" :sort-key="item" v-if="item === 'Item Name'" class="table-Unitssold-name">{{item}}</vs-th>
                            <vs-th :key="item" :sort-key="item" v-else>{{item}}</vs-th>
                        </template>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td :data="data[indextr]['Item Name']" class="table-Unitssold-name">
                                {{data[indextr]['Item Name']}}
                            </vs-td>
                            <vs-td :data="data[indextr]['Units Sold']">
                                {{data[indextr]['Units Sold']}}
                            </vs-td>
                            <vs-td :data="data[indextr]['Change']">
                                <span v-if="data[indextr]['Change'] < 0" style="color:red;">
                                    <i class="material-icons icon-arrows">arrow_downward</i>
                                    {{Math.abs(data[indextr]['Change'])}} %
                                </span>
                                <span v-else style="color:green;">
                                    <i class="material-icons icon-arrows">arrow_upward</i>
                                    {{data[indextr]['Change']}} %
                                </span>
                            </vs-td>
                            <vs-td :data="data[indextr]['% of Sales']">
                                {{data[indextr]['% of Sales']}} %
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </vs-col>
        </vs-row>
        <vs-row class="px-3">
            <vs-col class="d-flex justify-content-end align-items-center">
                <label class="mb-0" style="font-size: 12px" for="numbre">Show:  </label>
                <input type="number" min="1" class="form-control p-1 ml-1" style="max-width: 45px" v-model="maxItemsAux" @change="changeMaxItems">
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'
export default {
    data() {
        return {
            maxItemsUnit: 20,
            maxItemsAux: 20,
            receivedData: [],
            data: {
                unitsSoldColumns: ['Item Name','Units Sold', 'Change', '% of Sales'],
                inventorySalesUnitsSold:0,
                inventorySalesUnitsSoldDelta:0
            },
            loading: false
        }
    },
    mounted(){
        this.fetch();
        this.$root.$on('update', this.fetch);
    },
    methods: {
        changeMaxItems() {
            if(this.maxItemsAux > 0){
                this.maxItemsUnit = this.maxItemsAux;
            }
        },
        fetch() {
            this.loading = true;
            this.receivedData = [];
            
            let unitsSoldConfig = {
				fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
                toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
                includeB2B:this.$store.state.includeB2BSales,
                compare: this.$store.state.comparison,
                pageNumber:1,
                pageSize:10000
            }
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales', unitsSoldConfig).then((response) => {
                let InventorySalesGeneral = response.data;
                // console.log(InventorySalesGeneral);
                this.data.inventorySalesUnitsSold = InventorySalesGeneral.unitSold;
                this.data.inventorySalesUnitsSoldDelta = InventorySalesGeneral.delta;
                InventorySalesGeneral.items.forEach((item, index) => {
                    this.receivedData.push({
                        "Item Name": item.name,
                        "Units Sold": item.unitSold,
                        "Change": item.delta,
                        "% of Sales": item.percentageOfSales
                    });
                });
                this.loading = false;
                this.$store.commit('setDataUnitSold', this.receivedData);
                this.$root.$emit('finishedFetch');
            });
        }
    }
}
</script>
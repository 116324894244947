<template>
    <div>
        <b-form inline class="search-bar">            
            <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                <select class="form-control select-card" @change="changeComparison" v-model="select1SkuVelocity">
                    <option :key="index" :value=item.value :vs-text="item.text" v-for="(item,index) in items1">{{item.text}}</option>>
                </select>
                <!-- <vs-select @change="changeComparison" class="selectExample" v-model="select1SkuVelocity">
                    <vs-select-item :key="index" :vs-value="item.value" :vs-text="item.text" v-for="(item,index) in items1" />
                </vs-select> -->
            </b-input-group>
            <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                <select class="form-control select-card" @change="changeComparison" v-model="select2SkuVelocity">
                    <option :key="index" :value=item.value :vs-text="item.text" v-for="(item,index) in items2">{{item.text}}</option>>
                </select>
                <!-- <vs-select @change="changeComparison" class="selectExample" v-model="select2SkuVelocity">
                    <vs-select-item :key="index" :vs-value="item.value" :vs-text="item.text" v-for="(item,index) in items2" />
                </vs-select> -->
            </b-input-group>
            <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                <vs-button color="primary" type="button" @click="update" class="ld-ext-right">Apply </vs-button>
            </b-input-group>
        </b-form>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    props:['items1', 'items2'],
    data() {
        return {
            select1SkuVelocity: '7days', 
            select2SkuVelocity: '30days',
            loading: false
        }
    },
    mounted() {
        this.$root.$on('finishedFetch', () => { this.loading = false; });
    },
    methods:{ 
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        changeComparison() {
            this.$store.commit('setSkuVelocityDropdown1Text', this.select1SkuVelocity);
            this.$store.commit('setSkuVelocityDropdown1Value', this.select1SkuVelocity);
            this.$store.commit('setSkuVelocityDropdown2Text', this.select2SkuVelocity);
            this.$store.commit('setSkuVelocityDropdown2Value', this.select2SkuVelocity);
        },
        update(){
            this.loading = true;
            this.$root.$emit('updateSkuVelocity');
        }
    }
}
</script>

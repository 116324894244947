import toastr from 'toastr'
import { HttpUtil } from '../http'
import AuthorizationService from '../../services/authorization'
import AuthTokenUtil from '@/utils/http/authToken'
import store  from '../../store'
// import Cookie from './cookie'

const MAX_RETY = 2
const LOGIN_URL = '/'

export default class HttpWrapper {

  static noop () {}

  static failedRequest (res) {
    return (res === undefined || !/2[0-9][0-9]/.test(res.status || (res.response && res.response.status)))
  }

  static unauthorized (res) {
    let c = /401/.test(res && (res.status || (res.response && res.response.status))) 
    return c;
  }

  static captureError (res) {
    const baseMessage = 'Error occurred!'
    let errorMessage = typeof res.data === 'object' ? res.statusText : res.data || res.response ? res.response.data : ''
    if (errorMessage === undefined || errorMessage.length === 0 || typeof errorMessage === 'object') errorMessage = baseMessage
    return errorMessage
  }

  static displayErrorMessage (message) {
    toastr['error'](message)
  }

  static extendRequest (request, opts) {
    return Object.assign({}, request, opts)
  }

  static addAuthHeaders (request, headersObj) {
    const { accesstoken } = headersObj
    const Headers = typeof accesstoken === 'undefined' ? {} : { 'Authorization': `Bearer ${accesstoken}` }
    return this.extendRequest(request, {Headers})
  }

  static maxRetry (retry) {
    return retry >= MAX_RETY
  }

  static async Delete (...args) {
    return await this.Call('Delete', ...args)
  }

  static async Put (...args) {
    return await this.Call('Put', ...args)
  }

  static async Post (...args) {
    
    return await this.Call('Post', ...args)
  }

  static async Get (...args) {
    return await this.Call('Get', ...args)
  }

  static async goToLogin () {
    setTimeout(function () {
      store.dispatch('user/resetUser')
      store.dispatch('resetAuthentication')
      window.location.replace(LOGIN_URL)
    }, 0)
  }

  static async Call (type, request, successCb = this.noop, failCb = this.noop, disableErrorMessages = false, retry = 0) {
    if (this.maxRetry(retry)) {
      // Make sure this is processed after the current promise is resolved
      this.goToLogin()
      return
    }
    const fail = failCb || this.noop
    const success = successCb || this.noop
    const finalRequest = this.addAuthHeaders(request, AuthTokenUtil.extractAuth())
    const res = await HttpUtil[type](finalRequest)
    if (this.unauthorized(res)) {
     
      const good = await AuthorizationService.refreshToken()
       
      if (!good ){//&& window.location.pathname != LOGIN_URL) {
        //console.log(window.location.pathname);
        if(window.location.pathname == LOGIN_URL)
        {return;}
        this.goToLogin()
        return ;
      }
      const updatedRequest = this.addAuthHeaders(request, AuthTokenUtil.extractAuth())
      return await this.Call(type, updatedRequest, successCb, failCb, disableErrorMessages, retry + 1)
    } else if (this.failedRequest(res)) {
      fail(res)
      if (!disableErrorMessages) this.displayErrorMessage(this.captureError(res))
      return false
    }
    
    AuthTokenUtil.persistAuth(AuthTokenUtil.extractFromHeader(res.headers))
    success(res)
    if (/204/.test(res.status)) return true
    return res.data 
  }
}

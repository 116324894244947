<template>
   
    <div class="main-content">
      <div class="sticky-bar-perfomarce">
        <div class="header-container">
          <base-header :heading="'SKU Performance'" :description="''" slot="header">
            <div slot="action">
              <div class="btn-group btn-group-nav" role="group">
                <div class="btn-group btn-group-nav" role="group">
                  <div>
                    <search-bar-simple :items="options1" @update="update()" />
                  </div>
                </div>
              </div>
            </div>
          </base-header>
        </div>
      </div>
      <div class="dashboard-container">
        <card-full-width
          :title="'Units Sold Performance'"
          :kindTemplate="'units-sold-shipped-performance'"
          :dataName="'dataUnitsSoldPerformance'"
          :disableHeader="true"
        />

        <card-full-width
          :title="'Units Sold By Channel'"
          :kindTemplate="'units-sold-shipped-channel'"
          :topRightText="'(' + this.dates + ')'"
        />
      </div>
    </div> 
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      options1: [
        { text: "Previous period", value: "PreviousPeriod" },
        { text: "Previous year", value: "PreviousYear" }
      ],
      dates:'' 
    };
  },
  mounted(){ 
    this.setDates();
		this.$root.$on('update', ()=>{  
       this.setDates();
    }); 
  },
   destroyed: function () { 
     this.$root.$off('update', ()=>{ 
     this.setDates();
    }); 
   },
   methods:{
     setDates(){
       let config = {
                fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				        toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY')
            }
      this.dates = config.fromDate + " to " + config.toDate;
     }
   }
};
</script>
<style scoped lang='scss'>
@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss";
@import "@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss";
//@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard-panel-template.scss";
.main-content {
  background-color: #f2f3f5;
}
</style>
 
import ValidateUtil from '../../utils/validate'

export default class UserValidationModel {
  constructor () {
    this.locale = 'en'
  }
  validationMap () {
    return {
      firstName: ['presence'],
      lastName: ['presence'],
      email: ['presence', 'email'],
      password: ['presence', 'password'],
      companyName: ['companyName'],
      companyWebsite: ['presence', 'website'],
      totalOrdersPerMonth: ['presence', 'positive'],
      confirmPassword: ['presence', 'password', 'match password'],
      phoneNumber: ['presence'],
      noOfSkus: ['presence', 'greaterThan 0'],
      launchDate: ['presence'],
      whenToStartShipping: ['presence'],
      hasHeavyItems: []
    }
  }
  mapAttributeGetter (attribute, options) {
    const args = Object.assign({}, {locale: this.locale}, options)
    const value = this[`_${attribute}`]
    const validation = this.validate(attribute, value, args)
    return { value, validation }
  }
  mapAttributeSetter (attribute, value) {
    this[`_${attribute}`] = value
  }

  validate (property, value, args) {
    const validations = this.validationMap()[property] || []
    let valid = true
    let validationMessage = ''
    for (let validation of validations) {
      let v = ValidateUtil.validate(validation, value, args)
      if (!v.valid) {
        valid = v.valid
        validationMessage = v.message
        return { valid, validationMessage }
      }
    }
    return { valid, validationMessage }
  }

  _validationMap () {
    return {
      signup: {
        validatedAttributes: ['firstName', 'lastName', 'password', 'email', 'companyWebsite'],
        boolAttributes: ['verifiedRecaptcha'],
        nonNullAttributes: []
      },
      login: {
        validatedAttributes: ['email', 'password'],
        boolAttributes: [],
        nonNullAttributes: []
      },
      forgotPassword: {
        validatedAttributes: ['email'],
        boolAttributes: [],
        nonNullAttributes: []
      },
      newPassword: {
        validatedAttributes: ['password', 'confirmPassword'],
        boolAttributes: [],
        nonNullAttributes: []
      },
      phone: {
        validatedAttributes: ['phoneNumber'],
        boolAttributes: [],
        nonNullAttributes: []
      },
      about: {
        validatedAttributes: ['companyName', 'website'],
        boolAttributes: [],
        nonNullAttributes: ['newToShipBob']
      },
      business: {
        validatedAttributes: ['noOfSkus'],
        boolAttributes: [],
        nonNullAttributes: ['whenToStartShipping'],
        selectAttributes: ['selectedPlatforms']
      },
      signupModal: {
        validatedAttributes: ['firstName', 'lastName', 'email', 'password'],
        boolAttributes: [],
        nonNullAttributes: []
      },
      launchDate: {
        validatedAttributes: ['companyName', 'noOfSkus', 'launchDate'],
        boolAttributes: [],
        nonNullAttributes: []
      },
      instantPriceQuote: {
        validatedAttributes: ['noOfSkus', 'totalOrdersPerMonth'],
        boolAttributes: [],
        nonNullAttributes: ['whenToStartShipping'],
        selectAttributes: ['selectedPlatforms']
      }
    }
  }

  isValid (type) {
    const { validatedAttributes = [], boolAttributes = [], nonNullAttributes = [], selectAttributes = [] } = this._validationMap()[type]
    return validatedAttributes.map(va => this[va].validation.valid)
                              .concat(boolAttributes.map(ba => this[ba]))
                              .concat(nonNullAttributes.map(nna => this[nna] !== null))
                              .concat(selectAttributes.map(val => this[val].length > 0))
                              .every(v => v === true)
  }
}

<template> 
    <!-- <div class="main-content">
    
<div class="sticky-bar-perfomarce">
	<div class="header-container"> 
	<base-header :heading="'Shipping Performance'" :description="''"  :showSearch="false" slot="header" >
	 
          <div slot="action"  >
            
                  <search-bar-simple :items="options1" @update="update()" />
              
          </div> 
        </base-header>
        
      </div>
	   </div> -->

     <div class="main-content">
      <div class="sticky-bar-perfomarce">
        <div class="header-container">
          <base-header :heading="'Shipping Performance'" :description="''" slot="header">
            <div slot="action">
              <div class="btn-group btn-group-nav" role="group">
                <div class="btn-group btn-group-nav" role="group">
                  <div>
                    <search-bar-simple :items="options1" @update="update()" />
                  </div>
                </div>
              </div>
            </div>
          </base-header>
        </div>
      </div>
     
      <div class="dashboard-container">
        <card-half-width
          :title="'Top 4 Shipping Methods'"
          :kindTemplate="'shipping-methods'" 
          :paragraph1="'Breakdown transit times, and average cart amount by shipping method to see which shipping methods are most attractive to your customers. Understand how much your clients are spending by shipping method.'"          
          :footer="'These shipping methods only include delivered B2C orders. Excluded are B2B orders and internal and external transit orders.'"
          :tooltipPosition="'right'"
          :topRightText="'(' + this.transitTimeDates + ')'"
        />

        <card-half-width :title="'Transit Time (Business Days)'" :kindTemplate="'transit-time-v2'" 
          :paragraph="'View a % breakdown of the days it takes for orders to be delivered. By default ALL shipping methods offered are displayed, including domestic and international.'"
        :paragraph1="'From the dropdown menu, you can select specific shipping methods. The shipping methods are unique to the terminology used in the cart of your ecommerce platform.'"        
        :footer="'NOTE: These shipping options are NOT a direct match to ShipBob\'s shipping options (Standard, Expedited, etc.) You can select multiple options to group them and display the average.'"
        :topRightText="'(' + this.transitTimeDates + ')'"
        />

        <card-half-width
          :title="'Top Selling States'"
          :kindTemplate="'top-selling'"
          :paragraph="'Pinpoint the top US states with the highest sales volume.'"
          :footer="'These shipping methods only include delivered B2C orders. Excluded are B2B orders and internal and external transit orders'"
          :tooltipPosition="'right'"
          :topRightText="'(' + this.transitTimeDates + ')'"
          
        />

        <card-half-width
          :title="'State Performance'"
          :kindTemplate="'state-details'"
          :dataName="'dataStateDetails'"
         :paragraph="'View a breakdown of the % of orders that are shipped to US states along with the fulfillment cost and average transit time.'"
        :paragraph1="'Filter the information by shipping method and and apply custom date ranges. The shipping methods are unique to the terminology used in the cart of your ecommerce platform.'"
        :paragraph2="'NOTE: These shipping methods are NOT a direct match to ShipBob\'s shipping options (Standard, Expedited, etc.) You can select multiple selections to group them and display the average.'"
        :topRightText="'(' + this.transitTimeDates + ')'"

        />
      </div>
    </div> 
</template>
<script>
export default {
  data() {
    return {
      options1: [
        { text: "Previous period", value: "PreviousPeriod" },
        { text: "Previous year", value: "PreviousYear" }
      ],
      transitTimeDates:'' 
    };
  },
  mounted(){ 
		this.$root.$on('finishedsetTransitTimeDates', ()=>{ 
      this.transitTimeDates = this.$store.state.transitTimeDates;
    }); 
  },
   destroyed: function () { 
     this.$root.$off('finishedsetTransitTimeDates', ()=>{ 
      this.transitTimeDates = this.$store.state.transitTimeDates;
    }); 
   }
};
</script>
<style scoped lang='scss'>
  @import '@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss';
  @import '@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss';
  //@import '@/../assets/scss/shipbob-custom/components/dashboard/_dashboard-panel-template.scss';
.main-content {
  background-color: #f2f3f5
} 

</style>
 

<template>
  <div id="appContainer">
    <admin-bar v-if="isAdmin" :offset.sync="offset"></admin-bar>
    <div>
      <div class="master-layout-container" :style="adminMargin">
        <master-side-nav
          @handleSideNavSm="handleSideNavSm"
          :showQuickSetup="true"
          :sideNavType="sideNavType"
          :navLinks="navLinks"
          :style="adminHeight"
        ></master-side-nav>
        <fade-transition :mode="'out-in'">
          <slot></slot>
        </fade-transition>
      </div>
    </div>
    <sb-splash-screen id="splashScreenStyleWithSideNav"></sb-splash-screen>

    <FullScreenModal
      id="FullScreenModal"
      v-if="!hasShipped"
      :loading="false"
      :bgColor="'white'"
      :hideSubmitButton="true"
      :hideCloseButton="true"
    >
      <div slot="formContent">
        <div class="form-row">
          <div class="col">
            <h1 class="title">
              ShipBob Analytics
            </h1>
            <!-- <h4 class="subtitle" style="text-align:center">Utilize smart technology with ShipBob.</h4> -->
            <div
              style="text-align:center;max-width:500px"
            >Once you start shipping orders with ShipBob, your data will automatically populate, and you can start using the full suite of features to drive insights for your business.</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col" style="text-align:center">
            <img src="../../assets/dashboard_img.png" />
          </div>
        </div>
      </div>
    </FullScreenModal>
  </div>
</template>
<script>
import SbSplashScreen from "@/components/utils/SbSplashScreen";
import OrdersLinks from "@/models/nav-links";
import MasterSideNav from "@/components/layouts/MasterSideNav";
import AdminBar from "@/components/layouts/AdminBar";
import AppTemplateMixin from "./AppTemplateMixin";
import UserService from "@/services/user";
import LookupService from "@/services/lookup";
import FullScreenModal from "@/components/layouts/FullScreenModal";

export default {
  name: "AppSideNavTemplate",
  components: { SbSplashScreen, MasterSideNav, AdminBar, FullScreenModal },
  mixins: [AppTemplateMixin],
  data() {
    return {
      navLinks: OrdersLinks,
      sideNavSmOpen: false,
      impersonatedUser: {},
      loggedInUser: {},
      hasShipped: true //show nothing to start with
    };
  },
  created() {
    this.loadUser();
  },
  computed: {
    adminHeight() {
      if (this.$mq === "sm" && !this.sideNavSmOpen) return;
      const height = `calc(100vh - ${this.offset}px)`;
      return { height };
    },
    isAdmin() {
      return this.loggedInUser.IsAdmin;
    }
  },
  methods: {
    loadUser() {
      UserService.getLoggedInAndImpersonatedUser().then(result => {
        this.$store.commit("setImpersonatedUser", result.ImpersonatedUser);
        this.$store.commit("setLoggedInUser", result.LoggedInUser);
        this.impersonatedUser = result.ImpersonatedUser;
        this.loggedInUser = result.LoggedInUser;
      });
      UserService.getHasShipped().then(result => {
        this.$store.commit("setuserHasShipped", result);
        this.hasShipped = result;
      });
      LookupService.GetSettingIsEnabled({name:"PowerBIArtEnabled"}).then(result => {
        this.$store.commit("setPowerBIEnabled", result);
        if (result==true) { this.$root.$emit('pbiEmbed'); }      
      });
    },
    handleSideNavSm(val) {
      this.sideNavSmOpen = val;
    }
  }
};
</script>
<style lang="scss">
#splashScreenStyleWithSideNav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  height: 100%;
  @media only screen and (min-width: 0px) {
    left: 0px;
    width: 100%;
  }
  @media only screen and (min-width: 576px) {
    left: 60px;
    width: calc(100% - 60px);
  }
  @media only screen and (min-width: 1024px) {
    left: 256px;
    width: calc(100% - 256px);
  }
}

.primary-nav {
  background: linear-gradient(
    to top,
    #378ac5 22.98%,
    #2c7bb9 36.28%,
    #2673b1 52.12%,
    #2470af 77.1%
  ) !important;
  background: -webkit-linear-gradient(
    to top,
    #378ac5 22.98%,
    #2c7bb9 36.28%,
    #2673b1 52.12%,
    #2470af 77.1%
  ) !important;
  background: -moz-linear-gradient(
    to top,
    #378ac5 22.98%,
    #2c7bb9 36.28%,
    #2673b1 52.12%,
    #2470af 77.1%
  ) !important;
}
</style>
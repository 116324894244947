import HttpWrapper from '../utils/http/httpWrapper'
import AuthTokenUtil from '../utils/http/authToken'
const VUE_APP_ENDPOINT = process.env.VUE_APP_ENDPOINT


export default class LookupService {
  static noop () {}

  static urls () {
    return {  
      getSettingIsEnabled: VUE_APP_ENDPOINT + 'lookup/settings'
    }
  } 

  static async GetSettingIsEnabled (req) {  
    const request = {
      Url: this.urls().getSettingIsEnabled+"?name="+ req.name + "&value=" + req.value,
    };
    return await HttpWrapper.Get(request, null, null, true); 
  } 
}

// // const proxyObj = (defaultVal, obj) => {
// //   return new Proxy(obj, {
// //     get: (o, k) => {
// //       if (typeof o[k] === 'object' && !(o[k] instanceof Array)) return proxyObj(defaultVal[k].val, o[k])
// //       else if (k in o) {
// //         if ((typeof o[k] === 'object' && o[k] instanceof defaultVal[k].type) || (typeof o[k] === defaultVal[k].type.name.toLowerCase())) {
// //           return o[k]
// //         } else {
// //           const type = typeof o[k]
// //           const shouldBe = defaultVal[k].type
// //           console.warn(`${k} should be [${shouldBe}], but is [${type}]`)
// //           return defaultVal[k].val
// //         }
// //       } else {
// //         return defaultVal[k].val
// //       }
// //     }
// //   })
// // }

// // const ObjectWithDefault = (defaultVal, obj = {}) => {
// //   return proxyObj(defaultVal, obj)
// // }

// // const MapDefaultsToModel = (context, defaultObj, proxiedObj) => {
// //   for (let key in defaultObj) {
// //     context[key] = proxiedObj[key]
// //   }
// // }

// const basicType = ['Object', 'Number', 'String', 'Boolean', 'Array']
// const proxyObj = (defaultVal, obj = {}) => {
//   return new Proxy(obj, {
//     get: (o, k) => {
//       if (o[k] !== undefined && defaultVal[k] && defaultVal[k].type && !basicType.includes(defaultVal[k].type.name)) return new defaultVal[k].type(o[k]) // eslint-disable-line
//       else if (o[k] !== undefined && o[k] !== null && o[k].constructor.name === 'Object') return proxyObj(defaultVal[k].val, o[k])
//       else if (k in o) {
//         if (o[k] !== undefined && o[k] !== null && defaultVal[k] && defaultVal[k].type && o[k].constructor.name === defaultVal[k].type.name) {
//           return o[k]
//         } else {
//           const type = typeof o[k]
//           const shouldBe = defaultVal[k] && defaultVal[k].type && defaultVal[k].type.name
//           console.warn(`${k} should be [${shouldBe}], but is [${type}]`)
//           return defaultVal[k].val
//         }
//       } else {
//         if (defaultVal[k]) return defaultVal[k].val
//       }
//     }
//   })
// }
// const ObjectWithDefault = (defaultVal, obj) => {
//   return proxyObj(defaultVal, obj)
// }
// const MapDefaultsToModel = (context, defaultObj, proxiedObj) => {
//   for (let key in defaultObj) {
//     context[key] = proxiedObj[key]
//   }
// }

// export { ObjectWithDefault, MapDefaultsToModel }

const constructKeys = (defaultObj, obj) => {
    const defaultObjKeys = Object.keys(defaultObj)
    const objKeys = Object.keys(obj)
    return {
      defaultKeys: defaultObjKeys,
      extraKeys: objKeys.filter(x => defaultObjKeys.indexOf(x) < 0)
    }
  }
  
  const getVals = (key, defaultObj, obj) => ({defaultVal: defaultObj[key], objVal: obj[key]})
  
  const noVal = (objVal) => typeof objVal === 'undefined' || objVal === null
  
  const valWrongType = (defaultVal, objVal) => {
    const { type } = defaultVal
    return type === null || type === undefined || objVal.constructor.name !== type.name
  }
  
  const nonCustomTypes = ['Object', 'Number', 'String', 'Boolean', 'Array', 'Set', 'Map']
  
  const isCustomType = (obj) => obj !== null && obj !== undefined && !nonCustomTypes.includes(obj.constructor.name)
  
  const isObj = (obj) => obj !== null && obj !== undefined && obj.constructor.name === 'Object'
  
  const isArray = (arr) => Array.isArray(arr)
  
  const calculateValue = (defaultVal, objVal) => {
    if (isCustomType(defaultVal)) return constructClass(defaultVal, objVal)
    else if (isObj(defaultVal)) return constructObj(defaultVal, objVal, {})
    else if (isArray(defaultVal)) return constructArray(defaultVal, objVal)
    return objVal !== undefined ? objVal : defaultVal
  }
  
  const constructClass = (defaultVal, objVal) => objVal !== undefined ? objVal : defaultVal
  
  const constructArray = (defaultVal, objVal = []) => {
    const len = Math.max(defaultVal.length, objVal.length)
    const returnArr = []
    for (let i = 0; i < len; i++) {
      const defaultArrVal = defaultVal[i]
      const objArrVal = objVal[i]
      if (noVal(defaultArrVal)) {
        returnArr.push(objArrVal)
      } else if (!noVal(objArrVal) && valWrongType(defaultArrVal, objArrVal)) {
        const actualType = objArrVal.constructor.name
        const type = defaultArrVal.type && defaultArrVal.type.name
        console.warn(`Array index: ${i} should be [${type}], but is [${actualType}]`)
        returnArr.push(calculateValue(defaultArrVal.val))
      } else {
        returnArr.push(calculateValue(defaultArrVal.val, objArrVal))
      }
    }
    return returnArr
  }
  
  const constructObj = (defaultObj, obj = {}, returnObj) => {
    const objToReturn = Object.assign({}, returnObj)
    const { defaultKeys, extraKeys } = constructKeys(defaultObj, obj)
    defaultKeys.forEach(key => {
      const { defaultVal, objVal } = getVals(key, defaultObj, obj)
      if (noVal(objVal)) {
        objToReturn[key] = calculateValue(defaultVal.val)
      } else if (valWrongType(defaultVal, objVal)) {
        const actualType = objVal.constructor.name
        const type = defaultVal.type && defaultVal.type.name
        console.warn(`${key} should be [${type}], but is [${actualType}]`)
        objToReturn[key] = calculateValue(defaultVal.val)
      } else {
        objToReturn[key] = calculateValue(defaultVal.val, objVal)
      }
    })
    extraKeys.forEach(key => {
      const { objVal } = getVals(key, {}, obj)
      objToReturn[key] = objVal
    })
    return objToReturn
  }
  
  const ObjectWithDefault = (defaultObj, obj) => constructObj(defaultObj, obj, {})
  
  const MapToContext = (context, defaultObj, obj) => {
    // 1. Transform
    _transformToCustomType(context, defaultObj, obj)
    // 2. Fallback to Default if value is invalid/empty
    _populateWithDefault(context, defaultObj, obj)
  }
  
  const _transform = (CustomType, val) => {
    return new CustomType(val)
  }
  
  const _populateWithDefault = (context, defaultObj, obj) => {
    const newObj = ObjectWithDefault(defaultObj, obj)
    const { defaultKeys } = constructKeys(newObj, {})
    defaultKeys.forEach(key => {
      const { objVal } = getVals(key, defaultObj, newObj)
      context[key] = objVal
    })
  }
  
  const _transformToCustomType = (context, defaultObj, obj) => {
    if (!obj || !Object.keys(obj).length) {
      return
    }
    let missingKeys = []
    for (let key in defaultObj) {
      let {objVal} = getVals(key, defaultObj, obj)
      const type = defaultObj && defaultObj[key] && defaultObj[key].type
      if (!objVal) {
        missingKeys.push({n: key, t: type.name})
      }
      if (objVal && type && !isPredefinedType(type.name)) {
        obj[key] = _transform(type, objVal)
      }
    }
    if (missingKeys.length) {
     // console.warn(`${obj}'s shape is incorrect. Below are the missing keys:`)
    }
    missingKeys.forEach(k => {
     // console.warn(`missing ${k.n} of type ${k.t}`)
    })
  }
  
  const isPredefinedType = (type) => !type || type === 'Number' || type === 'String' || type === 'Boolean' || type === 'Object' || type === 'Array' || type === 'Set' || type === 'Map'
  
  export { MapToContext, ObjectWithDefault }
  
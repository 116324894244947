<template>
    <div>
        <vs-row vs-w="12" vs-justify="flex-start" vs-align="center">
            <vs-col vs-w="8" vs-justify="flex-start">
                <b-form inline class="search-bar-2 my-2">
                    <b-input-group left="@" class="mr-3">
                        <!-- <v-select :options="options1" label="text" v-model="selected1" placeholder="Inventory Number ID (All)" class="select-size-400"></v-select> -->
                        <sb-dropdown-search v-model="selected1" :options="options1" :minSearchTermLength="0" @search="handleSearch" :showSelectedOptions="true" :comparator = comparator :loading="false" class="dropdown-filter filter-inventory"  placeholder="Search by inventory name, or ShipBob ID..">
                            <sb-selected-dropdown-search-option slot="selection" slot-scope="{ selection }" class="selection-placeholder" :value="selection.text || 'Search by inventory name, or ShipBob ID..'"/>
                            <sb-dropdown-option slot-scope="{ option }">{{ option.value==0?option.text: option.value + " - " + option.text }}  </sb-dropdown-option>
                        </sb-dropdown-search>
                    </b-input-group>
                    <b-input-group left="@">
                        
                        <sb-dropdown :options="options2" v-model="selected2" class="dropdown-filter filter-short">
                            <sb-selected-option slot="selection" slot-scope="{ selected }">
                                {{ selected.text }}
                            </sb-selected-option>
                            <sb-options>
                                <sb-option slot-scope="{ option }" :value="option">
                                    {{ option.text }}
                                </sb-option>
                            </sb-options>
                        </sb-dropdown>
                    </b-input-group>
                    <b-input-group left="@" class="search-bar-btn">
                        <sb-button @click="update"  variation="primary"   size="small" @click.prevent="" >Apply</sb-button>                     
                    </b-input-group>
                </b-form>
            </vs-col> 
        </vs-row>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
         
        <div v-else>
            <vs-divider></vs-divider>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="12">
                    <header class="vs-card-header">
                        <title-component str="" dataName="dataUnitsSoldChannel"/>
                    </header>
                </vs-col>
            </vs-row>
           
            <vs-row >
                <vs-col vs-w="4" vs-justify="flex-start" >
                    <vs-row vs-align="flex-end">
                        <vs-col vs-w="12">
                            <indicator-subtitle :value="data.totalUnits" :colorClass="'blue2'" :subtitle="data.textTotalUnits"/>
                        </vs-col>
                    </vs-row>
                </vs-col>
                <vs-col vs-w="8" >
                    <div class="d-flex align-items-center">
                        <span class="horizontal-barchart-title">
                            By channel:
                           </span>  
                    </div>
                    <stacked-horizontal-bar-chart :labels="dataBarChart.labels" :data="dataBarChart.dataset" :options="dataBarChart.options"/>
                </vs-col>
            </vs-row>
            <vs-divider></vs-divider>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="12" class="px-3">
                    <title-component :str="data.textHistorical" dataName="dataUnitsSoldPerformance"/>
                </vs-col>
            </vs-row>
            <vs-row vs-w="12" vs-justify="center" vs-align="center" class="mt-5">
                <vs-col vs-w="12">
                    <line-chart :labels="dataLineChart.labels" :data="dataLineChart.dataset" :options="dataLineChart.options"/>
                </vs-col>
            </vs-row>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment';
import {cloneDeep } from 'lodash';

export default {    
    data() {
        return {
            loading: false,
            options1: [],
            items: [],
            options2: [
                { text: 'By import Date', value: 'Sold'},
                { text: 'By ship Date', value: 'Shipped'}
            ],
            selected1: { text: 'All Inventory', value: 0},
            selected2: { text: 'By import Date', value: 'Sold'},

            salesItems: [],
            data: {
                totalUnits: 0,
                textTotalUnits: 'Total Units Sold',
                textHistorical: 'Historical units sold',
                date: ''
            },
            comparator(a, b) {
              return a.id === b.id
            },
            dataBarChart:{
                dataset:[],
                labels: [],
                options:{
                    responsive: true, 
                    maintainAspectRatio: false, 
                    legend:{
                        position:'bottom',
                        labels: {
                            boxWidth: 12
                        }
                    },
                    tooltips: {
                        mode: 'point'
                    },
                    scales: {
                        yAxes: [{
                            stacked: true,
                            display: false,
                            maxBarThickness: 20,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                        xAxes: [{
                            stacked: true,
                            display: false,
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                color: "#ffffff",
                            }
                        }]
                    }
              
                    
                },
            },
            dataLineChart:{
                dataset: [],
                labels:[],
                options: {              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{
                        display: false,
                        position:'bottom'
                    },
                    scales: {
                        xAxes: [{
                            display: true
                        }],
                        yAxes: [{
                            gridLines: {
                                color: "#f6f6f6",
                            },
                            ticks: {
                                beginAtZero: true,
                                min: 0
                            }
                        }]
                    },
                    
                    
                },
            }
        }
    },
    mounted(){
        this.fetch();
        this.getSalesItems();
        this.$root.$on('update', this.fetch);
    },
    destroyed(){
        this.$root.$off('update', this.fetch);
    },
    methods: {
        handleSearch ({ searchTerm }) {
          if (searchTerm.length > 1) {
           searchTerm = searchTerm.toLowerCase();
            this.options1 = cloneDeep(this.items.filter(x => (x.text.toLowerCase().indexOf(searchTerm) !== -1) || (x.value.toString().indexOf(searchTerm) !== -1) ))
          }
          else this.options1 = this.items
        },
        dateFormat(date) {
            return moment(new Date(date)).format('MM/DD/YYYY');
        },
        getSalesItems() {
            this.options1 = [];
            let inventoryItemsConfig = { 
                IsActive:true,
                IsMerged:false,
                IsBundle:false
                };

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/', inventoryItemsConfig).then((response) => {
                this.options1.push({ text: 'All Inventory', value: 0})
                response.data.data.forEach((item) => {
                    this.options1.push({ text: item.name, value: item.inventoryId})
                });
                this.$store.commit('setSalesItems', response.data.data);
                this.items = this.options1;
            });

        },
        update() {
            this.fetch();
        },
        fetch() {
            this.loading = true;

            this.dataBarChart.dataset = [];
            this.dataLineChart.labels = [];
            this.dataLineChart.dataset = [];

            let config = {
                fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY')
            }

            // let config = {
            //     fromDate:'2019-01-01',
            //     toDate: '2019-01-25'
            // }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/sum/' + this.selected1.value, config).then((response) => {
                let data = response.data.data;

                this.data.totalUnits = data['totalUnits'+this.selected2.value];
                this.data.textTotalUnits = (this.selected2.value === 'Sold') ? 'Total units sold' : 'Total units shipped';
                this.data.textHistorical = (this.selected2.value === 'Sold') ? 'Historical units sold' : 'Historical units shipped';
                this.data.date = data.selectedDates;

                this.loading = false;
                this.$root.$emit('finishedFetch');
            });


            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/shipped/' + this.selected1.value, config).then((response) => {
                let dataShipped = response.data;
                this.createLineChart(dataShipped.data, this.selected2.value);

                this.loading = false;
                this.$store.commit('setDataUnitsSoldShipped', dataShipped.data);
                this.$root.$emit('finishedFetch');
            });

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/channel/' + this.selected1.value, config).then((response) => {
            // axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/channel/0', config).then((response) => {
                let dataChannel = response.data;
                this.createHorizontalBarChart(dataChannel.data, this.selected2.value);
                
                this.loading = false;
                this.$store.commit('setDataUnitsSoldChannel', dataChannel.data);
                this.$root.$emit('finishedFetch');

            });

            
        },
        createHorizontalBarChart (data, key) {

            data.forEach(item => {
                this.dataBarChart.dataset.push({
                    label: item.channel,
                    data: [item['totalUnits'+key]],
                    backgroundColor: [this.getColor(item.channel.toLowerCase())],
                    borderColor: 'transparent',
                });

            });

        },
        createLineChart(data, key){
            let qty = [];
            
            data.forEach((item, index) => {
                this.dataLineChart.labels.push(this.dateFormat(item.day));
                qty.push(item['units'+key]);
            });

            this.dataLineChart.dataset.push({
                label: '',
                backgroundColor: '#59BAEF',
                borderColor: '#59BAEF',
                pointStyle: 'line',
                fill:false,
                tension:0,
                data: qty
            });
        },
        getColor(key){
            let color = "";
            switch (key) {
                case 'amazon': color = '#C9C1C1'; break;
                case 'api': color = '#887E7E'; break;
                case 'backerkit': color = '#38D968'; break;
                case 'bigcommerce': color = '#59BAEF'; break;
                case 'ebay': color = '#509B63'; break;
                case 'excel': color = '#1F66A6'; break;
                case 'magento': color = '#59BAEF'; break;
                case 'manual': color = '#92EFAC'; break;
                case 'other': color = '#453F98'; break;
                case 'shipstation': color = '#232424'; break;
                case 'shopify': color = '#59BAEF'; break;
                case 'squarespacewarehouseorder': color = '#CAF269'; break;
                case 'stitchlabs': color = '#236C94'; break;
                case 'TicTail': color = '#232424'; break;
                case 'walmart': color = '#1A66FD'; break;
                case 'wooCommerce': color = '#59BAEF'; break;
                case 'zapier': color = '#232424'; break;
                default: color = '#888a8e';
            }
            return color;
        }
    }
}
</script>

 
 <style scoped lang='scss'> 

.dropdown-filter {
    margin-left: .5em; 
}
</style>
import axios from 'axios';
import authHelper from '@/utils/http/authToken'
import store  from '@/store'

axios.interceptors.request.use(function (config) {
    let vars = getUrlVars();

    if(vars.token !== undefined) {
        authHelper.persistAuth({accesstoken: vars.token,expiresin: 200,refreshtoken: ''})       
    }
    var extract = authHelper.extractAuth();
    if(extract.accesstoken){
        config.headers.Authorization = 'Bearer ' + extract.accesstoken;
    }
       
    return config;
}, function (error) {
    // Do something with request error
    if (error.response.status === 401) {
        //window.localStorage.removeItem('auth')
        store.dispatch('user/resetUser')
        store.dispatch('resetAuthentication')
        this.$router.push("Login"); 
    }
    return Promise.reject(error);
});

let axiosInstance = (method, uri, extraParams = {}, formData = new FormData(), responseType = null,timeoutRequest = null) => {
    let defaultHeaders = {}

    if(method == "GET"){
        return axios.get(uri, {
            // headers: {},
            headers: defaultHeaders,
            // headers: { 'Authorization': 'Bearer 1234' },
            params: extraParams,
            responseType:responseType,
            timeout: (timeoutRequest==undefined||timeoutRequest==null)?null: timeoutRequest
        });
    } else if(method == "POST") {
        return axios.post(uri, formData, {
            headers: defaultHeaders
        });
    } else if (method == "PUT") {
        return axios.put(uri, formData, {
            headers: defaultHeaders
            // headers: { 'Authorization': 'Bearer 1234' }
        });
    }
};

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

export default axiosInstance;
/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue';
import router from './router';
import Vuesax from 'vuesax';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMq from 'vue-mq'
import Vuetable from 'vuetable-2';
import vSelect from 'vue-select';
import bForm from 'bootstrap-vue/es/components/form/form';
import bInputGroup from 'bootstrap-vue/es/components/input-group/input-group';
import bFormGroup from 'bootstrap-vue/es/components/form-group/form-group';
import bFormInput from 'bootstrap-vue/es/components/form-input/form-input';
import bFormCheckboxGroup from 'bootstrap-vue/es/components/form-checkbox/form-checkbox-group';
import bFormCheckbox from 'bootstrap-vue/es/components/form-checkbox/form-checkbox';

import VueToast from 'vue-toast-notification';

// Import the Design System
import DesignSystem from 'shipbob-design-system';
import 'shipbob-design-system/dist/bootstrap/sb-bootstrap.css'
import 'shipbob-design-system/dist/system/system.css'
import('shipbob-design-system/dist/svgs/svgs.js')

//plugins
import CustomDirectives from './plugins/custom-directives-plugin'
import LayoutsPlugin from './plugins/layouts-plugin'

import Info from './components/shared/info';
import Sidebar from './components/shared/Sidebar';
import SidebarV2 from './components/shared/SidebarV2'; 
import CardFullWidth from './components/shared/CardFullWidth';
import CardHalfWidth from './components/shared/CardHalfWidth';  
import Title from './components/shared/Title';
import LineChart from './components/shared/LineChart';
import StackedBarChart from './components/shared/StackedBarChart';
import StackedHorizontalBarChart from './components/shared/StackedHorizontalBarChart';
import DoughnutChart from './components/shared/DoughnutChart';
import SearchBar from './components/shared/SearchBar';
import SearchBarSimple from './components/shared/SearchBarSimple';
import Accotation from './components/shared/Accotation';
import AccotationVertical from './components/shared/AccotationVertical'; 
import AccotationWithoutDoughnut from './components/shared/AccotationWithoutDoughnut';
import DaysInventorySearchBar from './components/shared/DaysInventorySearchBar';
import SkuVelocitySearchBar from './components/shared/SkuVelocitySearch';
import InventoryHistorySearchBar from './components/shared/InventoryHistorySearchBar';
import InventoryFCSearchBar from './components/shared/InventoryFCSearchBar';
import InventoryReconciliationSearchBar from './components/shared/InventoryReconciliationSearchBar';
import InventorySummarySearchBar from './components/shared/InventorySummarySearchBar';
import OrderTurnAroundTimeSearchBar from './components/shared/OrderTurnAroundTimeSearchBar';
import SalesSkuSearchBar from './components/shared/SalesSkuSearchBar';
import Datepicker from 'vuejs-datepicker';
import DateFilter from './components/shared/DateFilter';
import FromDateFilter from './components/shared/FromDateFilter';
import ToDateFilter from './components/shared/ToDateFilter';
import DateFilter7Days from './components/shared/DateFilter7Days';
import ComparisonFilter from './components/shared/ComparisonFilter';
import ComparisonFilterNoCaption from './components/shared/ComparisonFilterNoCaption';
import IndicatorWithSubtitle from './components/shared/IndicatorWithSubtitle';
import IndicatorWithSubtitleDate from './components/shared/IndicatorWithSubtitleDate';
import IndicatorWithSubtitleH2 from './components/shared/IndicatorWithSubtitleH2';
import IndicatorWithSubtitleH3 from './components/shared/IndicatorWithSubtitleH3';
import IndicatorWithSubtitleMinMax from './components/shared/IndicatorWithSubtitleMinMax';
import IndicatorWithSubtitleAndDelta from './components/shared/IndicatorWithSubtitleAndDelta';
import IndicatorWithTitle from './components/shared/IndicatorWithTitle';
import SupBreadcrumb from './components/shared/SupBreadcrumb';
import TableIndicators from './components/shared/TableIndicators';
import PopOver from './components/utils/PopOver'; 
import SellingStateItem from './components/shared/SellingStateItem';
import ShippingMethodItem from './components/shared/ShippingMethodItem';
import DownloadDataButton from './components/shared/DownloadDataButton';


import MasterSideNav from './components/layouts/MasterSideNav';
import AppSideNavTemplate from './components/layouts/AppSideNavTemplate';
import AppTemplate from './components/layouts/AppTemplate'; 
import ExceptionSummary from './components/shared/cardtemplates/ExceptionSummary';
import ExceptionOutOfStockDetails from './components/shared/cardtemplates/ExceptionOutOfStockDetails';

import KeyMetrics from './components/shared/cardtemplates/KeyMetrics';
import TransitTime from './components/shared/cardtemplates/TransitTime';
import TransitTimeV2 from './components/shared/cardtemplates/TransitTimeV2';

import OrderStatus from './components/shared/cardtemplates/OrderStatus';
import OrderTurnAroundTime from './components/shared/cardtemplates/OrderTurnAroundTime'
import TopSelling from './components/shared/cardtemplates/TopSelling';
import ShippedOrderMetrics from './components/shared/cardtemplates/ShippedOrderMetrics';
import OrderRevenue from './components/shared/cardtemplates/OrderRevenue';
import Exceptions from './components/shared/cardtemplates/Exceptions'; 
import AgeOrdersV2 from './components/shared/cardtemplates/AgeOrdersV2';
import OrdersAnomalies from './components/shared/cardtemplates/OrdersAnomalies';

import CompletedOrders from './components/shared/cardtemplates/CompletedOrders';
import ShippingMethods from './components/shared/cardtemplates/ShippingMethods';
import OrderAmountShippingMethod from './components/shared/cardtemplates/OrderAmountShippingMethod';

import SalesSku from './components/shared/cardtemplates/SalesSku';
import UnitsSold from './components/shared/cardtemplates/UnitsSold';
import SkuVelocity from './components/shared/cardtemplates/SkuVelocity';
import DaysInventory from './components/shared/cardtemplates/DaysInventory';
import InventorySummary from './components/shared/cardtemplates/InventorySummary';

import InventoryDistributionMap from './components/shared/cardtemplates/InventoryDistributionMap';
import CurrentDistribution from './components/shared/cardtemplates/CurrentDistribution';
import IdealDistribution from './components/shared/cardtemplates/IdealDistribution';

import InventoryHistoryByItem from './components/shared/cardtemplates/InventoryHistoryByItem'; 
import InventoryReconciliation from './components/shared/cardtemplates/InventoryReconciliation';

import FrequentlyPurchased from './components/shared/cardtemplates/FrequentlyPurchased';
import CostGoodsSold from './components/shared/cardtemplates/CostGoodsSold'

import TotalMonthlyCosts from './components/shared/cardtemplates/TotalMonthlyCosts';
import CurrentMonthDate from './components/shared/cardtemplates/CurrentMonthDate';
import BillingBreakDown from './components/shared/cardtemplates/BillingBreakDown';

import StateDetails from './components/shared/cardtemplates/StateDetails';
import Storage from './components/shared/cardtemplates/Storage';
import AvgFulfillment from './components/shared/cardtemplates/AvgFulfillment';
import StorageCost from './components/shared/cardtemplates/StorageCost';
import UnitsSoldShippedPerformance from './components/shared/cardtemplates/UnitsSoldShippedPerformance';
import UnitsSoldShippedChannel from './components/shared/cardtemplates/UnitsSoldShippedChannel';
import SkuVelocityDaysInventory from './components/shared/cardtemplates/SkuVelocityDaysInventory';
import InventoryDistributionPowerBI from './components/shared/cardtemplates/InventoryDistributionPowerBI';

import CustomVSSidebarGroup from './components/shared/CustomVSSidebarGroup';

import JsonExcel from 'vue-json-excel'

//  import 'bootstrap/dist/css/bootstrap.css'
//  import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vuesax/dist/vuesax.css'
import 'material-icons/iconfont/material-icons.css';
import 'vue-toast-notification/dist/index.css';
//import './styles/shipbob.scss'
import './styles/shipbobv2.scss'
import store from './store'

Vue.config.productionTip = false

Vue.use(CustomDirectives)
Vue.use(LayoutsPlugin)
Vue.use(Vuesax)
Vue.use(Vuetable)
Vue.use(BootstrapVue);
Vue.use(VueToast);
Vue.use(VueMq, {
  breakpoints: { // set breakpoints
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1366,
    xxl: Infinity
  }
});
Vue.use(DesignSystem)

Vue.use(VueAxios, axios)
Vue.component('info', Info)
Vue.component('sidebar', Sidebar)
Vue.component('sidebarv2', SidebarV2)
Vue.component('line-chart', LineChart)
Vue.component('stacked-bar-chart', StackedBarChart);
Vue.component('stacked-horizontal-bar-chart', StackedHorizontalBarChart);
Vue.component('doughnut-chart', DoughnutChart);
Vue.component('datepicker', Datepicker)
Vue.component('search-bar', SearchBar);
Vue.component('search-bar-simple', SearchBarSimple);
Vue.component('accotation', Accotation);
Vue.component('accotation-vertical', AccotationVertical);
Vue.component('accotation-without-doughnut', AccotationWithoutDoughnut);
Vue.component('days-inventory-search-bar', DaysInventorySearchBar);
Vue.component('inventory-history-search-bar', InventoryHistorySearchBar);
Vue.component('inventory-fc-search-bar', InventoryFCSearchBar);
Vue.component('inventory-summary-search-bar', InventorySummarySearchBar);
Vue.component('inventory-reconciliation-search-bar', InventoryReconciliationSearchBar);
Vue.component('order-turn-around-time-search-bar', OrderTurnAroundTimeSearchBar);
Vue.component('sku-velocity-search-bar', SkuVelocitySearchBar);
Vue.component('sales-sku-search-bar', SalesSkuSearchBar);
Vue.component('indicator-subtitle', IndicatorWithSubtitle)
Vue.component('indicator-subtitle-date', IndicatorWithSubtitleDate)
Vue.component('indicator-subtitle-h2', IndicatorWithSubtitleH2)
Vue.component('indicator-subtitle-h3', IndicatorWithSubtitleH3)
Vue.component('indicator-subtitle-min-max', IndicatorWithSubtitleMinMax)
Vue.component('indicator-subtitle-delta', IndicatorWithSubtitleAndDelta)
Vue.component('indicator-title', IndicatorWithTitle)
Vue.component('sup-breadcrumb', SupBreadcrumb)
Vue.component('table-indicators', TableIndicators)
Vue.component('selling-state-item', SellingStateItem)
Vue.component('date-filter', DateFilter);
Vue.component('from-date-filter', FromDateFilter);
Vue.component('to-date-filter', ToDateFilter);
Vue.component('date-filter-7-days', DateFilter7Days); 
Vue.component('card-full-width', CardFullWidth);
Vue.component('card-half-width', CardHalfWidth);
Vue.component('popover', PopOver); 
Vue.component('download-data-button', DownloadDataButton); 

Vue.component('shipping-method-item', ShippingMethodItem);


Vue.component('mastersidenav-template', MasterSideNav);
Vue.component('appside-template', AppSideNavTemplate);
Vue.component('app-template', AppTemplate);
Vue.component('exception-summary', ExceptionSummary);
Vue.component('exception-outofstock', ExceptionOutOfStockDetails);

Vue.component('key-metrics', KeyMetrics);
Vue.component('transit-time', TransitTime);
Vue.component('transit-time-v2', TransitTimeV2);

Vue.component('order-status', OrderStatus);
Vue.component('order-turn-around-time', OrderTurnAroundTime);
Vue.component('top-selling', TopSelling);
Vue.component('shipped-order-metrics', ShippedOrderMetrics);
Vue.component('order-revenue', OrderRevenue);
Vue.component('exceptions', Exceptions); 
Vue.component('age-ordersv2', AgeOrdersV2);
Vue.component('orders-anomalies', OrdersAnomalies);

Vue.component('completed-orders', CompletedOrders);
Vue.component('shipping-methods', ShippingMethods);
Vue.component('order-amount-shipping-method', OrderAmountShippingMethod);

Vue.component('sales-sku', SalesSku);
Vue.component('units-sold', UnitsSold);
Vue.component('sku-velocity', SkuVelocity);
Vue.component('days-inventory', DaysInventory);
Vue.component('inventory-summary', InventorySummary);

Vue.component('inventory-distribution-map', InventoryDistributionMap);
Vue.component('current-distribution', CurrentDistribution);
Vue.component('ideal-distribution', IdealDistribution);
Vue.component('state-details', StateDetails);
Vue.component('storage', Storage);
Vue.component('avg-fulfillment', AvgFulfillment);
Vue.component('storage-cost', StorageCost);
Vue.component('units-sold-shipped-performance', UnitsSoldShippedPerformance);
Vue.component('units-sold-shipped-channel', UnitsSoldShippedChannel);
Vue.component('sku-velocity-days-inventory', SkuVelocityDaysInventory);
Vue.component('inventory-distribution-powerbi', InventoryDistributionPowerBI);

Vue.component('title-component', Title);
Vue.component('comparison-filter', ComparisonFilter);
Vue.component('comparison-filter-no-caption', ComparisonFilterNoCaption);
Vue.component('b-form', bForm)
Vue.component('b-input-group', bInputGroup)
Vue.component('b-form-group', bFormGroup);
Vue.component('b-form-input', bFormInput);
Vue.component('b-form-checkbox-group', bFormCheckboxGroup);
Vue.component('b-form-checkbox', bFormCheckbox);

Vue.component('inventory-history-item', InventoryHistoryByItem); 
Vue.component('inventory-reconciliation', InventoryReconciliation);

Vue.component('frequently-purchased', FrequentlyPurchased);
Vue.component('cost-goods-sold', CostGoodsSold)

Vue.component('total-monthly-costs', TotalMonthlyCosts);
Vue.component('billing-break-down', BillingBreakDown);
Vue.component('current-month-date', CurrentMonthDate);

Vue.component('custom-vs-sidebar-group', CustomVSSidebarGroup);

Vue.component('v-select', vSelect);

Vue.component('download-excel', JsonExcel)

new Vue({
  router,
  store,
  render: h => h(App),

  data: {
    isSidebarActive: false
  },
  mounted(){
	  
  }
}).$mount('#app')
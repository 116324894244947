<template>
    <div>
        <vs-row vs-w="12" vs-justify="flex-start">
            <vs-col vs-w="12">
                <sales-sku-search-bar :value="0" :items="salesItems"></sales-sku-search-bar>
            </vs-col>
        </vs-row>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="12">
                    <indicator-subtitle-delta :value="data.salesItems" :colorClass="'blue2'" subtitle="Units Sold" :delta="data.salesItemsDelta" :showSimbol="false"/>
                </vs-col>
            </vs-row>
            <vs-divider></vs-divider>
            <vs-row vs-w="12" vs-justify="center" vs-align="center" style="height: 345px">
                <line-chart :labels="data.salesSkuLabels" :data="data.salesSkuDataset" :options="data.salesSkuOptions"/>
            </vs-row>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {
    data(){
        return {
            salesItems: [],
            data: {
                salesSkuDataset: [],
                salesSkuLabels:[],
                salesItems:0,
                salesItemsDelta:0,
                salesSkuOptions: {              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{position:'bottom'},
                    scales: {
                        xAxes: [{
                            display: false
                        }],
                        yAxes: [{
                            gridLines: {
                                color: "#f6f6f6",
                            }
                        }]
                    }
                },
            },
            loading: false
        }
    },
    mounted() {
        this.fetch();
        this.$root.$on('update', this.fetch);
        this.$root.$on('updateSalesSku', this.fetch);

        let inventoryItemsConfig = {
            IsActive:true,
                IsMerged:false,
                IsBundle:false
        };

        if(this.$store.state.salesItems.length === 0){
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/', inventoryItemsConfig).then((response) => {
                response.data.data.forEach((item) => {
                    this.salesItems.push({ text: item.name, value: item.inventoryId})
                });
                this.$store.commit('setSalesItems', response.data.data);
            });
        } else {
            let salesItems = this.$store.state.salesItems;
            salesItems.forEach((item) => {
                this.salesItems.push({ text: item.name, value: item.inventoryId})
            });
        }  
    },
    methods: {
        fetch() {
            if(this.$store.state.sales_itemID !== undefined) {
                this.loading = true;
                this.data.salesSkuDataset = [];
                this.data.salesSkuLabels = [];

                let salesSkuConfig = {
                    fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
                    toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
                    includeB2B:this.$store.state.includeB2BSales,
                    compare: this.$store.state.comparison
                }

           
                axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/' + this.$store.state.sales_itemID, salesSkuConfig).then((response) => {
                    let InventorySales = response.data;
    
                    let qty = [];
                    let qty2 = [];
    
                    this.data.salesItems = InventorySales.unitSold;
                    this.data.salesItemsDelta = InventorySales.delta;
                    InventorySales.histories.forEach((item, index) => {
                        this.data.salesSkuLabels.push(item.date);
                        qty.push(item.unitSold);
                        qty2.push(item.previousUnitSold);
                    });
    
                    this.data.salesSkuDataset.push({
                        label: 'Current',
                        backgroundColor: '#59BAEF',
                        borderColor: '#59BAEF',
                        fill:false,
                        tension:0,
                        data: qty
                    });
    
                    this.data.salesSkuDataset.push({
                        label: 'Previous',
                        backgroundColor: '#1f66a6',
                        borderColor: '#1f66a6',
                        fill:false,
                        tension:0,
                        data: qty2
                    });
    
                    this.loading = false;
                    this.$store.commit('setDataSalesBySku', InventorySales.histories);
                    this.$root.$emit('finishedFetch');
    
                });
            }
        }
    }
}
</script>

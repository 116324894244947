<template>
    <div  class="date-filter">  
            
                        <datepicker class="vs-input input-date-sm" v-model="from" :format="customFormatter" @closed="setFrom"></datepicker>
                    
    </div>
</template>
<script>
import moment from 'moment';

export default {
    data() {
        return {
            from: '',
            to: '',
            isMobile: window.innerWidth <= 835,
            highlighted:{}
        }
    },
    methods:{
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        setFrom(){
            this.$store.commit("setFrom", this.customFormatter(new Date(this.from)));
            
            this.highlighted = {
                from: new Date(this.$store.state.from) 
            };
        }  
    },
    mounted(){
        let date = new Date();  
        this.from = this.customFormatter(date.setDate(date.getDate() - 7));

        this.$store.commit("setFrom", this.from); 

        this.highlighted = {
            from: new Date(this.$store.state.from) 
        };
    }
}
</script>


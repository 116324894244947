<template>
  <div class="main-content">
      
    <div class="header-container">
      <base-header :heading="'Explore Inventory Distribution'"
                 :description="''"
                 :paragraph1="''" 
                 slot="header"
                 >  
                 <div slot="action">
              
            </div>
      </base-header>
     
    </div>


    <div class="dashboard-container">
     
     <card-full-width
        :title="'Current Distribution'"
        :kindTemplate="'inventory-distribution-powerbi'"
        :paragraph="''"
        :disableFooter="'true'"
        :disableHeader="'true'"
       v-if="powerBIEmbeded"/>
      
      
      <card-half-width
        :title="'Current Distribution'"
        :kindTemplate="'current-distribution'"
        :paragraph="'This data excludes B2B orders and internal and external transit orders.'"
        :disableFooter="'true'"
        :topRightText="'Last ' + this.curtDistNumOrder + ' Orders'"
       v-if="!powerBIEmbeded" />

      <card-half-width
        :title="'Ideal Distribution'"
        :kindTemplate="'ideal-distribution'"
        :paragraph="'You can reduce your shipping costs and average transit times by storing inventory closer to your customers. This analysis helps you estimate your costs and zones if you split your inventory across multiple facilities. NOTE: The lower the zone, the faster the delivery). There are considerations (besides shipping costs) when deciding if splitting inventory is the right choice for your business.'"
        :paragraph1="'-Transportation costs to split inventory.'"
        :paragraph2="'-Additional warehousing fees for storing product in multiple facilities'"
        :paragraph3="'-Number of SKUs and order combinations. The greater the number of SKUs you sell the more difficult it can get to manage inventory in multiple facilities. '"
        :tooltipPosition="'right'"
        :disableFooter="'true'"
      :topRightText="'Last ' + this.idealDistNumOrder + ' Orders'"
       v-if="!powerBIEmbeded" />

        <card-full-width
        :title="''"
        :kindTemplate="'inventory-distribution-map'" 
        :disableHeader="true"
        :topRightText="'(' + this.mapSelectedDates + ')'"
      />

    </div>
  </div>
</template>
<script> 
export default {
  components: {
    
  },
  data() {
    return { 
      mapSelectedDates:'' ,
      curtDistNumOrder:0,
      idealDistNumOrder:0,
      powerBIEmbeded:true
    };
  },
 async mounted(){  
    this.powerBIEmbeded = await this.getSettingIsEnabled;
		this.$root.$on('finishedsetMapSelectedDates', ()=>{ 
      this.mapSelectedDates = this.$store.state.mapSelectedDates;
    }); 
    this.$root.$on('finishedsetCurrentDistNumOrder', ()=>{ 
      this.curtDistNumOrder = this.$store.state.curtDistNumOrder;
    }); 
    this.$root.$on('finishedsetIdealDistNumOrder', ()=>{ 
      this.idealDistNumOrder = this.$store.state.idealDistNumOrder;
    }); 
  },
   destroyed: function () { 
     this.$root.$off('finishedsetMapSelectedDates', ()=>{ 
      this.mapSelectedDates = this.$store.state.mapSelectedDates;
    }); 
     this.$root.$off('finishedsetCurrentDistNumOrder', ()=>{ 
      this.curtDistNumOrder = this.$store.state.curtDistNumOrder;
    });
    this.$root.$off('finishedsetIdealDistNumOrder', ()=>{ 
      this.idealDistNumOrder = this.$store.state.idealDistNumOrder;
    });
    
   },
  computed: {  
    async getSettingIsEnabled() { 
      return await this.$store.dispatch("getSettingIsEnabled", {name:"PowerBIIdealDistributionEnabled"});
  }
	}
   
};
</script>
<style scoped lang='scss'>
@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss";
@import "@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss";
//@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard-panel-template.scss";
.main-content {
  background-color: #f2f3f5;
}
</style>
 
<template>
  <sb-button @click="exportSheet()" size="small" variation="tertiary">
    <span class="icon icon-download-cloud"></span>
    Download
  </sb-button>
</template>
<script>
import json2csv from "csvjson-json2csv"; 
export default {
  props: [ 
    "dataName",
    "data" 
  ],
  data() {
    return {
      receivedData: []
    };
  },
  mounted() {
    this.$root.$on("finishedFetch", () => { 
      if (this.dataName !== undefined) {  
        this.receivedData = this.$store.state[`${this.dataName}`];
      }else if (this.data !== undefined) { 
        console.log(this.data);
        this.receivedData = this.data; 
      }
      
    });
  },
  methods: {
    exportSheet: function() {
      let prefix  = "data:text/csv;charset=utf-8,";
      const fileName = "tabledata.csv";
      var csvContent  = json2csv(this.receivedData);      
      const data = prefix + encodeURIComponent(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", fileName);
      link.click();
    }
  }
};
</script> 
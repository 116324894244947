<template>
    <div>
        <div class="row">
            <div class="col-12 p-0">
                <b-form inline class="search-bar d-flex justify-content-start">            
                    <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                        <date-filter></date-filter>
                    </b-input-group>
                    <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0 search-bar-btn">
                        <sb-button @click="update"  variation="primary"   size="medium" @click.prevent="" >Apply</sb-button>                        
                    </b-input-group>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    props:['additionals', 'kind'],
    data() {
        return {
            items:[
				{text:'Previous period',value:'PreviousPeriod'},
				{text:'Previous year',value:'PreviousYear'},
            ],
            loading:false,
        }
    },
    mounted() {
        this.$root.$on('finishedFetch', () => { this.loading = false; });
    },
    methods:{        
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        update(){
            this.loading = true;
            this.$root.$emit('update');
        }
    }
}
</script>


<template>
    <div class="accotation-item">
        <vs-row vs-justify="center">
            <vs-col class="centered" vs-justify="center">
                <div :class="'doughnut ' + color" v-if="this.enabled"></div>
                <div class='doughnut disabled' v-else></div>

                <h4 class="accotation-title h6-Color">{{title}}</h4>
                <span v-if="showPercent==undefined"> {{percentage}} %</span>
                <span v-if="showPercent"> $ {{numberWithCommas(percentage)}}</span>
                <div class="form-group" v-if="requiredDropdown">
                    <!-- <select class="form-control select-acotation" label="Figuras" v-model="selectedItem"  @input="toggleAccotation" :active="isActive">
                        <option :key="index" :value="item.value" :vs-text="item.text" v-for="(item,index) in items">{{item.text}}</option>>
                    </select> -->
                     <div>
                        <input type="radio"  v-model="selectedItem" value="0"  :active="isActive"  @input="toggleAccotation" >
                        <label   class="radio"  @click.prevent>On</label>
                    </div>
                      <div  >
                        <input type="radio"  v-model="selectedItem" value="1" :active="isActive" @input="toggleAccotation" >
                        <label   class="radio" @click.prevent>Off</label>
                    </div>
                    <!-- <sb-radio-button v-model="selectedItem" value="0"  :active="isActive" @input="toggleAccotation" >On</sb-radio-button>
                    <sb-radio-button v-model="selectedItem" value="1" :active="isActive" @input="toggleAccotationOff" >Off</sb-radio-button> -->
                    <!-- <div v-else> -->
                        <!-- <p>&nbsp;</p> -->
                        <!-- <p>&nbsp;</p> -->
                    <!-- </div> -->
                </div>
                <!-- <vs-select class="accotation-select" label="Figuras" v-model="selectedItem" v-if="requiredDropdown" @input="toggleAccotation" :active="isActive">
                    <vs-select-item :key="index" :vs-value="item.value" :vs-text="item.text" v-for="(item,index) in items"/>
                </vs-select> -->
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['color', 'title', 'percentage', 'requiredDropdown', 'showPercent'],
    data() {
        return {
            isActive: false,
            enabled: true,
            selectedItem:"0",
            selectedItemOn:null,
            selectedItemOff:null,
            items: [{
                value:1,
                text: "OFF"
            },
            {
                value:0,
                text: "ON"
            }] 

        }
    },
    mounted(){
         
    },
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
         toggleAccotation() {
            this.isActive = false;
            
            if(this.selectedItem === 0) {
                this.enabled = false;
            } else {
                this.enabled = true;
            }

            let varName = "";


            if(this.title !== undefined){
                varName = 'setideal_' + this.title.toLowerCase();
            } else {
                varName = "default"
            }
           
            this.$store.commit(varName, this.selectedItem);

            this.$root.$emit("accotationChanged");
        }   
    } 
}
</script>
 
 <style scoped lang='scss'> 

.radio {
    margin-left: .5em; 
}
</style>
<style scoped lang='scss'>
 
 .h6{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
 }
  .h6-Color{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
    color: #b1b1b1;
 }

</style>
 

<template>
    <div  >
        <div class="fulfillment-metrics-header">
             <h4>Today's B2C Order Snapshot  <info style="margin-left:5px;" :paragraph="'This data excludes B2B orders and internal and external transit orders'"></info></h4>
              <div class="float-right">
                   <a href="/fulfillment/sla-exceptions" class="link-more-details" >View More</a>
              </div>
        </div>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else class="fulfillment-metrics-container" >
            <vs-row class="mb-3"  vs-align="center">
                <vs-col vs-type="flex" vs-w="12"  vs-align="center">
                    <vs-row vs-justify="space-around"  vs-align="center">
                        <vs-col vs-justify="center" vs-w="5"  >
                            <div>
                                <vs-col vs-w="10"  >
                                    <vs-progress :percent="data.item.percent" :color="'rgb(36, 112, 175)'" :height="10">HEX</vs-progress>
                                    <p>{{data.item.subtitle}}</p>
                                </vs-col>
                                <vs-col vs-w="2"  class="primary"> 
                                    <h2 class="h2" style="text-align:center" ><span  > </span>{{(data.item.percent==0)||(data.item.percent==undefined)?"- ":data.item.percent}}%  <info  style="margin-left:0px;" :paragraph="'This % excludes orders received past cutoff, On Hold or in exceptions'"></info></h2> 
                                
                                </vs-col>
                            </div>
                        </vs-col>
                        <vs-col vs-justify="center" vs-w="1"   vs-align="center" >
                            <indicator-subtitle-h2 :value="data.shippedToday" :colorClass="'primary'" subtitle="Shipped Today"  />
                        </vs-col>
                        <vs-col vs-justify="center" vs-w="1"  vs-align="center">
                            <indicator-subtitle-h2 :value="data.fulfillment" :colorClass="'primary'" subtitle="Processing Today"  />
                        </vs-col>
                        <vs-col vs-justify="center" vs-w="1"  vs-align="center">
                            <indicator-subtitle-h2 :value="data.pastCutoffTime" :colorClass="'disabled'" subtitle="Received Past Cutoff Time"  />
                        </vs-col>
                        <vs-col vs-justify="center" vs-w="1"  vs-align="center">
                            <indicator-subtitle-h2 :value="data.onHold" :colorClass="'disabled'" subtitle="Total On Hold Orders"  />
                        </vs-col>
                        <vs-col vs-justify="center" vs-w="1"  vs-align="center">
                            <indicator-subtitle-h2 :value="data.exception" :colorClass="'disabled'" subtitle="Total Exception Orders"/>
                        </vs-col>
                    
                    </vs-row>
                </vs-col>
            </vs-row>
        </div>     
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {
    props:['title'],
    data() {
        return {
            data: {
                shippedToday:0,
                fulfillment: 0,
                pastCutoffTime: 0,
                onHold: 0,
                exception: 0,
                item: {}
            },
            loading: false 
        }
    },
    mounted() {
        this.fetch();
        // this.$root.$on('update', this.fetch);
    },
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        fetch() {
            this.loading = true;
            let configShippedDate = new Date();
			let configShipped = {
					toDate: moment(configShippedDate.setDate(configShippedDate.getDate())).format('MM/DD/YYYY'),
					fromDate: moment(configShippedDate.setDate(configShippedDate.getDate() - 7)).format('MM/DD/YYYY'),
					compare: this.$store.state.comparison
				
			}
			axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/status', configShipped).then((response) => {
                    let graphicsData = response.data;
                    let resultGraphicsData = [];
                    
                    this.data.shippedToday = this.numberWithCommas(graphicsData.shippedToday);
                    this.data.fulfillment = this.numberWithCommas(graphicsData.fulfillment);
                    this.data.pastCutoffTime = this.numberWithCommas(graphicsData.pastCutoffTime);
                    this.data.onHold = this.numberWithCommas(graphicsData.onHold);
                    this.data.exception = this.numberWithCommas(graphicsData.exception);

                    this.data.item.subtitle = "Orders Fulfilled Today (%)";
                    this.data.item.percent = Math.floor(graphicsData.shippedToday/(graphicsData.shippedToday+graphicsData.fulfillment)*100);
                    this.data.item.percent = (Number.isNaN(this.data.item.percent)) ? 0 :  this.data.item.percent;


                    this.loading = false;    
                    this.$root.$emit('finishedFetch');
            });
        }
    }
}
</script>
<style lang="scss" scoped>
  @import '@/../assets/scss/shipbob-custom/components/dashboard/_panel-fulfillment-metrics.scss';
    .fulfillment-metrics-container
   {
        padding-bottom: 0px;
        padding-top: inherit;
   }
</style>
<template>
  <div class="container-fluid flex-full-height">
    <div class="flex-1 d-flex">
      <div id="hero-image-wrapper" class="row">
        <div class="col-12 flex-full-height px-75 py-50 setup-guide-hero">
          <div class="flex-full-height">
            <div class="flex-1 d-flex flex-column">
              <div class="row">
                <h1 class="hero-header bold font-weight-bold line-height-56">
                  Get your products in
                  <br />customers' hands faster.
                </h1>
                <h2
                  class="hero-header-2"
                >We store your items securely, then pick, pack and ship your orders on the same day.</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="setup-guide-login-wrapper" class="row">
        
        <div
          class="form-container col-12 flex-full-height justify-content-end px-20 px-l-40 px-xxl-100 py-30"
        >
          <div class="row flex-1 flex-column justify-content-center">
            <img
              :src="require('../assets/ShipBob-Logo-Color.png')"
              alt="shipbob-logo"
              id="shipbob-logo"
            />
            <h1>Welcome back!</h1>
            <h4>We can't wait for you to see what's new. Happy Shipping!</h4>
            <sb-form>
                <sb-field>
                  <sb-label>Email Address</sb-label>
                  <sb-text-input v-model="email" ></sb-text-input>
                 
                </sb-field>
                <div><br></div>
                <sb-field>
                  <sb-label>Password</sb-label>
                  <sb-text-input  type="password"  v-model="password"></sb-text-input>
                   
                </sb-field>
                <div><br></div>
                
                 <sb-button @click="login" variation="primary" size="medium" @click.prevent>Login</sb-button>
              </sb-form>

            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import toastr from "toastr";

export default {
  data() {
    return {
      initialLoad: true
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/getUser"];
    },
    imageWrapper() {
      return "hero-image-wrapper";
    },
    loginWrapper() {
      return "setup-guide-login-wrapper";
    },
    image() {
      return {
        "setup-guide-hero": true,
        "call-us-hero": false
      };
    },
    email: {
      get() {
        return this.user.email.value;
      },
      set(val) {
        this.$store.dispatch("user/setEmail", val);
      }
    },
    password: {
      get() {
        return this.user.password.value;
      },
      set(val) {
        this.$store.dispatch("user/setPassword", val);
      }
    }
  },
  methods: {
    auth() {
      this.$router.push("/dashboard");
    },
    setInitialLoad() {
      if (!this.initialLoad) return;
      this.initialLoad = false;
    },
    async login() {
      this.setInitialLoad();
      if (!this.user.isValid("login")) {
        toastr.error("Invalid/username or password...");
        return;
      }
      this.toggleLoadScreen();
      const success = await this.$store.dispatch(
        "user/login",
        this.user.mapForLogin()
      );
      if (success) {
        this.initialLoad = true;
        this.password = null;
        this.$router.push({ name: "Dashboard" });
      } else {
        this.toggleLoadScreen();
      }
    },
    toggleLoadScreen() {
      //oginLayout.toggleLoadScreen()
    }
  }
};
</script>
<style scoped lang='scss'>
.hero-header
{
color: white;
font-weight: 700;
}
.hero-header-2
{
color: white;  
font-weight: 500;
}
  // .h1{
  //   font-weight: 700 !important;
  //   line-height: 3.5rem;
  //   font-size: 2rem;
  //   margin-bottom: .5rem;
  //   font-family: inherit;
  //   margin-top: 0;
  //   color: inherit;
  // }
  // .h2{
  //   font-size: 1.75rem;
  //   margin-bottom: .5rem;
  //   font-family: inherit;
  //   font-weight: 500;
  //   line-height: 1.2;
  //   color: inherit;
  //   margin-top: 0;
  // }
</style>
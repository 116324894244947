<template>
    <vs-row vs-w="12" vs-justify="center" vs-align="center" class="shipping-method-itemv2 ">
        <vs-col vs-w="12" class="progressx">
            <div class="d-flex justify-content-between mt-2">
                <h6 class="h6">{{item.name}}</h6>
                <h6 class="right barely-visible mb-0 h6" vs-justify="flex-end" :color="item.color">{{item.percent}}% <span class="h6-Color">of {{item.totalShipped}} Orders</span></h6>
                
            </div>
                <vs-progress :percent="this.maxValue" :color="item.color" :height="9">HEX</vs-progress>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                 <vs-col vs-w="3">
                    <indicator-subtitle-min-max :min="item.minCartAmount" :max="item.maxCartAmount" subtitle="Cart Range" simbol="$"></indicator-subtitle-min-max>
                </vs-col>
                <vs-col vs-w="3">
                    <indicator-subtitle-h3 :value="item.avgCartAmount" subtitle="Avg Cart Amount" simbol="$"></indicator-subtitle-h3>
                </vs-col>
                <vs-col vs-w="3">
                    <indicator-subtitle-h3 :value="item.avgShippingCost" subtitle="Avg Shipping Cost" simbol="$"></indicator-subtitle-h3>
                </vs-col>
                <vs-col vs-w="3">
                    <indicator-subtitle-h3 :value="item.avgDaysTransit" subtitle="Avg Days in Transit"></indicator-subtitle-h3>
                </vs-col>
            </vs-row>
        </vs-col> 
    </vs-row>
</template>
<script>
export default {
    props:['item', 'max'],
    data(){
        return {
            maxValue:0
        }
    },
    mounted() {
        this.maxValue = this.item.percent; 
    }
}
</script>
<style scoped lang='scss'>
 
 .h6{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
 }
  .h6-Color{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
    color: #b1b1b1;
    font-weight: bold;
 }

</style>
 

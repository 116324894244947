<template>
    <div class="date-filter"> 
        
                      
                        <datepicker class="vs-input input-date-sm" v-model="to" :format="customFormatter" @closed="setTo" :highlighted="highlighted"></datepicker>
                      
    </div>
</template>
<script>
import moment from 'moment';

export default {
    data() {
        return {
            from: '',
            to: '',
            isMobile: window.innerWidth <= 835,
            highlighted:{}
        }
    },
    methods:{
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        }, 
        setTo() {
            this.$store.commit("setTo", this.customFormatter(new Date(this.to)));
            
            this.highlighted = { 
                to: new Date(this.$store.state.to)
            };
        }
    },
    mounted(){
        let date = new Date(); 
        this.to = this.customFormatter(date.setDate(date.getDate() -1));
     
 
        this.$store.commit("setTo", this.to);

        this.highlighted = { 
            to: new Date(this.$store.state.to)
        };
    }
}
</script>


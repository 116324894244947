export default {
    data () {
      return {
        sideNavType: 'Merchant',
        offset: 0
      }
    },
    mounted () {
     // this.$store.dispatch('detectBrowser')
    },
    computed: {
      showSplashScreen () {
        return false;// this.$shipBobStore.getters['showSplashScreen']
      },
      adminMargin () {
        const marginTop = `${this.offset}px`
        return { marginTop }
      },
      user () {
        return  false;//this.$shipBobStore.getters['user/getUser']
      },
      isAdmin () {
        return this.user.isAdmin
      }
    }
  }
  
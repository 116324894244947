  <template>
  <div class="main-content">
    <!-- <div class="header-container">
      <base-header :heading="'Merchant Health Report'" :description="''" slot="header"></base-header>
    </div> -->

    <div class="dashboard-container">  
      <div style="  width:100%; height:100%; ">
      <SbLoadingPromiseWrapper :loadingPromise="loadingPromise"  style="  width:100%; height:100%; ">
      <iframe :src="pdfUrl" type="application/pdf" style="  width:100%; height:100%; "/> 
      </SbLoadingPromiseWrapper>
      </div>
    </div>
  </div>
</template>
<script> 
import axiosInstance from '../helpers/ws';
import Vue from "vue"; 

export default {
  data() {
    return {
       loadingPromise: null,
       pdfUrl:process.env.VUE_APP_ENDPOINT + 'Report/health/pdf'
    };
  },
  mounted: function() {
      this.loadingPromise = this.fetch(1);
  },
  methods: {
     fetch: async function(p) {
      await new Promise(res => {

        axiosInstance( 
          'GET',
          process.env.VUE_APP_ENDPOINT + "Report/health/pdf", 
          null, 
          null,       
          'blob', // important,
          300000 //5min timeout request duration in miliseconds for long running reports.
       ) .catch(error=>{
           Vue.$toast.open({
                message: " Unable to generate report " + error ,
                type: "error",
                position: "top-right",
                duration: 10000
              });
          res(true);
        }).then((response) => {
          res(true);
        let file  = new Blob([response.data], { type: 'application/pdf' }); 
        var pdfblob = window.URL.createObjectURL(file );
        this.pdfUrl=pdfblob;
        this.$refs["iframePDF"].src = response.data ;
         
          

          // //------------------
          // let blob = new Blob([response.data]);
          // let link = document.createElement('a');
          // link.href = window.URL.createObjectURL(blob);  
          // link.download = 'asdf.pdf';    
          // link.click();
        });
      });
     }
  }
}
</script>
<style scoped lang='scss'>
@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss";
@import "@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss";
//@import '@/../assets/scss/shipbob-custom/components/dashboard/_dashboard-panel-template.scss';
.main-content {
  background-color: #f2f3f5;
}
.dashboard-container
{
  height: 100%;
  padding: 0%;
  
}
</style>
 
<template>
    <div> 
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else style="height: 510px">
            <div style="margin-top: 60px"></div>
            <selling-state-item :item="item" v-for="item in data.topSellingData" :key="item.DestinationState" :max="data.maxSellingStateValue"/>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {
    props:['title'],
    mounted() {
        this.fetch();
        this.getSelectedDates();
        this.$root.$on('update', this.update);
        this.$root.$on('update', this.getSelectedDates);
    },
    data() {
        return {
            selectedDates: '',
            data: {
                topSellingData: [],
                maxSellingStateValue: 0
            },
            loading: false
        }
    },
    methods:{
        	update(){
			this.fetch();
		},
        getSelectedDates(){
             let config = {
                fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY')
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/sum/0', config).then((response) => {
                let data = response.data.data;
                this.selectedDates= data.selectedDates;
            });
        },
        fetch() {
            this.loading = true;
            this.data.maxSellingStateValue = 0;
            this.data.topSellingData = [];

            let configDistribution = {
				fromDate:moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate:moment(new Date(this.$store.state.to)).format('MM/DD/YYYY')
				// fromDate: '2019-01-01',
				// toDate:'2019-01-01'
			}
			
			axiosInstance("GET", process.env.VUE_APP_ENDPOINT+ 'orders/region/distribution', configDistribution).then((response) => {
                this.data.topSellingData = response.data.data;
               // console.log(response);

				this.data.topSellingData.forEach((item) => {
					if(item.percentOfTotal > this.data.maxSellingStateValue){
						this.data.maxSellingStateValue = item.percentOfTotal;
					}
                });
                this.loading = false;
                this.$root.$emit('finishedFetch');
			});
        }
    } 
}
</script>

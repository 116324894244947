<template>
<div>
  <div class="fulfillment-metrics-header">
     <!-- <div class="title-wrapper"> -->
     <h4 class="title">B2C Fulfillment Metrics<info style="margin-left:5px;" :paragraph="'Get an overview of the % of orders fulfilled within SLAs and other key metrics. The time period defaults to the last 7 days. You can adjust it to any historical date, click Apply, and the data will update. '"></info></h4>
     <!-- </div> -->
     <order-turn-around-time-search-bar></order-turn-around-time-search-bar>
    <!-- <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
     
       
      <from-date-filter></from-date-filter>
     <span style="text-align: center;margin:5px;"> to </span>
       <to-date-filter></to-date-filter> 
      <sb-button @click="update" variation="primary" size="medium" @click.prevent>Apply</sb-button>
      
    </sb-toolbar> -->
  </div>
    <div v-if="this.loading" class="spin-center-ideal-distribution center">
        <div class="ld ld-ring ld-spin running"></div>
    </div>
    <template v-else>
      <div class="fulfillment-metrics-container"> 
      <vs-row vs-w="12"  vs-type="flex" vs-justify="space-around"  >
            <vs-col vs-w="4"  vs-type="flex" vs-justify="center" vs-align="center">
              <indicator-subtitle-h2
                :value="data.totalOrdersReceived"
                :colorClass="'primary'"
                subtitle="Orders Placed"
                :showSimbol="false" 
              />
            </vs-col>
            <vs-col vs-w="4"  vs-type="flex" vs-justify="center" vs-align="center">
              <indicator-subtitle-h2
                :value="data.totalOrdersShipped"
                :colorClass="'primary'"
                subtitle="Orders Fulfilled"
                :showSimbol="false" 
              />
            </vs-col>
            <vs-col vs-w="4"  vs-type="flex" vs-justify="center" vs-align="center">
              <indicator-subtitle-h2
                :value="data.fulfilledOnTime"
                :simbol="'%'"
                :tooltip="this.data.tooltip"
                subtitle="% of Orders Fulfilled On Time"
                :colorClass="'primary'"
                subtitleLink="fulfillment/sla-exceptions"
              />
            </vs-col>
          </vs-row> 
      </div>
      <div class="fulfillment-metrics-container">
          
          <vs-row vs-w="12" vs-type="flex" vs-justify="space-around">
            <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center"> 
              <indicator-subtitle-h2
                :value="'$' + data.totalShippingCost"
                subtitle="Fulfillment Cost"
                colorClass="blue1"
              />
            </vs-col>
            <vs-col vs-w="4"  vs-type="flex" vs-justify="center" vs-align="center">
              <indicator-subtitle-h2
                :value="'$' + data.averageShippingCost"
                subtitle="Avg Fulfillment Cost Per Order"
                colorClass="blue1"
                :delta="data.averageShippingCost"
              />
            </vs-col>
            <vs-col vs-w="4"   vs-type="flex" vs-justify="center" vs-align="center">
              <indicator-subtitle-h2
                :value="'$' + data.averageRevenue"
                subtitle="Avg Revenue Per Order"
                colorClass="blue1"
                :delta="data.averageDeltaRenueve"
                subtitleLink="accounting/break-down"
              />
            </vs-col>
          </vs-row>   
        </div>
    </template>
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";

export default {
  props: ["title"],
  data() {
    return {
      maxItems: 20,
      maxItemsAux: 20,
      receivedData: [],
      data: {
        fulfilledOnTime: 0,
        tooltip: "",
        totalOrdersReceived: 0,
        totalOrdersShipped: 0,
        totalShippingCost: 0,
        averageShippingCost: 0,
        averageShippingCost: 0,
        averageRevenue: 0,
        averageDeltaRenueve: 0
      },
      loading: false
    };
  },
  mounted() {
    this.$root.$on("finishedFetch", () => {
      this.loading = false;
    });
    this.fetch();
    this.$root.$on("updateOrderTurnAroundTime", this.fetch);
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    update() {
      this.loading = true;
      this.$root.$emit("updateOrderTurnAroundTime");
    },
    dateFormat(date) {
      return moment(new Date(date)).format("MM/DD/YYYY");
    },
    numberWithCommas(x) {
      return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    changeMaxItems() {
      if (this.maxItemsAux > 0) {
        this.maxItems = this.maxItemsAux;
      }
    },
    fetch() {
      this.loading = true;
      let configOrderDate = new Date();
      let configOrderTurnAround = {
        from: moment(new Date(this.$store.state.from)).format("MM/DD/YYYY"),
        to: moment(new Date(this.$store.state.to)).format("MM/DD/YYYY")
      };

      axiosInstance(
        "GET",
        process.env.VUE_APP_ENDPOINT + "Fulfillment/turnaroundtimessummary",
        configOrderTurnAround
      ).then(response => {
        let dataFulfillement = response.data.data;
        let totalFulfillment = 0;
        let totalOntime = 0;
        let totalLate = 0;
        dataFulfillement.forEach(item => {
          totalOntime += item.status === "OnTime" ? item.orders : 0;
          totalLate += item.status === "Late" ? item.orders : 0;
          totalFulfillment += item.orders;
        });
        this.data.tooltip = `Late: ${this.numberWithCommas(
          totalLate
        )}. On time: ${this.numberWithCommas(totalOntime)}`;
        this.data.fulfilledOnTime = (totalOntime / totalFulfillment) * 100;
        this.data.fulfilledOnTime = Number.isNaN(this.data.fulfilledOnTime)
          ? 0
          : this.data.fulfilledOnTime.toFixed(1);
      });

      let configShippingMethod = {
        fromDate: moment(new Date(this.$store.state.from)).format("MM/DD/YYYY"),
        toDate: moment(new Date(this.$store.state.to)).format("MM/DD/YYYY"),
        compare: this.$store.state.comparison
      };

      axiosInstance(
        "GET",
        process.env.VUE_APP_ENDPOINT + "orders/metrics",
        configShippingMethod
      ).then(response => {
        /** COMPLETED ORDERS CHART HEADER **/
        let OrdersMetrics = response.data;

        this.data.totalOrdersReceived = this.numberWithCommas(
          Math.round(OrdersMetrics.received.total.value)
        );
        this.data.totalOrdersShipped = this.numberWithCommas(
          Math.round(OrdersMetrics.shipped.total.value)
        );

        this.data.totalShippingCost = this.numberWithCommas(
          Math.round(OrdersMetrics.shippingCost.total.value)
        );
        this.data.averageShippingCost = this.numberWithCommas(
          OrdersMetrics.shippingCost.average.value.toFixed(1)
        );
        this.data.averageRevenue = this.numberWithCommas(
          OrdersMetrics.revenue.average.value.toFixed(1)
        );
        this.data.averageDeltaRenueve = OrdersMetrics.revenue.average.delta;

        this.loading = false;
        this.$root.$emit("finishedFetch");
      });
    }
  }
};
</script>
 <style scoped lang='scss'>
@import '@/../assets/scss/shipbob-custom/components/dashboard/_dashboard-panel-template.scss';
 @import '@/../assets/scss/shipbob-custom/components/dashboard/_panel-fulfillment-metrics.scss';
   
.customers-toolbar {
  padding-left: 0px;
}
.vdp-datepicker {
  max-width: 100px;
  padding-left: 10px;
  position: unset;
}
   .fulfillment-metrics-container
   {
       padding-bottom: 0px;
   }
</style>
<template>
    <div>
        <vs-row style="padding:10px">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <span class="di-legend">SELECT </span>
                <select class="form-control select-card" @change="changeComparisonType" label="Figuras" v-model="select1" placeholder="Search">
                    <option :key="index" :value=item.value :vs-text="item.text" v-for="(item,index) in items1">{{item.text}}</option>>
                </select>
                <!-- <vs-select @change="changeComparisonType" class="di_select" label="Figuras" v-model="select1" placeholder="Search">
                    <vs-select-item :key="index" :vs-value="item.value" :vs-text="item.text" v-for="(item,index) in items1" />
                </vs-select> -->
                <span class="di-legend"> BASED ON </span>
                <select class="form-control select-card" @change="changeComparisonTime" label="Figuras" v-model="select2" >
                    <option :key="index" :value=item.value :vs-text="item.text" v-for="(item,index) in items2">{{item.text}}</option>>
                </select>
                <!-- <vs-select @change="changeComparisonTime" class="di_select" label="Figuras" v-model="select2">
                    <vs-select-item :key="index" :vs-value="item.value" :vs-text="item.text" v-for="(item,index) in items2" />
                </vs-select> -->
                <vs-button color="primary" type="button" @click="update" class="ld-ext-right">Apply</vs-button>
            </vs-col>
        </vs-row>
        <vs-row v-if="this.select1 === 'in'">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <v-select multiple :options="items3" v-model="inItems" label="text" placeholder="Type to Search" v-if="!this.loading" style="width:100%; padding: 10px" @input="fetchInItems()"></v-select>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    props:['items1', 'items2', 'items3'],
    data() {
        return {
            select1: 'low',
            select2: '30',
            loading: false,
            inItems: [],
            search_criteria:''
        }
    },
    mounted(){
        this.$root.$on('finishedFetch', () => { this.loading = false; });
        this.$store.commit("setdi_select", this.select1)
        this.$store.commit("setdi_time", this.select2)
    },
    methods:{        
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        changeComparisonType(){
            this.$store.commit("setdi_select", this.select1)
        },
        changeComparisonTime(){
            // console.log(this.select2);
            this.$store.commit("setdi_time", this.select2)
        },
        update(){
            this.loading = true;
            this.$root.$emit('updateDaysInventory');
        },
        fetchInItems() {
            // console.log(this.inItems);
            let items = "";

            this.inItems.forEach((item) => {
                items += item.value + ","
            })

            items = items.substring(0, items.length - 1);

            this.$store.commit("setdi_in_items", items);
        }
    }
}
</script>

<template>
    <div>
        <b-form inline class="search-bar-2">
            <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                <date-filter></date-filter>
            </b-input-group>
        </b-form>
        <b-form inline class="search-bar-2">
             
             <sb-dropdown-search v-model="selected1" :options="options1" @input="changeItem1()" :minSearchTermLength="0" @search="handleSearch" :showSelectedOptions="true" :comparator = comparator :loading="false" class="select-size-400" placeholder="Search by inventory name, or ShipBob ID..">
              <sb-selected-dropdown-search-option slot="selection" slot-scope="{ selection }" class="selection-placeholder" :value="selection.text || 'Search by inventory name, or ShipBob ID..'"/>
              <sb-dropdown-option slot-scope="{ option }">{{ option.value==0?option.text: option.value + " - " + option.text }}  </sb-dropdown-option>

            </sb-dropdown-search>
                  
            <b-input-group left="@" class="ml-2">
                <sb-dropdown-search v-model="selected2" :options="options2" @input="changeItem2()" :minSearchTermLength="0" @search="handleSearch2" :showSelectedOptions="true" :comparator = comparator :loading="false" class="select-size-400" placeholder="Lot Number">
                <sb-selected-dropdown-search-option slot="selection" slot-scope="{ selection }" class="selection-placeholder" :value="selection.text || 'Lot Number'"/>
                <sb-dropdown-option slot-scope="{ option }">{{ option.value==0?option.text: option.value + " - " + option.text }}  </sb-dropdown-option>

                </sb-dropdown-search>
            </b-input-group>
            <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                <sb-button @click="update"  variation="primary"   size="medium" @click.prevent="" >Apply</sb-button>             
            </b-input-group>
        </b-form>
    </div>
</template>
<script>
import moment from 'moment';
import {cloneDeep } from 'lodash';

export default {
    props:['items', 'items2', 'additionals', 'kind', 'name1', 'name2'],
    data() {
        return { 
            selected1:0,
            selected2:0,
            loading:false,
            comparator(a, b) {
              return a.id === b.id
            },
            options1: [],
            options2: []
        }
    },
    mounted() {
        this.$root.$on('finishedFetch', () => { this.loading = false;});
        this.options1 = this.items;
        this.options2 = this.items2;
    },
    methods:{     
        changeItem1() {
            this.$store.commit('setihid_itemID', this.selected1.value);
            this.$root.$emit('changeIhidItem');
        },
        changeItem2() {
            this.$store.commit('setihid_lotid', this.selected2.value);
        },
        handleSearch ({ searchTerm }) {
          if (searchTerm.length > 1) {
            searchTerm = searchTerm.toLowerCase()
            this.options1 = cloneDeep(this.items.filter(x => (x.text.toLowerCase().indexOf(searchTerm) !== -1) || (x.value.toString().indexOf(searchTerm) !== -1) ))
          }
          else this.options1 = this.items
        },
        handleSearch2 ({ searchTerm }) {
          if (searchTerm.length > 1) {
            searchTerm = searchTerm.toLowerCase()
            this.options2 = cloneDeep(this.items2.filter(x => (x.text.toLowerCase().indexOf(searchTerm) !== -1) || (x.value.toString().indexOf(searchTerm) !== -1) ))
          }
          else this.options2 = this.items2
        },
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        update(){
            this.loading = true;
            this.$root.$emit('updateInventoryHistory');
        }  
    },
    watch:{
        selected1 : (val) => {

        }
    }
}
</script>


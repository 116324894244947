import { render, staticRenderFns } from "./IndicatorWithSubtitle.vue?vue&type=template&id=2c2a64da&"
import script from "./IndicatorWithSubtitle.vue?vue&type=script&lang=js&"
export * from "./IndicatorWithSubtitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "IndicatorWithSubtitle.vue"
export default component.exports
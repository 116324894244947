import { render, staticRenderFns } from "./Storage.vue?vue&type=template&id=6af285b5&scoped=true&"
var script = {}
import style0 from "./Storage.vue?vue&type=style&index=0&id=6af285b5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af285b5",
  null
  
)

component.options.__file = "Storage.vue"
export default component.exports
<template>
  <div id="container" v-bind:style="heightStyle"></div>
</template>
<script>
import * as client from "powerbi-client";
import Vue from "vue";

const pbi = window.powerbi;
const models = client.models;

export default {
  components: {},
  props: ["reportName"],
  data() {
    return {
     heightStyle:{},
      config: {
        type: "report",
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        embedUrl: undefined,
        id: undefined,
        pageName: "", //?
        visualName: "", //?
        pageView: "fitToWidth",
        permissions: models.Permissions.Read,
      },
    };
  },
  mounted() {
     this.$root.$on('pbiEmbed', ()=>{
       this.embed();
    });
    this.domCycle(this.setContentHeight); 
  },
  destroyed()
  {
    this.$root.$off('pbiEmbed', ()=>{ });
  },
  computed: {
    
  },
  methods: {
    async load() {
      //grab container
      const embedContainer = document.getElementById("container");

      //preload the container
      powerbi.bootstrap(embedContainer, {
        type: "report",
      });
    },
    async embed() {
      //get the rls embdeded token from server
      const embededReponse = await this.getEmbededReportConfig(this.reportName);
      this.config.id = this.reportId;
      this.config.accessToken = embededReponse.embedToken.token;
      this.config.embedUrl = embededReponse.embedReports[0].embedUrl;

      //now do the actual embeding.
      const report = pbi.embed(embedContainer, this.config);
      //handle events
      report.off("loaded");
      // report.on("loaded", (event) => 
      // //console.log("loaded")
      // );
      report.off("rendered");
      // report.on("rendered", (event) => 
      // //console.log("Rendered")
      // );
      // report.on("error", (event) => {
      //   //console.log(event.detail);
      //   report.off("error");
      // });
    },
    async getEmbededReportConfig(reportName) {
      return await this.$store.dispatch("getEmbededReportConfig", reportName);
    },
     setContentHeight()
   {
     const heightString = this.adminContentHeight();
     Vue.set(this.heightStyle, 'height', heightString); 
    this.load();
   },
    adminContentHeight()
    { 
      const height = `calc(100vh - ${this.calculateAdminPanelBounds()}px)`
      return  height;
    },
    calculateAdminPanelBounds () {
      const adminBarElement = document.getElementById("admin-bar"); 
      if(adminBarElement){
             return adminBarElement.clientHeight;
      }
      return 0;
    },
   domCycle (func) {
      this.$nextTick(() => {
        func()
      })
    }
  },
};
</script>
<style scoped lang='scss'>
@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss";
@import "@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss";
.main-content {
  background-color: #f2f3f5;
}

</style>
 
<template>
    <div>
        <vs-row vs-w="12" class="mb-3" vs-type="flex" vs-justify="space-between" vs-align="center">
            <vs-col vs-w="12">
                <b-form inline vs-justify="space-between">
                    <b-input-group left="@">
                        <!-- <v-select multiple :options="options1" label="text" v-model="selectOptions1" placeholder="All ShipBob services (ship options)" style="width: 400px;">
                        </v-select> -->
                        <sb-dropdown :options="options1" v-model="selectOptions1"  class="dropdown-filter filter-shipping-method-small" placeholder="All shipping options" style="width: 400px;">
                            <sb-selected-option slot="selection" slot-scope="{ selected }" >
                                {{selectOptions1.length }} Filters Selected
                            </sb-selected-option>
                            <sb-options>
                                <sb-option slot-scope="{ option }" :value="option">
                                    {{ option.text }}
                                </sb-option>
                            </sb-options>
                        </sb-dropdown>
                    </b-input-group>
                    <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                        <sb-button @click="update"  variation="primary"   size="small" @click.prevent="" >Apply</sb-button>                        
                    </b-input-group>
                </b-form>
            </vs-col>
        </vs-row>
        <vs-row vs-w="12" class="mb-3" vs-type="flex" vs-justify="space-between" vs-align="center">
            <vs-col vs-w="12">
                <SbDismissibleChip v-for="f of selectOptions1" v-on:close="removeSelectedOption(f)" class="SbDismissibleChip-wrapper">
                    {{f.text}}
                </SbDismissibleChip>
            </vs-col>
        </vs-row>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else style="">
             <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="12" vs-justify="center" vs-align="center">
                    <line-chart :labels="data.labels" :data="data.dataset" :options="data.options"/>
                </vs-col>
            </vs-row>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'
export default {
    data() {
        return {
            options1: [],
            selectOptions1: [],
            receivedData: [],
            data: {
                labels: [], 
                dataset: [],
                options: {              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    title: {
                        display: true,
                        text: 'Weekly - Avg fullfillment cost per order'
                    },
                    legend:{
                        display: true,
                        position:'bottom'
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            // scaleLabel: {
                            //     display: true
                            //     // ,
                            //     // labelString: 'Weeks'
                            // }
                        }],
                        yAxes: [{
                            gridLines: {
                                color: "#f6f6f6",
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Avg cost'
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                
                                let text = data.datasets[tooltipItem.datasetIndex].avgBillableWeight[tooltipItem.index];
                                text = text || '';
 
                                let textAvg = data.datasets[tooltipItem.datasetIndex].avgShippingCost[tooltipItem.index];
                                textAvg = textAvg || '';
                                
                                let textAvgDomCost = data.datasets[tooltipItem.datasetIndex].avgDomesticShippingCost[tooltipItem.index];
                                textAvgDomCost = textAvgDomCost || '';
                                
                                let textAvgIntlCost = data.datasets[tooltipItem.datasetIndex].avgIntlShippingCost[tooltipItem.index];
                                textAvgIntlCost = textAvgIntlCost || '';

                                let textAvgZone = data.datasets[tooltipItem.datasetIndex].avgDomesticZone[tooltipItem.index];
                                textAvgZone = textAvgZone || ''; 

                                 let textavgDomesticBillableWeight = data.datasets[tooltipItem.datasetIndex].avgDomesticBillableWeight[tooltipItem.index];
                                textavgDomesticBillableWeight = textavgDomesticBillableWeight || '';

                                let textavgIntlBillableWeight = data.datasets[tooltipItem.datasetIndex].avgIntlBillableWeight[tooltipItem.index];
                                textavgIntlBillableWeight = textavgIntlBillableWeight || ''; 

                                   let avgDomesticOrderCount = data.datasets[tooltipItem.datasetIndex].avgDomesticOrderCount[tooltipItem.index];
                                avgDomesticOrderCount = avgDomesticOrderCount || '';

                                let avgIntlOrderCount = data.datasets[tooltipItem.datasetIndex].avgIntlOrderCount[tooltipItem.index];
                                avgIntlOrderCount = avgIntlOrderCount || ''; 

                                 let avgOrderCount = data.datasets[tooltipItem.datasetIndex].avgOrderCount[tooltipItem.index];
                                avgOrderCount = avgOrderCount || ''; 

                                let textavgUnitCount = data.datasets[tooltipItem.datasetIndex].avgUnitCount[tooltipItem.index];
                                textavgUnitCount = textavgUnitCount || '';
                                
                                let textavgDomesticUnitCount = data.datasets[tooltipItem.datasetIndex].avgDomesticUnitCount[tooltipItem.index];
                                textavgDomesticUnitCount = textavgDomesticUnitCount || '';
                                
                                let textavgInternationalUnitCount = data.datasets[tooltipItem.datasetIndex].avgIntlUnitCount[tooltipItem.index];
                                textavgInternationalUnitCount = textavgInternationalUnitCount || '';
 

                                let label=[];
                                  
                                if (data.datasets[tooltipItem.datasetIndex].linetype=="domestic")
                                { 
                                    label.push('Domestic Fulfillment Cost Metrics');
                                    label.push(''); 
                                    label.push('Avg Fulfillment Cost: '+textAvgDomCost);
                                    label.push('Avg Billable Weight: '+textavgDomesticBillableWeight);
                                    label.push('Avg Zone: '+textAvgZone);
                                    label.push('Order Count: '+avgDomesticOrderCount);
                                    label.push('Avg Unit Count: '+textavgDomesticUnitCount);
                                }else if (data.datasets[tooltipItem.datasetIndex].linetype=="intl")
                                {
                                    label.push('International Fulfillment Cost Metrics');
                                    label.push(''); 
                                    label.push('Avg Fulfillment Cost: '+textAvgIntlCost);
                                    label.push('Avg Billable Weight: '+textavgIntlBillableWeight); 
                                    label.push('Order Count: '+avgIntlOrderCount); 
                                    label.push('Avg Unit Count: '+textavgInternationalUnitCount);
                                }else if (data.datasets[tooltipItem.datasetIndex].linetype=="avg")
                                {
                                    label.push('Avg Fulfillment Cost Metrics');
                                    label.push(''); 
                                    label.push('Avg Fulfillment Cost: '+textAvg);
                                    label.push('Avg Billable Weight: '+text); 
                                    label.push('Avg Domestic Zone: '+textAvgZone);
                                    label.push('Order Count: '+avgOrderCount);
                                    label.push('Avg Unit Count: '+textavgUnitCount);
                                }
                                 
                                return label;
                            }
                        }
                    }
                },
            },
            loading: false,
        }
    },
    mounted() {
        this.getSelectsOptions();
        this.fetch();
        // this.$root.$on('update', this.fetch);
    },
    methods: {
        removeSelectedOption(filter){ 
            const index = this.selectOptions1.findIndex(f => f.text == filter.text)
            this.selectOptions1.splice(index, 1) 
        },
        dateFormat(date) {
            return moment(new Date(date)).format('MM/DD/YYYY')
        },
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        update() {
            this.fetch();
            // this.getSelectsOptions();
        },
        getSelectsOptions () {
            this.options1 = [];
            let date = new Date();
            let toDate = date.setDate(date.getDate() -1);
            let fromDate = date.setDate(date.getDate() - 365);

            let storesShippingConfig = {
				fromDate: moment(fromDate).format('MM/DD/YYYY'),
				toDate: moment(toDate).format('MM/DD/YYYY'),
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'Lookup/shipoptions', storesShippingConfig).then((response) => {
                let data = response.data;

                data.data.forEach((item) => {
                    this.options1.push({ text: item.service, value: item.pricingGroup})
                });
            });
        },
        fetch() {
            this.loading = true;
            this.receivedData = [];
            this.data.labels = [];
            this.data.dataset = [];

            let str = '';
            if(this.selectOptions1.length > 0){
                this.selectOptions1.forEach((item, index) => {
                    str += item.value;
                    if(index < (this.selectOptions1.length -1)){
                        str += ',';
                    }
                });
            } else {
                str  = '';
            }

            let config = {
                // shiptOptionIdGroup: 3
                shiptOptionIdGroup: str
            }


            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'cost/avgcostperorder', config).then((response) => {
                /** CHART DATASETS **/
                let dataset = response.data;
                 
                this.receivedData = dataset.data;
                
                let qty = [];
                let qtyDomesticShippingCost = [];
                let qtyIntlShippingCost = [];
                let avgBillableWeight = []
                let avgShippingCost = []
                let avgDomesticZone = []
                let avgDomesticShippingCost = []
                let avgIntlShippingCost = []
                let avgDomesticBillableWeight = []
                let avgIntlBillableWeight = []
                let avgDomesticOrderCount = []
                let avgIntlOrderCount = []
                let avgOrderCount = []
                let avgUnitCount = []
                let avgDomesticUnitCount = []
                let avgIntlUnitCount = []

				dataset.data.forEach((item) => {
					this.data.labels.push(this.dateFormat(item.shippedDate));
                    avgBillableWeight.push(item.avgBillableWeight);
                    avgShippingCost.push(item.avgShippingCost);
                    avgDomesticShippingCost.push(item.avgDomesticShippingCost);
                    avgIntlShippingCost.push(item.avgInternationalShippingCost);
                    avgDomesticBillableWeight.push(item.avgDomesticBillableWeight);
                    avgIntlBillableWeight.push(item.avgInternationalBillableWeight);
                    avgDomesticZone.push(item.avgDomesticZone);                    
                    avgDomesticOrderCount.push(item.ordersCountDomestic); 
                    avgIntlOrderCount.push(item.ordersCountIntl); 
                    avgOrderCount.push(item.ordersCount); 
                    avgUnitCount.push(item.avgUnitCount);
                    avgDomesticUnitCount.push(item.avgDomesticUnitCount);
                    avgIntlUnitCount.push(item.avgInternationalUnitCount);
                    qty.push(item.avgShippingCost);
                    qtyDomesticShippingCost.push(item.avgDomesticShippingCost);
                    qtyIntlShippingCost.push(item.avgInternationalShippingCost);
				});

				this.data.dataset.push({
                    avgBillableWeight: avgBillableWeight,
                    avgShippingCost: avgShippingCost,
                    avgDomesticShippingCost: avgDomesticShippingCost,
                    avgIntlShippingCost: avgIntlShippingCost,
                    avgDomesticBillableWeight: avgDomesticBillableWeight,
                    avgIntlBillableWeight: avgIntlBillableWeight,
                    avgDomesticZone: avgDomesticZone,
                    avgDomesticOrderCount: avgDomesticOrderCount,
                    avgIntlOrderCount: avgIntlOrderCount,  
                    avgOrderCount: avgOrderCount,  
                    avgUnitCount: avgUnitCount,
                    avgDomesticUnitCount: avgDomesticUnitCount,
                    avgIntlUnitCount: avgIntlUnitCount,
					backgroundColor: '#2470AF',
					borderColor: '#2470AF',
					fill:false,
                    tension:0,
                    label: 'Avg Fulfillment Cost',
                    linetype:'avg',
					data: qty
                });
                
                this.data.dataset.push({
                    avgBillableWeight: avgBillableWeight,
                    avgShippingCost: avgShippingCost,
                    avgDomesticShippingCost: avgDomesticShippingCost,
                    avgIntlShippingCost: avgIntlShippingCost,
                    avgDomesticBillableWeight: avgDomesticBillableWeight,
                    avgIntlBillableWeight: avgIntlBillableWeight,
                    avgDomesticZone: avgDomesticZone,
                    avgDomesticOrderCount: avgDomesticOrderCount,
                    avgIntlOrderCount: avgIntlOrderCount,
                    avgOrderCount: avgOrderCount,  
                    avgUnitCount: avgUnitCount,
                    avgDomesticUnitCount: avgDomesticUnitCount,
                    avgIntlUnitCount: avgIntlUnitCount, 
                    backgroundColor: '#59BAEF',
					borderColor: '#59BAEF',
					fill:false,
                    tension:0,
                    label: 'Domestic Avg Fulfillment Cost',
                    linetype:'domestic',
					data: qtyDomesticShippingCost
                });
                
                 this.data.dataset.push({
                    avgBillableWeight: avgBillableWeight,
                    avgShippingCost: avgShippingCost,
                    avgDomesticShippingCost: avgDomesticShippingCost,
                    avgIntlShippingCost: avgIntlShippingCost,
                    avgDomesticBillableWeight: avgDomesticBillableWeight,
                    avgIntlBillableWeight: avgIntlBillableWeight,
                    avgDomesticZone: avgDomesticZone,
                    avgDomesticOrderCount: avgDomesticOrderCount,
                    avgIntlOrderCount: avgIntlOrderCount,
                    avgOrderCount: avgOrderCount, 
                    avgUnitCount: avgUnitCount,
                    avgDomesticUnitCount: avgDomesticUnitCount,
                    avgIntlUnitCount: avgIntlUnitCount,
					backgroundColor: '#92EFAC',
                    borderColor: '#92EFAC',
					fill:false,
                    tension:0,
                    label: 'International Avg Fulfillment Cost',
                    linetype:'intl',
					data: qtyIntlShippingCost
				});

                this.loading = false;
                this.$store.commit('setAvgfulfillmentCostPerOrder', this.receivedData);
                this.$root.$emit('finishedFetch');                
			});

        }
    }
}
</script>
import { render, staticRenderFns } from "./InventoryDistributionPowerBI.vue?vue&type=template&id=1619b24b&scoped=true&"
import script from "./InventoryDistributionPowerBI.vue?vue&type=script&lang=js&"
export * from "./InventoryDistributionPowerBI.vue?vue&type=script&lang=js&"
import style0 from "./InventoryDistributionPowerBI.vue?vue&type=style&index=0&id=1619b24b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1619b24b",
  null
  
)

component.options.__file = "InventoryDistributionPowerBI.vue"
export default component.exports
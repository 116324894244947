<template>
  <div>
    <sb-table
      :columnDefinitions="columnDefinitions"
      :rows="tableHelper.pagedData"
      :loadingPromise="loadingPromise"
      @sort="handleSort"
      :defaultSortColumn="defaultSortColumn"
      :defaultSortOrdering="defaultSortOrdering"
    >
      <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
        <h3 class="title">Billing Breakdown <info style="margin-left:5px;" 
        :paragraph="'This chart provides an itemized record of charges for each month.'"
        :paragraph1="'Pick fees include any additional picks above your pre-determined maximum thresholds.'"
        :paragraph2="'Freight-Prepaid-B2B includes any charges for processing pre-paid orders and/or preparing or shipping B2B orders.'"
        :paragraph3="'Storage fees are recorded in the month they were processed (e.g.,June storage charges are processed at the start of July, so storage charges in July reflect the storage fees incurred in June).'"
        ></info></h3>
        <div class="search-wrapper"></div>
            <download-data-button :dataName="'dataBillingBreakDown'"   ></download-data-button>

      
      </sb-toolbar>
      <sb-table-row slot="sb-table-row" slot-scope="{row}">
        <sb-table-cell>{{row.month}}</sb-table-cell>
        <sb-table-cell>$ {{row.fulfillment}}</sb-table-cell>
        <sb-table-cell>$ {{row.pickFees}}</sb-table-cell>
        <sb-table-cell>$ {{row.storage}}</sb-table-cell>
        <sb-table-cell>$ {{row.receiving}}</sb-table-cell>
        <sb-table-cell>$ {{row.returns}}</sb-table-cell>
        <sb-table-cell>$ {{row.freightPrepaidB2B}}</sb-table-cell>
        <sb-table-cell>$ {{row.serialScan}}</sb-table-cell>
        <sb-table-cell>$ {{row.kitting}}</sb-table-cell>
        <sb-table-cell>$ {{row.other}}</sb-table-cell>
        <sb-table-cell>$ {{row.total}}</sb-table-cell>
        <sb-table-cell>$ {{row.credits}}</sb-table-cell>
        <sb-table-cell>$ {{row.netTotal}}</sb-table-cell>
      </sb-table-row>
      <sb-table-empty-state
        slot="sb-table-empty-state"
        icon-name="products"
        label="No Storage Invoices"
      >
        <p>Once you are invoiced for storage you will see the summary here.</p>
      </sb-table-empty-state>
      <pagination-wrapper
        slot="pagination"
        @fetchPage="handleFetchPage"
        :pageSize="tableHelper.pagination.pageSize"
        :currentPage="tableHelper.pagination.currentPage"
        :totalPages="tableHelper.pagination.totalPages"
        :totalRecords="tableHelper.pagination.totalRecords"
        :visibleRecords="tableHelper.pagination.visibleRecords"
      ></pagination-wrapper>
    </sb-table> 
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
  components: {
    "pagination-wrapper": PaginationWrapper
  },
  data() {
    return {  
      loadingPromise: null,
      columnDefinitions: [
        { name: "Month", field: "month", sortable: true },
        { name: "Fulfillment", field: "fulfillment", sortable: true },
        { name: "Pick Fees", field: "pickFees", sortable: true },
        { name: "Storage", field: "storage", sortable: true },
        { name: "Receiving", field: "receiving", sortable: true },
        { name: "Returns", field: "returns", sortable: true },
        {
          name: "Freight-Prepaid-B2B",
          field: "freightPrepaidB2B",
          sortable: true
        },
        { name: "Serial Scan", field: "serialScan", sortable: true },
        { name: "Kitting", field: "kitting", sortable: true },
        { name: "Other", field: "other", sortable: true },
        { name: "Total", field: "total", sortable: true },
        { name: "Credits", field: "credits", sortable: true },
        { name: "Net Total", field: "netTotal", sortable: true }
      ],
      tableHelper: new tabler(this, 12)   
    };
  },
  mounted() {
     this.loadingPromise = this.fetch(1);
  },
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },

  methods: {
    numberWithCommas(x) {
      return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    dateFormat(date) {
      return moment(new Date(date)).format("MM/DD/YYYY");
    }, 
    handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    },
    map(item) {
      return {
        month: this.dateFormat(item.month),
        fulfillment: this.numberWithCommas(Math.round(item.fulfillment)),
        pickFees: this.numberWithCommas(Math.round(item.pickFees)),
        storage: this.numberWithCommas(Math.round(item.storage)),
        receiving: this.numberWithCommas(Math.round(item.receiving)),
        returns: this.numberWithCommas(Math.round(item.returns)),
        freightPrepaidB2B: this.numberWithCommas(
          Math.round(item.freightPrepaidB2B)
        ),
        serialScan: this.numberWithCommas(Math.round(item.serialScan)),
        kitting: this.numberWithCommas(Math.round(item.kitting)),
        other: this.numberWithCommas(Math.round(item.other)),
        total: this.numberWithCommas(Math.round(item.total)),
        credits: this.numberWithCommas(Math.round(item.credits)),
        netTotal: this.numberWithCommas(Math.round(item.netTotal))
      };
    },
    fetch: async function(p) {
      await new Promise(res => { 
        this.tableHelper.preLoadReset();

        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "Cost/BreakDown"
        ).then(response => {
          let BillingBreakDown = response.data.data;
         
          this.tableHelper.loadData(BillingBreakDown, p); 
          this.$store.commit("setDataBillingBreakDown", this.tableHelper.receivedData);
          this.$root.$emit("finishedFetchDataBillingBreakDown");
          this.$root.$emit("finishedFetch");

          res(true);
        }); 
      });
    }
  }
};
</script>

<template>
    <div>
        <b-form inline class="search-bar-2">
            <b-input-group left="@" class="my-2 mr-sm-2 mb-sm-0">
                <date-filter></date-filter>
            </b-input-group>
        </b-form>
        <b-form inline class="search-bar-2 my-2">
           <div style="padding: 2em; background-color: #f9fafb;">
 
           
            <sb-dropdown-search v-model="selected1" :options="options1" @input="changeItem1()" :minSearchTermLength="0" @search="handleSearch" :showSelectedOptions="true" :comparator = comparator :loading="false" class="select-size-400" placeholder="Search by inventory name, or ShipBob ID..">
              <sb-selected-dropdown-search-option slot="selection" slot-scope="{ selection }" class="selection-placeholder" :value="selection.text || 'Search by inventory name, or ShipBob ID..'"/>
              <sb-dropdown-option slot-scope="{ option }">{{ option.value==0?option.text: option.value + " - " + option.text }}  </sb-dropdown-option>
            </sb-dropdown-search>
         
          </div>
        
        <div style="padding: 2em; background-color: #f9fafb;">
         
          <sb-dropdown 
              :options="items2"
              v-model="selected2"
              @input="changeItem2()"
              class="select-size-200"
              placeholder="Select a fulfillment center.." 
            >
              <sb-selected-option
                slot="selection"
                slot-scope="{ selected }"
              >
                {{ selected.text }}
              </sb-selected-option>
              <sb-options>
                <sb-option
                  slot-scope="{ option }"
                  :value="option"
                >
                 {{ option.text }}
                </sb-option>
              </sb-options>
            </sb-dropdown>
            </div>
            <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                <sb-button @click="update"  variation="primary"   size="medium" @click.prevent="" >Apply</sb-button>                
            </b-input-group>
        </b-form>
    </div>
</template>
<script>
import moment from 'moment';
import {cloneDeep } from 'lodash'

export default {
    props:['items', 'items2', 'additionals', 'kind', 'name1', 'name2'],
    data() {
         return {
            selected1:{},
            selected2:0,
            loading:false,
            comparator(a, b) {
              return a.id === b.id
            },
            options1: []
        }
    },
    mounted() {
         this.$root.$on('finishedFetch', () => { this.loading = false; this.options1 = this.items;  });         
    },
    methods:{     
        changeItem1() {
            let value = (this.selected1 !==  null) ? this.selected1.value : '0';
            this.$store.commit('set_ir_itemID', value);
            this.$root.$emit('changeIrItemId');
        },
        changeItem2() {
            let value = (this.selected2 !== null) ? this.selected2.value : '';
            this.$store.commit('set_ir_fulfillmentCenter', value);
        },
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        update(){
            this.loading = true;
            this.$root.$emit('updateInventoryReconciliation');
        },
         handleSearch ({ searchTerm }) {
          if (searchTerm.length > 1) {
           searchTerm = searchTerm.toLowerCase();
            this.options1 = cloneDeep(this.items.filter(x => (x.text.toLowerCase().indexOf(searchTerm) !== -1) || (x.value.toString().indexOf(searchTerm) !== -1) ))
          }
          else this.options1 = this.items
        }
    },
    watch:{
        selected1 : (val) => {

        }
    }
}
</script>

//  <style lang="scss">
//  .selection-placeholder input {
//    color: #9B9B9B !important;
//    font-style: italic;
//  }
//  </style>
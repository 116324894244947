<template>
  <div ref="costGoodsSold">
    <sb-table
      :columnDefinitions="columnDefinitions"
      :rows="tableHelper.pagedData"
      :loadingPromise="loadingPromise"
      @sort="handleSort"
      :defaultSortColumn="defaultSortColumn"
      :defaultSortOrdering="defaultSortOrdering"
    >
      <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
        <h3
          class="title"
        >Cost Of Goods Sold (COGS)</h3>
        <div class="search-wrapper"></div>
            <download-data-button :dataName="'dataCostGoodsSold'"   ></download-data-button>
 
         <vs-row>
                <vs-col vs-type="flex" vs-justify="flex-start">
                    <p class="p-info pl-5">Enter each SKU's cost so we can calculate your profitability analysis.</p>
                </vs-col>
            </vs-row>
      </sb-toolbar>
      <sb-table-row slot="sb-table-row" slot-scope="{row}">
        <sb-table-cell><a :href="app_link+row.inventoryId" target="_blank" rel="noopener noreferrer">
            <vs-tooltip :text="row.inventoryId + ' : ' + row.name" position="top">
              <p class="mb-0">{{row.name}}</p>
            </vs-tooltip>
          </a>
          </sb-table-cell> 
        <sb-table-cell>
          <template>
            <vs-row class="px-2 pb-3">
              <vs-col class="d-flex justify-content-end align-items-center">
                <vs-input v-model="row.costOfGood" />
                <!-- <input
                  type="button"
                  class="form-control p-1 ml-1"
                  style="max-width: 45px"
                  value="Save"
                  @change="update_cog(row.inventoryId, row.costOfGood)"
                /> -->
                 <sb-button @click="update_cog(row.inventoryId, row.costOfGood)"  variation="tertiary" size="medium" >Save</sb-button>
              </vs-col>
            </vs-row>
          </template>
        </sb-table-cell>
      </sb-table-row>
      <sb-table-empty-state slot="sb-table-empty-state" icon-name="products" label="No Inventory"></sb-table-empty-state>
      <pagination-wrapper
        slot="pagination"
        @fetchPage="handleFetchPage"
        :pageSize="tableHelper.pagination.pageSize"
        :currentPage="tableHelper.pagination.currentPage"
        :totalPages="tableHelper.pagination.totalPages"
        :totalRecords="tableHelper.pagination.totalRecords"
        :visibleRecords="tableHelper.pagination.visibleRecords"
      ></pagination-wrapper>
    </sb-table>
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import Vue from "vue";
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
  components: {
    "pagination-wrapper": PaginationWrapper
  },
  data() {
    return { 
      app_link: process.env.VUE_APP_SHIPBOB_LINK+'app/Merchant/#/Products/', 
      loadingPromise: null,
      columnDefinitions: [ 
        { name: "Inventory Name", field: "name", sortable: true },
        { name: "Cost of Goods Sold Amount", field: "costOfGood", sortable: true }
      ],
      tableHelper: new tabler(this, 12),
      editingRecord:{}
    };
  }, 
  mounted() {
    this.loadingPromise = this.fetch(1);
  },
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },
  methods: {
    handleSort({ sortColumn, descending }) { 
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    map(item) {
      return {
        inventoryId: item.inventoryId,
        name: item.name,
        costOfGood: item.costOfGood
      };
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    },
    fetch: async function(p) {
      await new Promise(res => { 
        this.tableHelper.preLoadReset();

        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "inventory/cogs"
        ).then(response => {
          let CostGoodsSold = response.data.data;
          this.tableHelper.loadData(CostGoodsSold, p);
 
          this.$store.commit("setDataCostGoodsSold", CostGoodsSold);
          this.$root.$emit("finishedFetch");
           res(true);
        });
       
      });
    },
    update_cog(inventoryId, cog) {
      cog = parseFloat(cog);
      if (cog !== NaN) {
        let costGoodsSoldConfig = {
          costOfGood: cog
        };
        axiosInstance(
          "PUT",
          process.env.VUE_APP_ENDPOINT + "inventory/" + inventoryId,
          null,
          costGoodsSoldConfig
        ).then(response => {
          if (response.status === 200) {
            Vue.$toast.open({
              message: "Inventory Id: " + inventoryId + " Saved successfully!",
              type: "info",
              position: "top-right",
              duration: 5000
            });
          } else {
            Vue.$toast.open({
              message: "Inventory Id: " + inventoryId + " Error!",
              type: "error",
              position: "top-right",
              duration: 10000
            });
          }
          let CostGoodsSold = response.data;
        });
      }
    }
  }
};
</script>
 <style scoped lang='scss'>
.customers-toolbar {
  padding-left: 0px;
}
</style>
<template>
<fade-transition :mode="'out-in'">
    <component :is="template">
      <router-view></router-view>
    </component>
  </fade-transition>
</template>
<script>
import AppTemplate from '@/components/layouts/AppTemplate'
const AppSideNavTemplate = () => import(/* webpackChunkName: "AppSideNavTemplate" */ '@/components/layouts/AppSideNavTemplate')
export default {
  name: 'shipbobVue',
  computed: {
    template () {  
      return (this.$route.fullPath!="/") ? AppSideNavTemplate : AppTemplate
    },
    route () {
      return this.$route
    }
  }
}
</script>
<style lang="scss">

    @import '../assets/scss/app.scss';
    @import '../node_modules/toastr/toastr.scss';

    #appContainer {
        width: 100%;
        height: 100%;
    }
</style>

<template>
  <div>
    
    <a href="#"
      class="nav-link"
      v-if="show"
      v-click-outside="closeDialog"
      v-bind:class="[icon, collapsedClass, activeClass, expanderClass, sublinkClass]"
      @click.prevent="callback($event)">
      <div v-if="isSublink">
        <span > {{ name }} </span>
      </div>
      <div v-else style="display: inline-block">
        <mq-layout :mq="['sm', 'xl', 'xxl']" style="display: inline-block">
          <span > {{ name }} </span>
        </mq-layout>
      </div>
      </a>
    <expand-transition>
      <div v-if="showSubLinks" class="subnav collapse" v-bind:class="{'show': hasChildren}">
        <SideNavLink v-for="(sublink, index) in children" :key="index" :link="sublink" :isSublink="true"></SideNavLink>
      </div>
    </expand-transition>
  </div>
</template>

<script>
export default {
  name: 'SideNavLink',
  props: {
    link: {
      type: Object,
      required: true
    },
    isSublink: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      collapsed: true
    }
  },
  computed: {
    sublinkClass () {
      return this.isSublink ? 'dark-link-background' : ''
    },
    collapsedClass () {
      return this.hasChildren && this.collapsed ? 'collapsed' : ''
    },
    activeClass () {
      return !this.collapsed ? 'active' : ''
    },
    expanderClass () {
      return this.collapsed ? '' : 'expander'
    },
    show () {
      if (typeof this.link.show === 'function') {
        return this.link.show.call(this)
      }
      return this.link.show
    },
    showSubLinks () {
      return !this.collapsed && this.hasChildren
    },
    name () {
      return this.link.name
    },
    icon () {
      return this.link.icon
    },
    children () {
      return this.link.children
    },
    hasChildren () {
      return this.children && this.children.length > 0
    }
  },
  methods: {
    callback (e) {
      if (this.hasChildren) {
        this.collapsed = !this.collapsed
      } else if (typeof this.link.callback === 'function') {
        this.link.callback.call(this, e)
      } else {
        const href = this.link.href
        if (typeof href === 'undefined' || href === null) return        
        window.location = href
      }
    },
    closeDialog () {
      this.collapsed = true
    }
  }
}
</script>

<style scoped lang="scss">
.dark-link-background {
  background-color: #1a2536;  
}

.nav-pills .nav-link
{
  border-radius: 0rem !important;
}
</style>

<template>
    <div>
        <vs-row vs-w="12" vs-justify="center" vs-align="center">
            <vs-col vs-w="12">
                <days-inventory-search-bar :items1="data.items1" :items2="data.items2" :items3="data.items3"/>
            </vs-col>
        </vs-row>
        <vs-divider></vs-divider>
         <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <vs-row vs-w="12" vs-justify="center" vs-align="center" style="height: 350px" v-else>
            <stacked-bar-chart :labels="data.daysInventoryLabels" :data="data.daysInventoryDataset" :options="data.daysInventoryOptions"/>
        </vs-row>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment';

export default {
    data() {
        return {
            data:{
                daysInventoryDataset:[],
                daysInventoryOptions:{              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    legend:{position:'bottom'},
                    scales:{
                        xAxes: [{
                            barPercentage: 0.2,
                            display: false
                        }],
                        yAxes: [{
                            gridLines: {
                                color: "#f6f6f6",
                            },
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                },
                items1: [
                    {text:'Top',value:'top'},
                    {text:'Low',value:'low'},
                    {text:'Search',value:'in'},
                ],
                items2: [
                    {text:'7 Days',value:'7'},
                    {text:'30 Days',value:'30'},
                    {text:'60 Days',value:'60'},
                    {text:'90 Days',value:'90'},
                ],
                items3: [],
            },
            loading: false
        }
    },
    mounted(){
        this.fetch();
        this.$root.$on('update', this.fetch);
        this.$root.$on('updateDaysInventory', this.fetch);
    },
    methods: {
        fetch(){
            this.loading = true;
            this.data.daysInventoryDataset = [];
            this.data.items3 = [];
            let daysInventoryConfig = {}
            let inventoryItemsConfig = {
                  IsActive:true,
                IsMerged:false,
                IsBundle:false
            }
            
            if(this.$store.state.di_select !== 'in') {
                daysInventoryConfig = {
                    timeFrame: this.$store.state.di_time,
                    selectionType: this.$store.state.di_select,
                    selections: false,
                    includeB2B:this.$store.state.includeB2BSales,
                }
            } else {
                daysInventoryConfig = {
                    timeFrame: this.$store.state.di_time,
                    selectionType: this.$store.state.di_select,
                    selections: this.$store.state.di_in_items,
                    includeB2B:this.$store.state.includeB2BSales,
                }
            }
            
            if(this.$store.state.salesItems.length === 0){
                axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/', inventoryItemsConfig).then((response) => {
                    response.data.data.forEach((item) => {
                        this.data.items3.push({ text: item.name, value: item.inventoryId})
                    });
                    this.$store.commit('setSalesItems', response.data.data);
                });
            } else {
                let salesItems = this.$store.state.salesItems;
                salesItems.forEach((item) => {
                    this.data.items3.push({ text: item.name, value: item.inventoryId})
                });
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/DaysOfInventory', daysInventoryConfig).then((response) => {
                response.data.items.forEach((item, index) => {
                    let color = "";

                    switch(index) {
                        case 0: color = '#59BAEF'; break;
                        case 1: color = '#1f66a6'; break;
                        case 2: color = '#88eca3'; break;
                        case 3: color = '#888a8e'; break;
                        default: color = '#1f66a6'; break;
                    }

                    this.data.daysInventoryDataset.push({
                        label: item.name,
                        backgroundColor: color,
                        borderColor:'transparent',
                        borderWidth: 1,
                        data: [item.days],
                    })
                })
                
                this.loading = false;
                this.$root.$emit('finishedFetch');
            });
        }
    } 
}
</script>

import HttpWrapper from '../utils/http/httpWrapper' 

export default class PowerBIService {

  static noop () {}

  static urls () {
    return {  
      powerBIConfigGeneratorUrl: process.env.VUE_APP_PowerBI + '/api/GetEmbedToken?ReportName='
    }
  } 


  static async GetReport (reportName) {
    const request = {
      Url: this.urls().powerBIConfigGeneratorUrl + reportName
    }
    return await HttpWrapper.Get(request, null, null, true); 
  }
}

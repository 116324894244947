<template>
    <div class="selling-state-item">
        <vs-col vs-w="10" class="progressx">
            <h6 class="selling-city mb-0 h6">{{item.destinationState}}</h6>
            <vs-progress :percent="this.maxValue" :color="'#59BAEF'" :height="9">HEX</vs-progress>
            <p v-if="item.subtitle !== undefined">{{item.subtitle}}</p>
        </vs-col>
        <vs-col vs-w="2">
            <p class="percentage">{{item.percentOfTotal}}%</p>
        </vs-col>
    </div>
</template>
<script>
export default {
    props:['item', 'max'],
    data(){
        return {
            maxValue:0
        }
    },
    mounted() { 
        this.item.percentOfTotal = Math.floor(this.item.percentOfTotal * 100)
         this.maxValue = this.item.percentOfTotal;
    }
}
</script>

<style scoped lang='scss'>
 
 .h6{
     font-size: 1rem;
     margin-bottom: 0px; 
 } 

</style>
const TWEEN = require('@tweenjs/tween.js')

const getComputedStyles = (el) => ({
  overflow: el.style.overflow,
  width: el.offsetWidth || 0,
  height: el.offsetHeight || 0,
  paddingLeft: el.style.paddingLeft || 0,
  paddingRight: el.style.paddingRight || 0,
  paddingTop: el.style.paddingTop || 0,
  paddingBottom: el.style.paddingBottom || 0,
  marginLeft: el.style.marginLeft || 0,
  marginRight: el.style.marginRight || 0,
  marginTop: el.style.marginTop || 0,
  marginBottom: el.style.marginBottom || 0
})
const getCachedStyles = (el) => ({
  overflow: el.cachedOverflow,
  width: el.cachedWidth,
  height: el.cachedHeight,
  paddingLeft: el.cachedPL,
  paddingRight: el.cachedPR,
  paddingTop: el.cachedPT,
  paddingBottom: el.cachedPB,
  marginLeft: el.cachedML,
  marginRight: el.cachedMR,
  marginTop: el.cachedMT,
  marginBottom: el.cachedMB
})
const cacheStyles = (el, styles) => {
  el.cachedOverflow = styles.overflow
  el.cachedWidth = styles.width
  el.cachedHeight = styles.height
  el.cachedPL = styles.paddingLeft
  el.cachedPR = styles.paddingRight
  el.cachedPT = styles.paddingTop
  el.cachedPB = styles.paddingBottom
  el.cachedML = styles.marginLeft
  el.cachedMR = styles.marginRight
  el.cachedMT = styles.marginTop
  el.cachedMB = styles.marginBottom
}
const setStyles = (el, styles) => {
  const MEASUREMENT = 'px'
  el.style.overflow = styles.overflow
  el.style.width = `${styles.width}${MEASUREMENT}`
  el.style.height = `${styles.height}${MEASUREMENT}`
  el.style.paddingLeft = `${styles.paddingLeft}${MEASUREMENT}`
  el.style.paddingRight = `${styles.paddingRight}${MEASUREMENT}`
  el.style.paddingTop = `${styles.paddingTop}${MEASUREMENT}`
  el.style.paddingBottom = `${styles.paddingBottom}${MEASUREMENT}`
  el.style.marginLeft = `${styles.marginLeft}${MEASUREMENT}`
  el.style.marginRight = `${styles.marginRight}${MEASUREMENT}`
  el.style.marginTop = `${styles.marginTop}${MEASUREMENT}`
  el.style.marginBottom = `${styles.marginBottom}${MEASUREMENT}`
}
const removeStyles = (el, styles) => {
  if (!Array.isArray(styles) && typeof styles === 'object') styles = Object.keys(styles)
  styles.forEach(s => el.style.removeProperty(s))
}

export default {
  name: 'expand',
  directive: {
    inserted (el) {
      cacheStyles(el, getComputedStyles(el))
    },
    componentUpdated (el, binding) {
      const newStyles = getComputedStyles(el)
      const cachedStyles = getCachedStyles(el)
      setStyles(el, {...cachedStyles, overflow: 'hidden'})
      cacheStyles(el, newStyles)

      const tween = new TWEEN.Tween(cachedStyles)
      tween.to(newStyles, (binding.value && binding.value.duration) || 250)
      .onUpdate((settings) => {
        setStyles(el, settings)
      })
      .onComplete(() => {
        removeStyles(el, {...cachedStyles, margin: '', padding: ''})
      })

      function animate (time) {
        requestAnimationFrame(animate)
        TWEEN.update(time)
      }
      requestAnimationFrame(animate)

      tween.start()
    }
  }
}

<template>
    <div>
        <vs-row vs-w="12">
            <vs-col vs-w="12" vs-justify="flex-start">
                <h6   style="text-align:center"  class="colorClass h6"><span v-if="simbol !== 'undefined'">{{simbol}}</span>{{value==0?"-":numberWithCommas(value)}}</h6>
                <div v-if="type === undefined" class="h2-sub" style="text-align:center">{{subtitle}}</div>
                <div v-if="type === 'normal-text'" style="text-align:center">{{subtitle}}</div>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['value', 'subtitle', 'simbol', 'colorClass', 'delta', 'type'],    
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    } 
}
</script>
<style scoped lang='scss'>
 
 .h6{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
 }

</style>
 



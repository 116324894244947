<template>
  <div>
   <div class="fulfillment-metrics-header">
     
     <h4 class="title">How to best leverage ShipBob's network of fulfillment centers</h4>
     
      <search-bar   kind="mapSearchBar" />
    
</div>
    <vs-row vs-justify="center">
      <vs-col vs-w="12">
        
        <p
           
        >See where your customers are shipping to for a better understanding of how to split inventory across ShipBob’s fulfillment centers to potentially save money. Each dot represents an order’s shipping destination during the selected time period.
       </p>
        <p
          
        >If you’re thinking about splitting inventory, consider whether warehousing costs, transportation costs to fulfillment centers, and the number of SKUs you have would be offset by the reduced shipping costs.</p>
      </vs-col>
    </vs-row>
    <div id="mapbox-map"></div>
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import mapboxgl from "mapbox-gl";

export default {
  data() {
    return {
      geoJSONData: null,
      accessToken: this.$store.state.mapboxApiKey, // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/fergocejo/cjpne6hec2pde2rp7xqxzkvhs"
    };
  },
  mounted() {
    this.fetchGeoJSONData();
    this.$root.$on("update_map", this.fetchGeoJSONData);
  },
  destroyed() {
    this.$root.$off("update_map", this.fetch);
  },
  methods: {
    fetchGeoJSONData() {
      let mapConfig = {
        fromDate: moment(new Date(this.$store.state.from)).format("MM/DD/YYYY"),
        toDate: moment(new Date(this.$store.state.to)).format("MM/DD/YYYY")
      };

      axiosInstance(
        "GET",
        process.env.VUE_APP_ENDPOINT + "inventory/distribution/map",
        mapConfig
      ).then(response => {
        mapboxgl.accessToken = this.accessToken;
        const map = new mapboxgl.Map({
          container: "mapbox-map",
          style: "mapbox://styles/mapbox/light-v9",
          zoom: 3.5,
          center: [-95, 39]
        });

        this.$root.$emit("finishedFetch");
        let mapSelectedDates =    moment(new Date(this.$store.state.from)).format("MM/DD/YYYY") + ' to ' +  moment(new Date(this.$store.state.to)).format("MM/DD/YYYY");
        this.$store.commit('setMapSelectedDates', mapSelectedDates);
        this.$root.$emit("finishedsetMapSelectedDates");

        map.on("load", function() {
          // Add a new source from our GeoJSON data and set the
          // 'cluster' option to true. GL-JS will add the point_count property to your source data.
          map.addSource("orders", {
            type: "geojson",
            // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
            // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
            data: response.data,
            cluster: true,
            clusterMaxZoom: 1, // Max zoom to cluster points on
            clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
          });

          map.addLayer({
            id: "clusters",
            type: "circle",
            source: "orders",
            filter: ["has", "totalOrders"],
            paint: {
              // Use step expressions (https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
              // with three steps to implement three types of circles:
              //   * Blue, 20px circles when point count is less than 100
              //   * Yellow, 30px circles when point count is between 100 and 750
              //   * Pink, 40px circles when point count is greater than or equal to 750
              "circle-color": [
                "step",
                ["get", "totalOrders"],
                "#59BAEF",
                100,
                "#459FD7",
                750,
                "#2C7BB9",
                1200,
                "#2470AF"
              ],
              "circle-radius": [
                "step",
                ["get", "totalOrders"],
                2,
                100,
                3,
                750,
                4,
                1200,
                5
              ]
            }
          });

          map.addLayer({
            id: "cluster-count",
            type: "symbol",
            source: "orders",
            layout: {
              // "text-field": "{totalOrders}",
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 12
            }
          });

          // inspect a cluster on click
          map.on("click", "clusters", function(e) {
            var features = map.queryRenderedFeatures(e.point, {
              layers: ["clusters"]
            });
            var clusterId = features[0].properties.cluster_id;
            map
              .getSource("orders")
              .getClusterExpansionZoom(clusterId, function(err, zoom) {
                if (err) return;

                map.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom
                });
              });
          });

          map.on("mouseenter", "clusters", function() {
            map.getCanvas().style.cursor = "pointer";
          });
          map.on("mouseleave", "clusters", function() {
            map.getCanvas().style.cursor = "";
          });
        });
      });
    }
  }
};
</script>
<style  scoped lang='scss'>
// .mapbox-map
// {
//     min-height:400px;
// }
@import '@/../assets/scss/shipbob-custom/components/dashboard/_dashboard-panel-template.scss';
 @import '@/../assets/scss/shipbob-custom/components/dashboard/_panel-fulfillment-metrics.scss';
   
.customers-toolbar {
  padding-left: 0px;
}
.vdp-datepicker {
  max-width: 100px;
  padding-left: 10px;
  position: unset;
}
   .fulfillment-metrics-container
   {
       padding-bottom: 0px;
   }
</style>
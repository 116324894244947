<template>
  <sb-table
    :columnDefinitions="columnDefinitions"
    :rows="tableHelper.pagedData"
    :loadingPromise="loadingPromise"
    @sort="handleSort"
    :defaultSortColumn="defaultSortColumn"
    :defaultSortOrdering="defaultSortOrdering"
  >
    <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
       
       <sb-dropdown
          :options="options1"
          v-model="showActiveRecords"
          @input="changeItem1()"
            class="select-size-200"
        >
          <sb-selected-option slot="selection" slot-scope="{ selected }">{{ selected.text }}</sb-selected-option>
          <sb-options>
            <sb-option slot-scope="{ option }" :value="option">{{ option.text }}</sb-option>
          </sb-options>
        </sb-dropdown>
      <sb-button @click="update" variation="primary" size="medium" @click.prevent>Apply</sb-button>

      <div class="search-wrapper">
          
      </div> 
      <sb-button
        @click="markResolved"
        variation="secondary"
        size="medium"
        @click.prevent
      >{{textMark}}</sb-button>
    </sb-toolbar>
    <sb-table-row slot="sb-table-row" slot-scope="{row}">
      <sb-table-cell>
        <sb-checkbox v-model="row.isSelected"></sb-checkbox>
      </sb-table-cell>
      <sb-table-cell>
        <a
          :href="app_link+'Orders/'+row.orderId"
          target="_blank"
          rel="noopener noreferrer"
        >{{row.orderId}}</a>
      </sb-table-cell>
      <sb-table-cell>{{dateFormat(row.shipDate)}}</sb-table-cell>
      <sb-table-cell>{{row.localBusinessDaysSinceShipped}}</sb-table-cell>
      <sb-table-cell>{{row.shipOptionMaxDeliverDays}}</sb-table-cell>
      <sb-table-cell>{{row.carrier}}</sb-table-cell>
      <sb-table-cell>{{row.carrierService}}</sb-table-cell>
      <sb-table-cell>{{row.shipBobZoneUsed}}</sb-table-cell>
      <sb-table-cell>
        <a
          :href="getAppLink(row.carrier, row.trackingId)"
          target="_blank"
          rel="noopener noreferrer"
        >{{row.trackingId}}</a>
      </sb-table-cell>
      <sb-table-cell>{{row.buyerEmail}}</sb-table-cell>
    </sb-table-row>
    <sb-table-empty-state
      slot="sb-table-empty-state"
      icon-name="users"
      label="No Undelivered Shipments"
    >
      <p>You are in the clear, there are no Undelivered Shipments!</p>
    </sb-table-empty-state>
    <pagination-wrapper
      slot="pagination"
      @fetchPage="handleFetchPage"
      :pageSize="tableHelper.pagination.pageSize"
      :currentPage="tableHelper.pagination.currentPage"
      :totalPages="tableHelper.pagination.totalPages"
      :totalRecords="tableHelper.pagination.totalRecords"
      :visibleRecords="tableHelper.pagination.visibleRecords"
    ></pagination-wrapper>
  </sb-table>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import Vue from "vue";
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
  components: {
    "pagination-wrapper": PaginationWrapper
  },
  props: [
    "title", 
  ],
  data() {
    return {
      maxItems: 20,
      maxItemsAux: 20,
      loadingPromise: null,
      dataSelected: [],
      app_link: process.env.VUE_APP_SHIPBOB_LINK + "App/Merchant/#/",
      options1: [
        { text: "Unreviewed", value: 1 },
        { text: "Reviewed", value: 0 }
      ],
      showActiveRecords: { text: "Unreviewed", value: 1 },
      textMark: "Mark as Reviewed",
      counter: 0,
      loading: false,
      tableHelper: new tabler(this, 30),
      columnDefinitions: [
        { name: "", field: "isSelected" },
        { name: "Order ID", field: "orderId" },
        { name: "Ship Date", field: "shipDate", sortable: true },
        {
          name: "Days Since Shipped",
          field: "localBusinessDaysSinceShipped",
          sortable: true
        },
        {
          name: "Est. Transit Days",
          field: "shipOptionMaxDeliverDays",
          sortable: true
        },
        { name: "Carrier", field: "carrier" , sortable: true },
        { name: "Carrier Service", field: "carrierService" , sortable: true },
        { name: "ZONE", field: "shipBobZoneUsed" },
        { name: "Tracking ID", field: "trackingId" },
        { name: "Buyer Email", field: "buyerEmail" }
      ]   
      };
  }, 
  mounted() {
    this.loadingPromise = this.fetch(1);
  },
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },
  methods: {
    dateFormat(date) {
      return moment(new Date(date)).format("MM/DD/YYYY");
    },
    handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    changeMaxItems() {
      if (this.maxItemsAux > 0) {
        this.maxItems = this.maxItemsAux;
      }
    },
    map(item) {
      return {
        isSelected: false,
        orderId: item.orderId,
        shipDate: item.shipDate,
        localBusinessDaysSinceShipped: item.localBusinessDaysSinceShipped,
        shipOptionMaxDeliverDays: item.shipOptionMaxDeliverDays,
        carrier: item.carrier,
        carrierService: item.carrierService,
        shipBobZoneUsed: item.shipBobZoneUsed,
        trackingId: item.trackingId,
        buyerEmail: item.buyerEmail
      };
    },
    changeItem1() {
      if (this.showActiveRecords.value) {
        this.textMark = "Mark as Reviewed";
      } else {
        this.textMark = "Mark as Unreviewed";
      }
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    },
    fetch: async function(p) {
      await new Promise(res => {
        //setTimeout(() => {
        this.loading = true;

        this.dataSelected = [];
        this.tableHelper.preLoadReset();

        let config = {
          showActiveRecords: this.showActiveRecords.value ? true : false
        };

        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "orders/tracking/ageofshipments",
          config
        ).then(response => {
          this.tableHelper.loadData(response.data.data, p);
          this.loading = false;
          this.$store.commit("setDataAgeOrders", response.data.data);
          this.$root.$emit("finishedFetch");
          res(true);
        });
      });
    },
    update() {
      this.loadingPromise = this.fetch(1);
    },
    markResolved() {
      let config = {
        isActive: !this.showActiveRecords.value ? true : false
      };
      this.dataSelected = this.tableHelper.pagedData.filter(item => {
        return item.isSelected;
      });

      for (let i = 0; i < this.dataSelected.length; i++) {
        let item = this.dataSelected[i];
        let id = item.orderId;
        axiosInstance(
          "PUT",
          process.env.VUE_APP_ENDPOINT + "orders/tracking/ageofshipments/" + id,
          null,
          config
        ).then(response => {
          if (response.status === 200) {
            Vue.$toast.open({
              message: "Order Id: " + id + " Saved successfully!",
              type: "info",
              position: "top-right",
              duration: 5000
            });
          } else {
            Vue.$toast.open({
              message: "Order Id: " + id + " Error!",
              type: "error",
              position: "top-right",
              duration: 10000
            });
          }
          if (i === this.dataSelected.length - 1) {
            this.handleFetchPage({ page: 1 });
          }
        });
      }
    },
    getAppLink(carrier, trackingNumber) {
      var app_link = "";

      if (carrier == null) return;

      switch (
        carrier
          .trim()
          .replace(" ", "")
          .toUpperCase()
      ) {
        case "USPS":
          app_link =
            "https://tools.usps.com/go/TrackConfirmAction!input.action?tRef=qt&tLc=0&tLabels=" +
            trackingNumber;
          break;
        case "FEDEX":
          app_link =
            "https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=" +
            trackingNumber +
            "&cntry_code=us";
          break;
        case "UPS":
        case "UPSMAILINNOVATIONS":
        case "UPSSUREPOST":
          app_link =
            "http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=" +
            trackingNumber +
            "&loc=en_us";
          break;
        case "DHL":
        case "DHLGLOBALMAIL":
        case "DHLGLOBALMAILINTERNATIONAL":
        case "DHLECOMMERCE":
          app_link =
            "http://webtrack.dhlglobalmail.com/?trackingnumber=" +
            trackingNumber;
          break;
        case "NEWGISTICS":
          app_link = "https://track.aftership.com/usps/" + trackingNumber;
          break;
        case "DHLEXPRESS":
          app_link =
            "http://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=" +
            trackingNumber;
          break;
        case "ASENDIA":
          app_link =
            "http://apps.asendiausa.com/tracking/packagetracking.html?uid=MTc0ODMzMjM1NDA5NjUyMzMyODY3&pid=" +
            trackingNumber;
          break;
        case "LASERSHIP":
          app_link =
            "http://apps.asendiausa.com/tracking/packagetracking.html?uid=MTc0ODMzMjM1NDA5NjUyMzMyODY3&pid=" +
            trackingNumber;
          break;
        case "SHURPA":
          app_link = "https://app.shurpa.com/tracking/" + trackingNumber;
          break;
      }

      return app_link;
    }
  }
};
</script>
<style lang="scss">
.sb-dropdown {
  max-width: 300px;
}

.customers-toolbar {
  padding-left: 0px;
}

.customers-toolbar {
  .title {
    margin: 0;
  }
  .btn {
    margin-left: 20px;
  }
  .search-wrapper {
    flex-grow: 1;
    margin-left: 1em;
    .input-container {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 681px) {
    .search-wrapper {
      flex-basis: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
    button {
      margin-top: 1em;
    }
  }
}

.customers-toolbar {
  .title {
    margin: 0;
  }
  .btn {
    margin-left: 20px;
  }
  .search-wrapper {
    flex-grow: 1;
    margin-left: 1em;
    .input-container {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 681px) {
    .search-wrapper {
      flex-basis: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
    button {
      margin-top: 1em;
    }
  }
  
}
.icon-users:before {
  content: "";
}
.newCustomerBanner {
  margin-bottom: 0;
}
.zendesk-spacer {
  margin-right: 8em;
}
.addNew {
  margin-right: 1rem !important;
}
</style>
<template>
    <div class="accotation-item">
        <vs-row  vs-w="13"     vs-justify="flex-start" vs-align="center" >
            <vs-col vs-w="1"  vs-type="flex" vs-justify="flex-start" vs-align="center" >
                <div  :class="'doughnut ' + color" v-if="this.enabled"></div>
                <div class='doughnut disabled' v-else></div> 
                 
            </vs-col>
            <vs-col  vs-w="8" vs-type="flex"  >
               
                <h4 style="padding-left:5px;font-size:15px" class="accotation-title h6-Color">{{title}}</h4>
                 
            </vs-col>
            <vs-col  vs-w="1" vs-type="flex" vs-justify="center" vs-align="center" >
               
                <span style="padding-left:25px" v-if="showPercent==undefined"> {{percentage}}%</span>
                <span style="padding-left:25px" class="element" v-if="showPercent"> $ {{numberWithCommas(percentage)}}</span>
               
            </vs-col>
           
             
        </vs-row>
          <vs-row  vs-w="12"     vs-justify="flex-start" vs-align="center" >
                <vs-col  vs-w="2" vs-type="flex" vs-justify="center" vs-align="center" >
              
                <div  class="form-group" v-if="requiredDropdown"> 
                     <div>
                         <sb-checkbox  v-model="isActive" @change="toggleAccotation($event)">Active</sb-checkbox>
                       
                    </div>
                      
                </div> 
            </vs-col>
                </vs-row>
    </div>
</template>
<script>
export default {
    props:['color', 'title', 'percentage', 'requiredDropdown', 'showPercent','internalValue'],
    data() {
        return {
            isActive: true,
            enabled: true,
            selectedItem:0, 
            value:this.internalValue
        }
    },
    mounted(){
         
    },
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
         toggleAccotation(e) {
         
            if(e==false)
            { 
                this.selectedItem="0"
                
            }else
            {
                this.selectedItem="1" 
            } 
            
            if(this.selectedItem === 0) {
                this.enabled = false;
            } else {
                this.enabled = true;
            }

            let varName = ""; 
            let result = {title:this.title,contextValue:this.internalValue, selectValue:  e}; 
            // if(this.title !== undefined){
            //     varName = 'setideal_' + this.title.toLowerCase();
            //     result = {title:this.title,contextValue:this.internalValue, selectValue:  false}
            // } else {
            //     varName = "default"
            // }
           
            this.$store.commit(varName, this.selectedItem); 
            this.$root.$emit("accotationChanged",result);
        }   
    } 
}
</script>
 
 <style scoped lang='scss'> 

.radio {
    margin-left: .5em; 
}
</style>
<style scoped lang='scss'>
 
 .h6{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
 }
  .h6-Color{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
    color: #b1b1b1;
 }
.accotation-item
{
    width: 100%; 
    padding-top: 0.5rem;
    padding-right: 0px;
    padding-bottom: 0.5rem;
    padding-left: 0px;
}
 .form-group
 {
     margin-left:30px;
     margin-bottom: 0px;
 }
</style>
 

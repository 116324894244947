import { render, staticRenderFns } from "./AgeOrdersV2.vue?vue&type=template&id=531110bd&"
import script from "./AgeOrdersV2.vue?vue&type=script&lang=js&"
export * from "./AgeOrdersV2.vue?vue&type=script&lang=js&"
import style0 from "./AgeOrdersV2.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "AgeOrdersV2.vue"
export default component.exports
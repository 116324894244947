import { render, staticRenderFns } from "./DownloadDataButton.vue?vue&type=template&id=1574f199&"
import script from "./DownloadDataButton.vue?vue&type=script&lang=js&"
export * from "./DownloadDataButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "DownloadDataButton.vue"
export default component.exports
import { render, staticRenderFns } from "./Accotation.vue?vue&type=template&id=e2223aee&scoped=true&"
import script from "./Accotation.vue?vue&type=script&lang=js&"
export * from "./Accotation.vue?vue&type=script&lang=js&"
import style0 from "./Accotation.vue?vue&type=style&index=0&id=e2223aee&scoped=true&lang=scss&"
import style1 from "./Accotation.vue?vue&type=style&index=1&id=e2223aee&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2223aee",
  null
  
)

component.options.__file = "Accotation.vue"
export default component.exports
<template>
    <div>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else style="">
            <p style="padding: 0px 15px"> </p>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="12" vs-justify="center" vs-align="center">
                    <stacked-bar-chart :labels="data.totalMonthlyLabels" :data="data.totalMonthlyDataset" :options="data.totalMonthlyOptions"/>
                </vs-col>
            </vs-row>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {
    props:['title'],
    data() {
        return {
            data: {
                avgOrderAmount:0,
                avgOrderDelta:0,
                totalMonthlyLabels: [],
                totalMonthlyDataset: [],
                totalMonthlyOptions: {
                    responsive: true, 
                    maintainAspectRatio: false, 
                    legend:{position:'bottom'},
                    tooltips:{
                        enabled: true
                    },
                    scales: {
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                    xAxes: [{
                        stacked: true,
                        barPercentage: 0.8,
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            color: "#ffffff",
                        }
                    }]

                    }
                },
            },
            loading: false
        }
    },
    mounted() {
         this.loading = true;
         this.$root.$on('finishedFetchDataBillingBreakDown', () => {  
            let newData = this.$store.getters["getDataBillingBreakDown"]; 
             this.fetch(newData);
             }); 
    },
    methods: {
        dateFormat(date) {
            return moment(new Date(date)).format('MM/DD/YYYY')
        },
        fetch(newData) {
            this.loading = true;
            this.data.totalMonthlyDataset = [];
            this.data.totalMonthlyLabels = [];
            
            let level1 = [];
            let level1Color = [];
			let level2 = [];
			let level2Color = [];
			let level3 = [];
			let level3Color = [];
            
             let responseData = newData;
                responseData =  responseData.reverse();

				responseData.forEach((item, index) => {
                    this.data.totalMonthlyLabels.push(this.dateFormat(item.month));
                    level1.push((item.fulfillment + item.pickFees).toFixed(2));
                    level1Color.push('#59BAEF');
                    level2.push((item.storage).toFixed(2));
                    level2Color.push('#92EFAC');                    
                    level3.push((item.receiving + item.returns + item.freightPrepaidB2B + item.serialScan + item.kitting + item.other).toFixed(2));
                    level3Color.push('#2470AF');                    
				});

				this.data.totalMonthlyDataset.push({
                    label: 'Fulfillment',
					data: level1,
					backgroundColor: level1Color,
					borderColor: 'transparent',
				});

				this.data.totalMonthlyDataset.push({
                    label: 'Storage',
					data: level2,
					backgroundColor: level2Color,
					borderColor: 'transparent',
				});

				this.data.totalMonthlyDataset.push({
                    label: 'Other',
					data: level3,
					backgroundColor: level3Color,
					borderColor: 'transparent',
                });
                this.loading = false;
                this.$root.$emit('finishedFetch'); 
 
        }
    } 
}
</script>

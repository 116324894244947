export default [ 
    {
        id:1,
        name: 'Dashboard',
        href: '/Dashboard',
        icon: 'dashboard', 
        callback: function () {
          this.$router.push({ name: 'Dashboard' })
        },
        show: true
    },
    {
      id:2,
      name: 'Fulfillment', 
      icon: 'box1',  
      show: true,
      children: [
        {
          name: 'SLA & Exceptions',
          href: '/fulfillment/sla-exceptions',
          icon: '',
          callback: function () {
            this.$router.push({ name: 'SlaExceptions' })
          },
          show: true
        }
      ]
  },
    {
      id:3,
      name: 'Shipping', 
      icon: 'truck',  
      show: true,
      children: [
        {
          name: 'Performance',
          href: '/shipping/performance',
          icon: '',
          callback: function () {
            this.$router.push({ name: 'performance' })
          },
          show:  function() {
            return !this.$store.state.isBillingRestrictedCustomer;    
          }
        },{
          name: 'Undelivered Shipments',
          href: '/shipping/undelivered-shipments',
          icon: '',
          callback: function () {
            this.$router.push({ name: 'UndeliveredOrders' })
          },
          show: true
        }]
  },
  {
    id:4,
    name: 'Inventory',
    icon: 'products',
    show: true,
    children: [
      {
        name: 'On Hand',
        href: '/inventory/on-hand',
        icon: '',
        callback: function () {
          this.$router.push({ name: 'OnHand' })
        },
        show: true
      }, 
      {
        name: 'Inventory Reconciliation',
        href: '/inventory/reconciliation',
        icon: '',
        callback: function () {
          this.$router.push({ name: 'Reconciliation' })
        },
        show: true
      }  ,
      {
        name: 'Daily History',
        href: '/inventory/history',
        icon: '',
        callback: function () {
          this.$router.push({ name: 'History' })
        },
        show: true
      }  ,
      {
        name: 'SKU Performance',
        href: '/inventory/sku-performance',
        icon: '',
        callback: function () {
          this.$router.push({ name: 'Skuperformance' })
        },
        show: true
      },
      {
        name: 'Planning',
        href: '/inventory/planning',
        icon: '',
        callback: function () {
          this.$router.push({ name: 'Planning' })
        },
        show: true
      },
      {
        name: 'Inventory Distribution',
        href: '/inventory/map-and-distribution',
        icon: '',
        callback: function () {
          this.$router.push({ name: 'MapAndDistribution' })
        },
        show: true
      }    
    ] 
},
{
  id:5,
  name: 'Accounting',
  icon: 'calc',
  show:  function() {
    return !this.$store.state.isBillingRestrictedCustomer;    
  },
  children: [
    {
      id:1,
      name: 'Shopify Profitability',
      href: '/accounting/profitability"',
      icon: '',
      callback: function () {
        this.$router.push({ name: 'ShopifyProfitability' })
      },
      show: true
    }, 
    {
      id:2,
      name: 'Billing Breakdown',
      href: '/accounting/break-down',
      icon: '',
      callback: function () {
        this.$router.push({ name: 'BreakDown' })
      },
      show: true
    },  
    {
      id:3,
      name: 'Storage Costs',
      href: '/accounting/storage',
      icon: '',
      callback: function () {
        this.$router.push({ name: 'Storage' })
      },
      show: true
    }  
  ] 
}
];

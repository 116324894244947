<template>
    <div> 

             <sb-table
          :columnDefinitions="columnDefinitions"
          :rows="tableHelper.pagedData" 
          :loadingPromise="loadingPromise"
          @sort="handleSort"
          :defaultSortColumn="defaultSortColumn"
          :defaultSortOrdering="defaultSortOrdering" 
        >
        <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
               
        <h3 class="title">Frequently Purchased Together SKUs (Last 30 Days)</h3>
        <div class="search-wrapper"> 
      </div>
                                     <!-- <sb-button   @click="focusCOGS()" variation="primary"   size="small" @click.prevent="" >Update COGS</sb-button>
                                     -->
                                     <sb-button @click="fetch(1)"  variation="primary"   size="small" @click.prevent="" >Refresh Analysis</sb-button>
            <download-data-button :dataName="'dataFrequentlyPurchased'"   ></download-data-button>

      

          <vs-row vs-w="12" vs-justify="left" vs-align="center">
            <!-- <vs-col vs-w="6">
                <p>
                    <a href="#costGoodsSold">
                        Click here to enter your COGS. Then click the Refresh button to update the results.
                    </a>
                  
                </p> 
            </vs-col> -->
            <vs-col vs-w="1"> 
                 <p>
                    Net Revenue<br/>
                    Fulfillment<br/>
                    COGS<br/>
                    Profit<br/>
                </p> 
            </vs-col>
             <vs-col vs-w="4"> 
                 <p>
                    = Item Revenue + Shipping Revenue - Taxes - Discounts <br/>
                    = Fulfillment +  Shipping + Additional Pick Fees (if any) <br/>
                    = Cost of goods sold provided by you <br/>
                    = Net Revenue - Fulfillment Cost - COGS <br/>
                </p> 
            </vs-col>
        </vs-row>
      </sb-toolbar> 
          <sb-table-row slot="sb-table-row" slot-scope="{row}">  
                            <sb-table-cell class="table-FrequentlyPurchased-order-content"> 
                                     <template v-for="itemx2 in Object.keys(row.inventoryItems)">
                                        <vs-tooltip :key="itemx2" :text="row.inventoryItems[itemx2]['display']" position="top" >
                                            <p class="mb-0">
                                                {{row.inventoryItems[itemx2]['display']}}
                                            </p>
                                        </vs-tooltip>
                                    </template> 
                               </sb-table-cell>
                                  <sb-table-cell>
                                    $ {{row.revenueFromItems}}
                                </sb-table-cell>
                                  <sb-table-cell>
                                    $ {{row.revenueFromShipping}}
                               </sb-table-cell>
                                   <sb-table-cell>
                                    $ {{row.taxCollected}}
                                 </sb-table-cell>
                                   <sb-table-cell>
                                    $ {{row.totalDiscounts}}
                                </sb-table-cell>
                                   <sb-table-cell>
                                    $ {{row.netRevenue}}
                                </sb-table-cell>
                                  <sb-table-cell>
                                    $ {{row.fulfillmentCost}}
                                </sb-table-cell>
                                  <sb-table-cell>
                                    $ {{row.cogs}}
                                </sb-table-cell>
                                 <sb-table-cell>
                                    $ {{row.profit}}
                                </sb-table-cell>
                                  <sb-table-cell>
                                    {{row.orderCount}}
                                 </sb-table-cell>
                                   <sb-table-cell>
                                    $ {{row.profitPerOrderGroup}}
                                 </sb-table-cell>

          </sb-table-row>
          <sb-table-empty-state
            slot="sb-table-empty-state"
            icon-name="products"
            label="No Order Combinations"
          >
            <p>Once you start shipping, we will analyze the most frequently purchased items. </p>
          </sb-table-empty-state> 
            <pagination-wrapper
            slot="pagination"
            @fetchPage="handleFetchPage"
            :pageSize="tableHelper.pagination.pageSize"
            :currentPage="tableHelper.pagination.currentPage"
            :totalPages="tableHelper.pagination.totalPages"
            :totalRecords="tableHelper.pagination.totalRecords"
            :visibleRecords="tableHelper.pagination.visibleRecords"
          ></pagination-wrapper>
        </sb-table> 
              
        </div> 
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'
import tabler from "@/utils/tablehelper"; 
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
     components: {
    "pagination-wrapper": PaginationWrapper
  },
    data() {
        return { 
            loading: false,
            loadingPromise: null,
             columnDefinitions: [ 
                { name: "Order Combination by Qty", field: "inventoryItems" },
                { name: "Item Revenue", field: "revenueFromItems", sortable: true },   
                { name: "Shipping Revenue", field: "revenueFromShipping", sortable: true },                
                { name: "Taxes", field: "taxCollected", sortable: true },                
                { name: "Discounts", field: "totalDiscounts", sortable: true },                
                { name: "Net Revenue", field: "netRevenue", sortable: true },                
                { name: "Fulfillment", field: "fulfillmentCost", sortable: true },                
                { name: "COGS", field: "cogs", sortable: true },                
                { name: "Profit", field: "profit", sortable: true },                
                { name: "Orders", field: "orderCount", sortable: true },                
                { name: "Profit / Order", field: "profitPerOrderGroup", sortable: true } ,               
            ], 
            tableHelper: new tabler(this,12)
        }
    }, 
      mounted() {
     this.loadingPromise = this.fetch(1);
  },
   computed: {
     defaultSortColumn () {
      return null;
    },
    defaultSortOrdering () {
      return null;
    }
    } ,
    methods: {
     
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }, 
        handleSort ({ sortColumn, descending }) {
            this.tableHelper.handleSort({ sortColumn:sortColumn, descending:descending });
        }, 
        map(item)
        { 
            let itemName = "";
           
            return { 
                inventoryItems :item.inventoryItems, 
                revenueFromItems : this.numberWithCommas(Math.round(item.revenueFromItems)),
                revenueFromShipping: this.numberWithCommas(Math.round(item.revenueFromShipping)),
                taxCollected : this.numberWithCommas(Math.round(item.taxCollected)),
                    totalDiscounts : this.numberWithCommas(Math.round(item.totalDiscounts)),
                    netRevenue : this.numberWithCommas(Math.round(item.netRevenue)),
                    fulfillmentCost : this.numberWithCommas(Math.round(item.fulfillmentCost)),
                    cogs : this.numberWithCommas(Math.round(item.cogs)),
                    profit : this.numberWithCommas(Math.round(item.profit)),
                    orderCount : this.numberWithCommas(Math.round(item.orderCount)),
                    profitPerOrderGroup : this.numberWithCommas(item.profitPerOrderGroup.toFixed(2)),
            };  
        },
        handleFetchPage(p) {
            this.loadingPromise = this.tableHelper.loadData(this.tableHelper.receivedData, p.page);
        },
     fetch: async function(p) {
            await new Promise(res => {
            this.loading = true;
             
            this.tableHelper.preLoadReset();

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'profitability/orders').then((response) => { 
                let FrequentlyPurchased = response.data.data; 
                 
                this.tableHelper.loadData(FrequentlyPurchased, p);    
                this.loading = false;
                this.$store.commit('setDataFrequentlyPurchased', FrequentlyPurchased);
                this.$root.$emit('finishedFetch');
                  res(true);
            }); 
      });
        }
    }
}
</script>
 <style scoped lang='scss'> 

.customers-toolbar {
  padding-left: 0px;
}  
</style>
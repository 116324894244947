<template>
    <div>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="6">
                    <indicator-subtitle-delta :value="data.avgOrderAmount" subtitle="Avg Cart Amount" :delta="data.avgOrderDelta"/>
                </vs-col>
            </vs-row>
            <vs-divider></vs-divider>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <stacked-bar-chart :labels="data.shippingLabels" :data="data.shippingDataset" :options="data.shippingOptions"/>
            </vs-row>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {
    props:['title'],
    data() {
        return {
            data: {
                avgOrderAmount:0,
                avgOrderDelta:0,
                shippingLabels: [],
                shippingDataset: [],
                shippingOptions: {
                    responsive: true, 
                    maintainAspectRatio: false, 
                    legend:{position:'none'},
                    tooltips:{
                        enabled: false
                    },
                    scales: {
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                    xAxes: [{
                        stacked: true,
                        barPercentage: 0.2,
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            color: "#ffffff",
                        }
                    }]

                    }
                },
            },
            loading: false
        }
    },
    mounted() {
        this.fetch();
        this.$root.$on('update', this.fetch);
    },
    methods: {
        fetch() {
            this.loading = true;
            this.data.shippingDataset = [];
            this.data.shippingLabels = [];
            
            let qty5 = [];
			let qty6 = [];
			let qty7 = [];
            let configShippingMethod = {
				fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
                toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
                compare: this.$store.state.comparison
            }
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/shippingmethod/metrics', configShippingMethod).then((response) => {
                let responseData = response.data;
                
				this.data.avgOrderAmount = responseData.summary.cartAmount.avg;
				this.data.avgOrderDelta = responseData.summary.cartAmount.delta;
				  
				responseData.shippingMethods.forEach((item, index) => {
                    this.data.shippingLabels.push(item.shippingMethod.substring(0, 15));
					qty5.push(item.totalCartAmount.min);
					qty6.push(parseFloat(item.totalCartAmount.avg) - parseFloat(item.totalCartAmount.min));
					qty7.push(parseFloat(item.totalCartAmount.max) - parseFloat(item.totalCartAmount.avg));
				});

				this.data.shippingDataset.push({
					data: qty5,
					backgroundColor: [
						'rgba(255, 99, 132, 0)',
						'rgba(255, 99, 132, 0)',
						'rgba(255, 99, 132, 0)',
						'rgba(255, 99, 132, 0)',
					],
					borderColor: 'transparent',
				});

				this.data.shippingDataset.push({
					data: qty6,
					backgroundColor: [
						'#59BAEF88',
						'#46547088',
						'#88eca388',
						'#888a8e88',
					],
					borderColor: 'transparent',
				});

				this.data.shippingDataset.push({
					data: qty7,
					backgroundColor: [
						'#59BAEF',
						'#465470',
						'#88eca3',
						'#888a8e',
					],
					borderColor: 'transparent',
                });
                this.loading = false;
                this.$root.$emit('finishedFetch');                
	  		});
        }
    } 
}
</script>

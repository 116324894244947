<template>
     <div v-if="this.loading" class="spin-center-ideal-distribution center">
        <div class="ld ld-ring ld-spin running"></div>
    </div>
    <div   v-else>
        <p  >View which fulfillment centers shipped your <b>last {{data.numberOrders}} orders</b> and the total costs.</p>
        <vs-row >
            <vs-col vs-w="8">
                 <div class="current-distribution-container canvas" >
                <doughnut-chart :data="data.currentDistributionDataset" :options="data.currentDistributionOptions" :labels="data.currentDistributionLabels"></doughnut-chart>
                  </div>
            </vs-col>
              <vs-col   vs-w="4" vs-align="center" vs-type="flex"> 
                  <div style="margin:auto;">
                <accotation-vertical v-for="item in data.currentDistributionAccotations" :key="item.id"  :title="item.title" :percentage="item.percentage"   :color="item.colorClass"></accotation-vertical>                
                </div>
            </vs-col>
        </vs-row>
      
        <vs-divider></vs-divider>
        <vs-row vs-justify="center">
            <vs-col vs-w="6">
                <indicator-subtitle-h2 :value="'$ ' + data.totalShippingCost" :subtitle="'Fulfillment Cost'"/>
            </vs-col>
            <vs-col vs-w="6">
                <indicator-subtitle-h2 :value="data.numberOrders" :subtitle="'Number of Orders'"/>
            </vs-col>
        </vs-row>
        <vs-divider class="blank-divider"></vs-divider>
        <vs-row vs-justify="center">
            <vs-col vs-w="6">
                <indicator-subtitle-h2 :value="data.shippingCost" :subtitle="'Fulfillment Cost / Order'" :colorClass="'blue2'"/>
            </vs-col>
            <vs-col vs-w="6">
                 <indicator-subtitle-h2 :value="data.avgZone" :subtitle="'Average Zone'"/>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment';

export default {
    data() {
        return {
            data: {
                totalShippingCost: 0,
                numberOrders: 857,
                shippingCost: '$ 14.32',
                avgZone: 4.5,
                currentDistributionDataset:[],
                currentDistributionLabels:[],
                currentDistributionOptions:{              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{display:false}, 
                    cutoutPercentage: 50 
                },
                currentDistributionAccotations: [],
                availableColors: ['green1', 'blue3', 'blue2', 'gray', 'gray2']
            },
            loading: false
        }
    },
    mounted() {
        this.fetch();
        this.$root.$on('update', this.fetch);
    },
    destroyed(){
        this.$root.$off('update', this.fetch);
    },
    methods: {
        fetch(){
            this.loading = true;
            this.data.currentDistributionDataset = [];
            this.data.currentDistributionLabels = [];
            this.data.currentDistributionAccotations = [];

            let currentDistributionConfig = {
				fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/distribution/current', currentDistributionConfig).then((response) => {
                // console.log(response);
                let dists = response.data.distributions;
                let i = 0;
                let datacdd = [];
                let datacdl = [];
                let dataColors = [];
                
                for(let item in dists) {
                    this.data.currentDistributionAccotations.push({
                        id: i,
                        title: Object.keys(dists)[i].toUpperCase(),
                        percentage: dists[Object.keys(dists)[i]],
                        colorClass: this.getColor(Object.keys(dists)[i].toUpperCase(), true)                        
                    });

                    datacdd.push(dists[Object.keys(dists)[i]]);
                    datacdl.push(Object.keys(dists)[i].toUpperCase());
                    dataColors.push(this.getColor(Object.keys(dists)[i].toUpperCase(), false));
                    i++;
                }

                this.data.totalShippingCost = Math.round(response.data.totalCost);
                this.data.numberOrders = response.data.numberOfOrders;
                this.data.shippingCost = '$ ' + response.data.costPerOrder;
                this.data.avgZone = response.data.averageZone;

                this.data.currentDistributionDataset = [{
                    data: datacdd,
                    backgroundColor: dataColors
                }];

                this.loading = false;
                this.data.currentDistributionLabels = datacdl;

                this.$store.commit('setCurrentDistNumOrder', this.data.numberOrders);
                this.$root.$emit("finishedsetCurrentDistNumOrder");

            });
        },
        getColor(key, flag) {
            let color = "";
            switch (key) {
                case 'CICERO (IL)': color = (flag) ? 'color-chi' : '#2470AF'; break;
                case 'BETHLEHEM (PA)': color = (flag) ? 'color-pa' : '#92EFAC'; break;
                case 'MORENO VALLEY (CA)': color = (flag) ? 'color-mv' : '#59BAEF'; break;
                case 'CHATTANOOGA (TN)': color = (flag) ? 'color-sf' : '#509B63'; break;
                case 'GRAPEVINE 1 (TX)': color = (flag) ? 'color-da' : '#343E4F'; break;
                case 'ST. PETERSBURG (FL)': color = (flag) ? 'color-pt' : '#F28482'; break;
                case 'GRAPEVINE 2 (TX)': color = (flag) ? 'color-da2' : '#F6BD60'; break;
                case 'OTTAWA (ON)': color = (flag) ? 'color-ott' : '#F6BD60'; break;
                case 'KILKENNY (IE)': color = (flag) ? 'color-kil' : '#84A59D'; break;
                case 'CARROLLTON (TX)': color = (flag) ? 'color-car' : '#E6AF2E'; break;
                case 'WOODRIDGE (IL)': color = (flag) ? 'color-wrg' : '#BEB7A4'; break;
                case 'EDISON (NJ)': color = (flag) ? 'color-nje' : '#16BAC5'; break;
                case 'TWIN LAKES (WI)': color = (flag) ? 'color-wi' : '#4E3D42'; break;
                case 'FELTHAM (UK)': color = (flag) ? 'color-flt' : '#6D6466'; break; 
                case 'LOUISVILLE (KY)': color = (flag) ? 'color-lsv' : '#c59630'; break; 
                case 'PHOENIX (AZ)': color = (flag) ? 'color-phx' : '#C9D5B5'; break;
                case 'BUDD LAKE (NJ)': color = (flag) ? 'color-bdl' : '#E3DBDB'; break;
                case 'BETHLEHEM 2 (PA)': color = (flag) ? 'color-pa2' : '#0B3C49'; break;
                case 'YORKVILLE (IL)': color = (flag) ? 'color-ykv' : '#731963'; break;
                default: color = (flag) ? 'gray2' : '#888a8e';
            }
            return color;
        }
    }
}
</script>

<template>
    <div>
		<div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
		<div v-else>
			<vs-row vs-type="flex" vs-justify="flex-end" class="mb-3">
				<indicator-subtitle signo="$" :value="data.summaryCartAmount.avg" subtitle="Avg Cart Amount"/>
			</vs-row>
			<shipping-method-item :item="item" v-for="item in data.topSellingData" :key="item.name" :max="data.maxShippingMethodSaleValue" />
		</div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {
    data() {
        return {
            data: {
                topSellingData: [],
				maxShippingMethodSaleValue:0,
				summaryCartAmount: [] 
			},
			loading: false
        }
    },
    mounted(){
		this.fetch();
		this.$root.$on('update', this.update);
	},	
    methods: {
		update(){
			this.fetch();
		},
        fetch() {
			this.loading = true;
			this.data.topSellingData = [];
			
            let configShippingMethod = {
				fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				// fromDate: '2019-01-01',
				// toDate: '2019-01-25',
				toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
				compare: this.$store.state.comparison
			}

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/shippingmethod/metrics', configShippingMethod).then((response) => {
				let responseData = response.data;
				let maxShippingMethodSaleValue = 0;
				let color = "";

				this.avgOrderAmount = responseData.summary.cartAmount.avg;
				this.avgOrderDelta = responseData.summary.cartAmount.delta;

				this.data.summaryCartAmount = responseData.summary.cartAmount;
				  
				responseData.shippingMethods.forEach((item, index) => {
					if(maxShippingMethodSaleValue <= Math.floor(item.percentageOfOrders * 100)) {
						maxShippingMethodSaleValue = Math.floor(item.percentageOfOrders * 100);
					}

					switch(index){
						case 0: color = '#59BAEF'; break;
						case 1: color = '#1f66a6'; break;
						case 2: color = '#00ff00'; break;
						case 3: color = '#bbbbbb'; break;
					}

					this.data.topSellingData.push({
						name: item.shippingMethod,
						percent: Math.floor(item.percentageOfOrders * 100),
						avgDaysTransit:item.averageDaysInTransit,
						avgShippingCost: item.AverageShippingCost,
						avgCartAmount: item.totalCartAmount.avg,
						color: color,
						maxCartAmount: item.totalCartAmount.max,
						minCartAmount: item.totalCartAmount.min,
						totalShipped: item.totalShipped
					});

					this.data.maxShippingMethodSaleValue = maxShippingMethodSaleValue;               	
					this.loading = false;
					this.$root.$emit('finishedFetch'); 
				});
	  		});
        }
    }
}
</script>


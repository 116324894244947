<template>
    <div>
        <vs-row vs-w="12" vs-justify="flex-start">
            <vs-col vs-w="12">
                <sku-velocity-search-bar :items1="data.items1" :items2="data.items2"/>
            </vs-col>
        </vs-row>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="12">
                    <vs-table search :max-items="maxItemsSku" maxHeight="230px" hoverFlat pagination :data="receivedData" class="table-Skuvelocity">
                        <template slot="thead">
                            <template v-for="item in data.skuVelocityColumns">
                                <vs-th  :key="item" :sort-key="item" v-if="item === 'Inventory ID'" class="table-Skuvelocity-id">{{item}}</vs-th>
                                <vs-th  :key="item" :sort-key="item" v-else-if="item === 'Item Name'" class="table-Skuvelocity-name">{{item}}</vs-th>
                                <vs-th  :key="item" :sort-key="item" v-else >{{item}}</vs-th>
                            </template>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr]['Inventory ID']" class="table-Skuvelocity-id">
                                    {{data[indextr]['Inventory ID']}}
                                </vs-td>
                                 <vs-td :data="data[indextr]['Item Name']" class="table-Skuvelocity-name">
                                    {{data[indextr]['Item Name']}}
                                </vs-td>
                                <vs-td :data="data[indextr][$store.state.skuVelocityDropdown1Value]">
                                    {{data[indextr][$store.state.skuVelocityDropdown1Value]}}
                                </vs-td>
                                <vs-td :data="data[indextr][$store.state.skuVelocityDropdown2Value]">
                                    {{data[indextr][$store.state.skuVelocityDropdown2Value]}}
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>
            <vs-row>
                <vs-col class="d-flex justify-content-end align-items-center">
                    <label class="mb-0" style="font-size: 12px" for="numbre">Show:  </label>
                    <input type="number" min="1" class="form-control p-1 ml-1" style="max-width: 45px" v-model="maxItemsAux" @change="changeMaxItems">
                </vs-col>
            </vs-row>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'
export default {    
    data() {
        return {
            maxItemsSku: 20,
            maxItemsAux: 20,
            receivedData: [],
            data: {
                items1: [
                    {text:'7 Days',value:'7days'},
                    {text:'30 Days',value:'30days'},
                    {text:'60 Days',value:'60days'},
                    {text:'90 Days',value:'90days'}
                ],
                items2: [
                    {text:'7 Days',value:'7days'},
                    {text:'30 Days',value:'30days'},
                    {text:'60 Days',value:'60days'},
                    {text:'90 Days',value:'90days'}
                ],
                skuVelocityColumns: [],
            },
            loading: false
        }
    },
    mounted(){
        this.fetch();
        this.$root.$on('update', this.fetch);
        this.$root.$on('updateSkuVelocity', this.fetch);
    },
    methods: {
         changeMaxItems() {
            if(this.maxItemsAux > 0){
                this.maxItemsSku = this.maxItemsAux;
            }
        },
        fetch() {
            this.loading = true;
            this.receivedData = [];
            this.data.skuVelocityColumns = ['Inventory ID', 'Item Name', this.$store.state.skuVelocityDropdown1Text, this.$store.state.skuVelocityDropdown2Text];
            let skuVelocityConfig = {
				firstTimeFrame: this.$store.state.skuVelocityDropdown1Value.replace('days', ''),
                secondTimeFrame: this.$store.state.skuVelocityDropdown2Value.replace('days', ''),
                includeB2B:this.$store.state.includeB2BSales,
                pageNumber:1,
                pageSize:10000
            }
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/velocity', skuVelocityConfig).then((response) => {
                let InventorySalesVelocity = response.data;
                let items = [];
                InventorySalesVelocity.items.forEach((item, index) => {
                    let dp1Key = this.$store.state.skuVelocityDropdown1Value;
                    let dp2Key = this.$store.state.skuVelocityDropdown2Value;
                    items.push({
                        "Inventory ID": item.id,
                        "Item Name": item.name,
                        [dp1Key]: item.unitsSoldAtFirstTimeFrame,
                        [dp2Key]: item.unitsSoldAtSecondTimeFrame
                    });
                });
                this.receivedData = items;
                this.loading = false;
                this.$store.commit('setDataSkuVelocity', this.receivedData);                
                this.$root.$emit('finishedFetch');
            });
        } 
    }
}
</script>
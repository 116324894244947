<template>
    <div> 
                    <sb-table
          :columnDefinitions="columnDefinitions"
          :rows="tableHelper.pagedData" 
          :loadingPromise="loadingPromise"
          @sort="handleSort"
          :defaultSortColumn="defaultSortColumn"
          :defaultSortOrdering="defaultSortOrdering" 
        >
        <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
               
        <h3 class="title"> Storage Billing Summary (Last 12 Months)<info style="margin-left:5px;" :paragraph="'This table provides a detailed line item of every storage fee that has been billed to your account. You can export it to excel and dive deep into any time period.'"></info></h3>
        <div class="search-wrapper"> 
      </div>
            <download-data-button :dataName="'dataStorageInvoices'"   ></download-data-button>
 
         
      </sb-toolbar> 
          <sb-table-row slot="sb-table-row" slot-scope="{row}"> 
               <sb-table-cell>
              {{dateFormat(row.invoiceDate)}}
            </sb-table-cell>
              <sb-table-cell>
              {{row.comment}}
            </sb-table-cell>
              <sb-table-cell>
              $ {{row.invoiceAmount}}
            </sb-table-cell> 
          </sb-table-row>
          <sb-table-empty-state
            slot="sb-table-empty-state"
            icon-name="products"
            label="No Storage Invoices"
          >
            <p>Once you are invoiced for storage you will see the summary here. </p>
          </sb-table-empty-state> 
            <pagination-wrapper
            slot="pagination"
            @fetchPage="handleFetchPage"
            :pageSize="tableHelper.pagination.pageSize"
            :currentPage="tableHelper.pagination.currentPage"
            :totalPages="tableHelper.pagination.totalPages"
            :totalRecords="tableHelper.pagination.totalRecords"
            :visibleRecords="tableHelper.pagination.visibleRecords"
          ></pagination-wrapper>
        </sb-table> 
             
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'
import tabler from "@/utils/tablehelper"; 
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
    components: {
    "pagination-wrapper": PaginationWrapper
  },
    data() {
        return {  
            loadingPromise: null,
            columnDefinitions: [ 
                { name: "Invoice Date", field: "invoiceDate", sortable: true},
                { name: "Comment", field: "comment" },
                { name: "Invoice Amount", field: "invoiceAmount", sortable: true }                
            ], 
            tableHelper: new tabler(this,12)
        }
    }, 
     mounted() {
     this.loadingPromise = this.fetch(1);
  },
   computed: {
     defaultSortColumn () {
      return null;
    },
    defaultSortOrdering () {
      return null;
    } 
  },
    methods: {
        handleSort ({ sortColumn, descending }) {
            this.tableHelper.handleSort({ sortColumn:sortColumn, descending:descending });
        }, 
        map(item)
        { 
            return { 
                 invoiceDate : this.dateFormat(item.invoiceDate),
                 comment : item.comment,
                 invoiceAmount : this.numberWithCommas(item.invoiceAmount.toFixed(2))
            };  
        },
        dateFormat(date) {
            return moment(new Date(date)).format('MM/DD/YYYY')
        },
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        handleFetchPage(p) {
            this.loadingPromise = this.tableHelper.loadData(this.tableHelper.receivedData, p.page);
        },
        fetch: async function(p) {
            await new Promise(res => { 
            
            this.tableHelper.preLoadReset();

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'invoices/history').then((response) => {
                  
                let storage = response.data.data;
                this.tableHelper.loadData(storage, p);   
 
                this.$store.commit('setDataStorageInvoices', this.tableHelper.receivedData); 
               // console.log(this.tableHelper.receivedData);
                this.$root.$emit('finishedFetch');
                 res(true);
            });
            
         
      });
        }
    }
}
</script>

 <style scoped lang='scss'> 

.customers-toolbar {
  padding-left: 0px;
}  
</style>
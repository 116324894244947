// The current key names for our auth token data
const TOKEN_KEYS = ['accesstoken', 'expiresin', 'refreshtoken']

export default class AuthTokenUtil {
  static persistAuth (tokens) {
    
    if (typeof tokens === 'object' && Object.keys(tokens).length === 0) return
    window.localStorage.setItem('auth', JSON.stringify(tokens))
  }

  static extractFromHeader (headers) {
    return TOKEN_KEYS.reduce((acc, key) => {
      let header = headers[key]
      if (header) acc[key] = header
      return acc
    }, {})
  }

  static extractAuth () {
    try {
      return JSON.parse(window.localStorage.getItem('auth')) || {}
    } catch (e) {
      window.localStorage.removeItem('auth')
      return {}
    }
  }
}

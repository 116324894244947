<template>
    <div>
        <vs-row vs-w="12" vs-justify="flex-start">
            <vs-col vs-w="12">
                <inventory-summary-search-bar :value="0" :salesItems="salesItems" :basedOnItems="basedOnItems"></inventory-summary-search-bar>
            </vs-col>
        </vs-row>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        
        <div v-else style="padding-top:35px;">
            
            <vs-row class="mt-5">
                <vs-col vs-w="6" vs-justify="flex-start" >
                    <vs-row vs-align="flex-end">
                         <vs-col vs-w="3">
                            <indicator-subtitle-h2 :value="this.data.allFcs" :colorClass="'blue3'" subtitle="Currently On Hand"/>
                        </vs-col>
                        <vs-col vs-w="3">
                            <indicator-subtitle-h2 :value="data.unitsSold" :colorClass="'blue3'" subtitle="Avg Units Sold per day"/>
                        </vs-col>
                        <vs-col vs-w="3">
                            <indicator-subtitle-h2 :value="data.daysInventory" :colorClass="'blue3'" subtitle="Days of Inventory Left"/>
                        </vs-col>
                        <vs-col vs-w="3">
                            <indicator-subtitle-date :value="data.dateExpected" :colorClass="'blue3'" subtitle="Expected Stockout Date"/>
                        </vs-col>
                    </vs-row>
                </vs-col>
                <vs-col vs-w="6" >
                    <div class="d-flex align-items-center">
                        <span class="horizontal-barchart-title">FC distribution:</span> 
                    </div>
                    <stacked-horizontal-bar-chart :labels="dataBarChart.labels" :data="dataBarChart.dataset" :options="dataBarChart.options"/>
                </vs-col>
            </vs-row>
            <vs-divider></vs-divider>
            <vs-row vs-w="12" vs-justify="flex-start" vs-align="center">
                <vs-col vs-w="12" vs-justify="center" vs-align="center">
                    <h2 class="title-dates text-center my-0">Historical End of Day Inventory Across All Fulfillment Centers</h2>
                    <h3 class="h3 text-center gray2 mt-0">{{this.selectedDates}}</h3>
                </vs-col>
            </vs-row>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" vs-sm="3" vs-xs="2" v-if="dataLineChart.dataset.length==0">
                    
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" vs-sm="3" vs-xs="2" v-else-if="dataLineChart.dataset.length!=0">
                    <span style="margin-right:5px">FROM</span>
                    <datepicker class="input-span-placeholder vs-input--placeholder normal vs-input" v-model="from" :format="customFormatter" @closed="setFrom"></datepicker>
                    <i class="far fa-calendar-alt date-icon"></i>
                </vs-col>
            </vs-row>
            <vs-row vs-w="12" vs-justify="center" vs-align="center" class="mt-2" >
                <vs-col vs-w="12">
                    <sb-panel variation="warning"   v-if="dataLineChart.dataset.length==0">
                        <p   >No data found for the timeframe. Select a different SKU.</p>
                    </sb-panel>
                    <line-chart :labels="dataLineChart.labels" :data="dataLineChart.dataset" :options="dataLineChart.options" v-else-if="dataLineChart.dataset.length!=0"/>
                </vs-col>
            </vs-row>
        </div>
        
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'

export default {    
    data() {
        return {
            loading: false,
            from: '',
            salesItems: [],
            selectedDates: '',
            basedOnItems: [
                {text:'7 Days',value:'7'},
                {text:'30 Days',value:'30'},
                {text:'60 Days',value:'60'},
                {text:'90 Days',value:'90'},
            ],
            data: {
                unitsSold: 0,
                daysInventory: 0,
                dateExpected: '',
                allFcs: 0,
                onHand: 0,
            },
            dataBarChart:{
                dataset:[],
                labels: [],
                options:{
                    responsive: true, 
                    maintainAspectRatio: false, 
                    legend:{
                        position:'bottom',
                        labels: {
                            boxWidth: 12
                        }
                    },
                    tooltips: {
                        mode: 'point'
                    },
                    scales: {
                        yAxes: [{
                            stacked: true,
                            display: false,
                            maxBarThickness: 20,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                        xAxes: [{
                            stacked: true,
                            display: false,
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                color: "#ffffff",
                            }
                        }]
                    }
              
                    
                },
            },
            dataLineChart:{
                dataset: [],
                labels:[],
                options: {              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{position:'bottom'},
                    scales: {
                        xAxes: [{
                            display: true
                        }],
                        yAxes: [{
                            gridLines: {
                                color: "#f6f6f6",
                            },
                            ticks: {
                                beginAtZero: true,
                                min: 0
                            }
                        }]
                    },
                    
                    
                },
            }
        }
    },
    mounted(){
        let date = new Date();        
        date = this.customFormatter(date.setDate(date.getDate() -  61));
        this.from = this.customFormatter(date);
        this.$store.commit("setFrom60", this.customFormatter(new Date(date)));

        this.fetch();
        this.$root.$on('updateInventorySummary', this.fetch);
        this.getSelectedDates();
        this.$root.$on('updateInventorySummary', this.getSelectedDates);

        let inventoryItemsConfig = {
            IsActive:true,
                IsMerged:false,
                IsBundle:false
        };
        if(this.$store.state.salesItems.length === 0){
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/', inventoryItemsConfig).then((response) => {
                response.data.data.forEach((item) => {
                    this.salesItems.push({ text: item.name, value: item.inventoryId})
                });
                this.$store.commit('setSalesItems', response.data.data);
            });
        } else {
            let salesItems = this.$store.state.salesItems;
            salesItems.forEach((item) => {
                this.salesItems.push({ text: item.name, value: item.inventoryId})
            });
        }

    },
    destroyed(){
        this.$root.$off('updateInventorySummary', this.fetch);
    },
    methods: {
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
         dateFormat(date) {
            return moment(new Date(date)).format('MM/DD/YYYY')
        },
        setFrom(){
            let date = new Date(this.from);
            let from = this.customFormatter(date.setDate(date.getDate()));
            this.$store.commit("setFrom60", this.customFormatter(new Date(from)));
        },
        getSelectedDates(){
            let toDate = new Date();
            toDate.setDate(toDate.getDate() - 1);

            let config = {
                fromDate: moment(new Date(this.$store.state.from_60)).format('MM/DD/YYYY'),
				toDate: moment(toDate-1).format('MM/DD/YYYY')
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/sum/0', config).then((response) => {
                let data = response.data.data;
                this.selectedDates= data.selectedDates;
            });
        },
        fetch() {
            this.dataBarChart.dataset = [];
            this.dataLineChart.labels = [];
            this.dataLineChart.dataset = [];

            let idInventory = this.$store.state.sales_itemIDInventorySummary;
            // let idInventory = 1;

            if(idInventory !==  undefined){
                this.loading = true;

                axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/'+idInventory+'/sales/velocity').then((response) => {
                    let InventorySalesVelocity = response.data;
                        //console.log(InventorySalesVelocity);
    
                    // let items = InventorySalesVelocity.data.filter(item => item.id === idInventory);
                    let items =  InventorySalesVelocity.data;
                    if(items==undefined){
                        this.loading = false;
                        return;
                    }
                    let days = 30;

                    let opc = this.$store.state.di_timeInventorySummary;
                    switch (opc) {
                        case '7': days =  items['last7Days_AvgUnitSoldperDay']; break;
                        case '30': days = items['last30Days_AvgUnitSoldperDay']; break;
                        case '60': days = items['last60Days_AvgUnitSoldperDay']; break;
                        case '90': days = items['last90Days_AvgUnitSoldperDay']; break;
                        default:  days = items['last30Days_AvgUnitSoldperDay'];
                    }
                    let percent = parseInt(this.$store.state.percent_inventorySummary, 10)/100;
                    this.data.unitsSold = (days * (1 + percent)).toFixed(1);
                    this.data.onHand = items.onhand;


                    // Code for Horizontal Bar Chart
                    let onHandConfig = {
                        pageNumber:1,
                        pageSize:10000
                    }
                
                    axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/quantity', onHandConfig).then((response) => {
                        let OnHand = response.data;
                        
                        let inventories = OnHand.inventories.filter(item => item.inventoryid === idInventory);

                        if(inventories.length > 0){
                            //console.log(this.$store.state.percent_inventorySummary)
                            this.createHorizontalBarChart(inventories);

                            this.data.allFcs = inventories[0]['allShipbobFcsInteralTransfers'];
                            
                            // this.data.daysInventory =  parseInt(this.data.allFcs / this.data.unitsSold,10);

                            let percent = parseInt(this.$store.state.percent_inventorySummary, 10)/100;
                            this.data.daysInventory = Math.round(this.data.onHand /( days * (1 + percent) ));

                            let date = new Date();
                            this.data.dateExpected = this.customFormatter(date.setDate(date.getDate() + this.data.daysInventory));
                            
                            
                            //Code for line chart
                            let toDate = new Date();
                            toDate.setDate(toDate.getDate() - 1);
                            let inventoryHistoryItemConfig = {
                                fromDate: moment(new Date(this.$store.state.from_60)).format('MM/DD/YYYY'),
                                toDate: moment(toDate-1).format('MM/DD/YYYY'),
                                itemId: idInventory,
                                pageNumber:1,
                                pageSize:10000,
                                internal: true,
                                external: true
                            }
                            
                            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/' + idInventory + '/history/', inventoryHistoryItemConfig).then((response) => {
                                let InventoryHistoryItems = response.data.data;
                                this.createLineChart(InventoryHistoryItems);

                                this.loading = false;
                                this.$root.$emit('finishedFetch');               

                            });
                        }else
                        {
                            this.loading = false;
                            this.$root.$emit('finishedFetch');               
                        }
                    });
                    
                });
            }
        },
        createHorizontalBarChart (inventories) {
            this.dataBarChart.dataset.push({
                label: 'CHI',
                data: [inventories[0]['chi']],
                backgroundColor: [
                    '#2470AF',
                ],
                borderColor: 'transparent',
            });

            this.dataBarChart.dataset.push({
                label: 'NY/PA',
                data: [inventories[0]['nypa']],
                backgroundColor: [
                    '#92EFAC',
                ],
                borderColor: 'transparent',
            });
            
            this.dataBarChart.dataset.push({
                label: 'LA/MV',
                data: [inventories[0]['lamv']],
                backgroundColor: [
                    '#59BAEF',
                ],
                borderColor: 'transparent',
            });
            
            this.dataBarChart.dataset.push({
                label: 'SF',
                data: [inventories[0]['sf']],
                backgroundColor: [
                    '#509B63',
                ],
                borderColor: 'transparent',
            });

            this.dataBarChart.dataset.push({
                label: 'DA',
                data: [inventories[0]['da']],
                backgroundColor: [
                    '#343E4F',
                ],
                borderColor: 'transparent',
            });

            this.dataBarChart.dataset.push({
                label: 'CHA',
                data: [inventories[0]['cha']],
                backgroundColor: [
                    '#343E4F',
                ],
                borderColor: 'transparent',
            });

            this.dataBarChart.dataset.push({
                label: 'STP',
                data: [inventories[0]['stp']],
                backgroundColor: [
                    '#343E4F',
                ],
                borderColor: 'transparent',
            });

            this.dataBarChart.dataset.push({
                label: 'In Transit',
                data: [inventories[0]['inInternalTransfer']],
                backgroundColor: [
                    '#888a8e',
                ],
                borderColor: 'transparent',
            });
        },
        createLineChart(InventoryHistoryItems){
            let qty = [];
            let qty2 = [];
            let qty3 = [];
            
            let qty2Value = parseInt(this.data.unitsSold * 30, 10);
            let qty3Value = parseInt(this.data.unitsSold * 14, 10);
            InventoryHistoryItems.forEach((item, index) => {
                this.dataLineChart.labels.push(this.dateFormat(item.snapshotdate));
                qty.push(item.allShipbbFCsExcludingTransfers);
                qty2.push(qty2Value);
                qty3.push(qty3Value);
            });

            this.dataLineChart.dataset.push({
                label: 'Historical Inventory Level',
                backgroundColor: '#59BAEF',
                borderColor: '#59BAEF',
                pointStyle: 'line',
                fill:false,
                tension:0,
                data: qty
            });

            this.dataLineChart.dataset.push({
                label: 'One month of supply remaining',
                backgroundColor: '#88eca3',
                borderColor: '#88eca3',
                pointStyle: 'line',
                radius: 5,
                showLine: false,
                fill:false,
                tension:0,
                data: qty2
            });

            this.dataLineChart.dataset.push({
                label: 'Two weeks of supply remaining',
                backgroundColor: '#2165a5',
                borderColor: '#2165a5',
                pointStyle: 'line',
                radius: 5,
                showLine: false,
                fill:false,
                tension:0,
                data: qty3
            });
        }
    }
}
</script>

<template>
    <div>
        <vs-row v-for="item in mainData" :key="item.name" vs-w="12" vs-align="center" vs-justify="center">
            <vs-col vs-align="center" vs-justify="flex-start" vs-w="6" vs-offset="2"><p>{{item.name}}</p></vs-col>
            <vs-col vs-align="center" vs-justify="center" vs-w="4"><h2>{{item.value}}</h2></vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['mainData'],
    mounted() {
        
    }
}
</script>


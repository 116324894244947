<template>
    <div :class="{ 'sticky' : kind !== 'mapSearchBar'}">
        <div class="row">
            <div class="col-12 p-0">
                <b-form inline class="search-bar d-flex justify-content-start">            
                    <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                        <date-filter></date-filter>
                    </b-input-group>
                    <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0">
                        <comparison-filter :items="items" :selected="'PreviousPeriod'" :caption="'COMPARED TO'" v-if="kind === undefined"></comparison-filter>
                    </b-input-group>
                    <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0" v-if="additionals == 'inventoryMetrics'">
                        <vs-checkbox v-model="checkBox1" @change="toggleCheck()"> Include B2B sales</vs-checkbox>
                    </b-input-group>
                    <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0 search-bar-btn" v-if="kind === 'mapSearchBar'">
                        <sb-button @click="update_map"  variation="primary"   size="medium" @click.prevent="" >Apply</sb-button>                        
                    </b-input-group>
                    <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0 search-bar-btn" v-else>
                        <vs-button color="primary" type="button" @click="update" class="ld-ext-right">Apply</vs-button>
                    </b-input-group>
                    <b-input-group left="@" class="mb-2 mr-sm-2 mb-sm-0" v-if="this.showPreviuosDate && (kind !=='mapSearchBar' && kind !=='orderTurnAroundTimeSearchBar')">
                        <label style="margin-left:10px">{{previousDate()}}</label>
                    </b-input-group>
                </b-form>
            </div>
            <!-- <div class="col-3 p-0">
            </div> -->
        </div>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    props:['additionals', 'kind'],
    data() {
        return {
            checkBox1:false,
            items:[
				{text:'Previous period',value:'PreviousPeriod'},
				{text:'Previous year',value:'PreviousYear'},
            ],
            loading:false,
            showPreviuosDate: false
        }
    },
    mounted() {
        this.$root.$on('finishedFetch', () => { this.loading = false; });
    },
    methods:{        
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        update(){
            this.loading = true;
            this.showPreviuosDate =  true;
            this.$root.$emit('update');
        },
        update_map(){
            this.loading = true;
            this.showPreviuosDate =  true;
            this.$root.$emit('update_map');
        },
        toggleCheck() {
            this.checkbox1 = !this.checkbox1;
            this.$store.commit("setinclude_b2b_sales", this.checkBox1);
        },
        previousDate(){
            let text ="";
            if(this.$store.state.comparison === "PreviousPeriod") {
                let date1 = moment(this.$store.state.from);
                let date2 = moment(this.$store.state.to);

                let daysPeriod = date2.diff(date1, 'd');
                date1.subtract(1, 'd');
                date2 = moment(this.$store.state.from);
                date2.subtract(daysPeriod+1, 'd');
                text = `Compare to: ${date2.format('MM/DD/YYYY')} to ${date1.format('MM/DD/YYYY')}`;
            }
            if(this.$store.state.comparison === "PreviousYear") {
                let date1 = moment(this.$store.state.from).subtract(1, 'y');
                let date2 = moment(this.$store.state.to).subtract(1, 'y');
                text = `Compare to: ${date1.format('MM/DD/YYYY')} to ${date2.format('MM/DD/YYYY')}`;
            }
            

            return text;
        }
    }
}
</script>


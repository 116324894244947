<template>
   <div class="main-content">
          <div class="header-container">
        <base-header :heading="'Undelivered Shipments'" :description="'View a list of orders that have been shipped but not yet delivered to the end customer for longer than the anticipated number of days. Unfortunately, at times our partner carriers missort, lose a shipment, misscanned or delivered without a scan. This report may help identify those potential orders that already left a ShipBob fulfillment center by cross-referencing the Order ID hyperlink with the Tracking ID hyperlink. Then you can further investigate the deliverability issue. For orders that you’ve reviewed and don’t require further investigation, you can check its box and click “Mark as Reviewed.” Note: This only removes orders from this Analytics dashboard and does NOT change anything in your main ShipBob dashboard.'" slot="header"> 
          <div slot="action"> 
            <div class="btn-group btn-group-nav" role="group">
              <div class="btn-group btn-group-nav" role="group">
            <download-data-button :dataName="'dataAgeOrders'"   ></download-data-button>

                   
              </div>
            </div>
          </div>
        </base-header>
      </div> 
                <age-ordersv2 :title="'AGE OF ORDERS THAT HAVEN\'T BEEN DELIVERED'" 
                    :kindTemplate="'age-orders'"					
					:tooltipPosition="'right'"
                     />  
    </div>
</template>
 <script> 
export default {
      
data() {
    return { 
    };
  },
  mounted() {
   
  },
  methods: {
    getDate() {
      var options1 = { year: "numeric", month: "short", day: "numeric" };
      var options2 = { hour: "numeric", minute: "numeric" };

      return (
        "Last Updated: " +
        new Date().toLocaleDateString("en-US", options1) +
        " at " +
        new Date().toLocaleTimeString("en-US", options2)
      );
    }
  }
};
</script>
 
 <style scoped lang='scss'>
@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss";
@import "@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss";
//@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard-panel-template.scss";
.main-content {
  background-color: #fff;
}
</style>
<template>
    <!-- <vs-row vs-w="12" vs-justify="center" vs-align="center">
        <vs-col vs-w="4">
            <p>{{title}}</p>
        </vs-col>
        <vs-col vs-w="8">
            <vs-col vs-w="7" vs-align="center" vs-justify="center">
                <h2 class="centered blue1" v-if="colorClass=='blue1'">{{numberWithCommas(value)}}</h2>
                <h2 class="centered blue2" v-else-if="colorClass=='blue2'">{{numberWithCommas(value)}}</h2>
                <h2 class="centered" v-else>{{value}}</h2>
            </vs-col>
            <vs-col vs-w="5" vs-justify="flex-start">
                <sup-breadcrumb :delta="delta" v-if="delta != ''"></sup-breadcrumb>
            </vs-col>
        </vs-col>
    </vs-row> -->
    <div class="row">
        <div class="col-10 offset-1">
            <div class="row align-items-center">
                <div class="col-5 p-0 text-left">
                    <p style="font-size: 11px">{{title}}</p>
                </div>
                <div class="col-5 p-0">
                    <h2 class="centered blue1" v-if="colorClass=='blue1'">{{numberWithCommas(value)}}</h2>
                    <h2 class="centered blue2" v-else-if="colorClass=='blue2'">{{numberWithCommas(value)}}</h2>
                    <h2 class="centered" v-else>{{value}}</h2>
                </div>
                <div class="col-2 p-0 align-self-start">
                    <sup-breadcrumb :delta="delta" v-if="delta != ''"></sup-breadcrumb>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['title', 'value', 'delta', 'colorClass'],
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
}
</script>


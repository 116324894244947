<template>
     <div v-if="this.loading" class="spin-center-ideal-distribution center">
        <div class="ld ld-ring ld-spin running"></div>
    </div>
    <div   v-else>
        <p style="padding: 0px 15px"> </p>
        <vs-row vs-justify="center">
            <vs-col vs-w="12">
                <doughnut-chart :data="data.currentMonthDateDataset" :options="data.currentMonthDateOptions" :labels="data.currentMonthDateLabels"></doughnut-chart>
            </vs-col>
        </vs-row>
        <vs-row class="accotation-container">
            <vs-col vs-offset="2" vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
                <accotation v-for="item in data.currentMonthDateAccotations" :title="item.title" :percentage="item.percentage" :showPercent="true" :key="item.id" :color="item.colorClass"></accotation>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment';

export default {
    data() {
        return {
            data: {
                currentMonthDateDataset:[],
                currentMonthDateLabels:[],
                currentMonthDateOptions:{ 
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{display:false}, 
                    cutoutPercentage: 80 
                },
                currentMonthDateAccotations: [],
            },
            loading: false
        }
    },
    mounted() {
        // this.fetch();
        // this.$root.$on('update', this.fetch);
    },
    methods: {
        fetch(){
            this.loading = true;
            this.data.currentMonthDateDataset = [];
            this.data.currentMonthDateLabels = [];
            this.data.currentMonthDateAccotations = [];

            // axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'Cost/BreakDown').then((response) => {
            //     let dists = response.data.data[0];
            //     let datacdl = ['Fulfillment', 'Storage', 'Other'];

            //     let fulfillment = Math.round((dists.fulfillment + dists.pickFees).toFixed(2));
            //     let storage = Math.round((dists.storage).toFixed(2));
            //     let other = Math.round((dists.receiving + dists.returns + dists.freightPrepaidB2B + dists.serialScan + dists.kitting + dists.other).toFixed(2));
                
            //     this.data.currentMonthDateAccotations.push({
            //         id: 0,
            //         title: "Fulfillment",
            //         percentage: fulfillment,
            //         colorClass: this.getColor("Fulfillment", true)                        
            //     });

            //     this.data.currentMonthDateAccotations.push({
            //         id: 1,
            //         title: "Storage",
            //         percentage: storage,
            //         colorClass: this.getColor("Storage", true)                        
            //     });

            //     this.data.currentMonthDateAccotations.push({
            //         id: 2,
            //         title: "Other",
            //         percentage: other,
            //         colorClass: this.getColor("Other", true)                        
            //     });


            //     this.data.currentMonthDateDataset = [{
            //         data: [fulfillment, storage, other],
            //         backgroundColor: ['#59BAEF', '#92EFAC', '#2470AF']
            //     }];

            //     this.loading = false;
            //     this.data.currentMonthDateLabels = datacdl;
            // });
        },
        getColor(key, flag) {
            let color = "";
            switch (key) {
                case 'Fulfillment': color = (flag) ? 'color-fulfillment' : '#59BAEF'; break;
                case 'Storage': color = (flag) ? 'color-storage' : '#92EFAC'; break;
                case 'Other': color = (flag) ? 'color-other' : '#2470AF'; break;
                default: color = (flag) ? 'gray2' : '#888a8e';
            }
            return color;
        }
    }
}
</script>

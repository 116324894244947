<template>
    <div>
        <vs-row vs-w="12">
            <vs-col vs-w="12" vs-justify="flex-start">
             <div class="d-flex justify-content-around mt-2">
                <h6 class="colorClass h6"   style="text-align:center" ><span v-if="simbol !== 'undefined'">{{simbol}}</span>{{numberWithCommas(min)}}</h6> 
                <h6 class="colorClass h6"  style="text-align:center"  >&nbsp;-&nbsp;</h6> 
                <!-- <h6 class="right barely-visible mb-0 h6" vs-justify="flex-end" :color="item.color">{{item.percent}}% <span class="h6-Color">of {{item.totalShipped}} Orders</span></h6> -->
                <h6 class="colorClass h6"  style="text-align:center"  ><span v-if="simbol !== 'undefined'">{{simbol}}</span>{{numberWithCommas(max)}}</h6>
                
            </div>
                
                <div class="h2-sub" style="text-align:center">{{subtitle}}</div>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['min', 'max',  'subtitle', 'simbol', 'colorClass'],
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
}
</script>
<style scoped lang='scss'>
 
 .h6{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
 }
 .justify-content-around {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: center !important; 
}

</style>


<template>
    <div>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else>
            <vs-row vs-w="10" vs-justify="center" vs-align="center"  >
                <vs-col vs-w="10">
                    <table class="table-StorageCost">
                        <thead>
                            <tr>
                                 <th  >
                                    <h2 class="title-dates py-1"> </h2>
                                </th>
                                <th v-for="item in tableDataUnitCost" :key="item.month">
                                    <h2 class="title-dates py-1">{{dateFormatEspecial(item.month)}}</h2>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                 <td style="text-align: right">
                                <h2 class="title-dates gray2">{{this.avgunitsonhand_Title}}</h2>
                                  </td>
                                  <td style="border-left" :key="idx" v-for="(item, idx) in tableDataUnitCost">
                                    <h2 class="title-dates primary">{{numberWithCommas(Math.round(item.avgInventoryOnHand))}}</h2>
                                </td>
                                  
                            </tr>
                            <tr>
                                 <td style="text-align: right">
                                <h2 class="title-dates gray2">{{this.monthlyStorageCost_Title}}</h2>
                                  </td> 
                                  <td style="border-left" :key="idx" v-for="(item, idx) in tableDataUnitCost">
                                    <h2 class="title-dates primary">{{'$ '+ numberWithCommas(Math.round(item.monthlyStorageCost))}}</h2>
                                </td>
                            </tr>
                             <tr>
                                 <td style="text-align: right">
                                <h2 class="title-dates gray2">{{this.avgPerUnitStored_Title}}</h2>
                                  </td> 
                                  <td style="border-left" :key="idx" v-for="(item, idx) in tableDataUnitCost">
                                    <h2 class="title-dates primary">{{'$ '+ numberWithCommas(item.avgPerUnitStored)}}</h2>
                                </td>
                            </tr> 
                        </tbody>
                    </table>
                </vs-col>
            </vs-row>
            <vs-divider></vs-divider>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="12">
                    <header class="vs-card-header">
                        <title-component str="Historical Units On Hand" dataName="dataStorageHistorical"/>
                    </header>
                </vs-col>
            </vs-row>
            <vs-row vs-w="12" vs-justify="center" vs-align="center">
                <vs-col vs-w="12">
                    <line-chart :labels="data.labels" :data="data.dataset" :options="data.options"/>
                </vs-col>
            </vs-row>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment'
export default {
    data() {
        return {
            avgunitsonhand_Title : 'Avg units on hand',
            monthlyStorageCost_Title : 'Warehousing cost',
            avgPerUnitStored_Title : 'Avg cost per unit stored',
            receivedData: [],
            tableDataUnitCost: [],
            table: {
                column: [],
                data: []
            },
            data: {
                labels: [],
                dataset: [],
                options: {              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{
                        display: true,
                        position:'top'
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            type: 'time',
                            time: {
                                unit: 'day',
                                displayFormats: {
                                    day: 'MM-DD-YYYY'
                                },
                              stepSize:7  
                            },
                            distribution: 'series'
                        }],
                        yAxes: [{
                            gridLines: {
                                color: "#f6f6f6",
                            },
                            stacked: true
                        }]
                    }
                }
            },
            loading: false
        }
    },
    mounted() {
        this.fetch();
        // this.$root.$on('update', this.fetch);
    },
    methods: {
        dateFormat(date) {
            return moment(new Date(date)).format('MM-DD-YYYY')
        },
        dateFormatEspecial(date) {
            return moment(new Date(date)).format('MMM-YY')
        },
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        fetch() {
            this.loading = true;
            this.table.column = [];
            this.table.data = [];
            
            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'cost/avgstoragecostperunit').then((response) => {
                let data = response.data.data;
                
                let Avgunitsonhand_Title = 'Avg units on hand';

                let columns = [''];
                let avgInventoryOnHand = [this.avgunitsonhand_Title];
                let monthlyStorageCost= [this.monthlyStorageCost_Title];
                let avgPerUnitStored= [this.avgPerUnitStored_Title];

                let col = [''];
                let avgInv= [Avgunitsonhand_Title];
                let month= [this.monthlyStorageCost_Title];
                let avgPer= [this.avgPerUnitStored_Title];

                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    columns.push(this.dateFormatEspecial(element.month));

                    avgInventoryOnHand.push(this.numberWithCommas(Math.round(element.avgInventoryOnHand)));
                    monthlyStorageCost.push('$ '+this.numberWithCommas(Math.round(element.monthlyStorageCost)));
                    avgPerUnitStored.push('$ '+ this.numberWithCommas(element.avgPerUnitStored.toFixed(2)));

                    col.push(this.dateFormatEspecial(element.month));
                    avgInv.push(this.numberWithCommas(Math.round(element.avgInventoryOnHand)));
                    month.push('$ '+this.numberWithCommas(Math.round(element.monthlyStorageCost)));
                    avgPer.push('$ '+this.numberWithCommas(element.avgPerUnitStored));
                }
                // this.table.column = (columns.splice(6,11));
                // this.table.column.splice(0, 0, '');                             
                //this.table.data.push(Avgunitsonhand_Title);   
                //console.log(avgInventoryOnHand.splice(6,11));

                //this.table.data.push(avgInventoryOnHand.splice(6,11));     
                // this.table.data.push(monthlyStorageCost.splice(0,1));           
                // this.table.data.push(monthlyStorageCost.splice(6,11));
                // this.table.data.push(avgPerUnitStored.splice(0,1)); 
                // this.table.data.push(avgPerUnitStored.splice(6,11));
                //clone
                this.receivedData = data.map((x) => x);
                var numberToRemove = data.length-6;//we only want to show the last 6 months, but keep all 12 months for the download button
               
                if(numberToRemove>0)
                { 
                    for (let i = 0; i < numberToRemove; i++) {
                        data.shift();
                    } 
                }             
               this.tableDataUnitCost = data;//.slice(5,11);   

                //let receivedData = [col, avgInv, avgPer, month];
                this.$store.commit('setDataStorageCost', this.receivedData);
                this.loading = false;
                this.$root.$emit('finishedFetch');
            });


            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/history').then((response) => {
                /** CHART DATASETS **/
                let dataset = response.data;

                let qty = [];
				let qty2 = [];
				let qty3 = [];
				let qty4 = [];
                let qty5 = [];
                let qty6 = [];
				let qty7 = [];

                let receivedData = [];
				dataset.data.forEach((item) => {
					this.data.labels.push(this.dateFormat(item.date));
					qty.push(item.chI_TotalDailyQuantity);
					qty2.push(item.nypA_TotalDailyQuantity);
					qty3.push(item.lamV_TotalDailyQuantity);
					qty4.push(item.sF_TotalDailyQuantity);
                    qty5.push(item.dA_TotalDailyQuantity);
                    qty6.push(item.chA_TotalDailyQuantity);
                    qty7.push(item.stP_TotalDailyQuantity);
                    
                    receivedData.push({
                        'CHI': item.chI_TotalDailyQuantity,
                        'NY/PA': item.nypA_TotalDailyQuantity,
                        'LA/MV': item.lamV_TotalDailyQuantity,
                        'SF': item.sF_TotalDailyQuantity,
                        'DA': item.dA_TotalDailyQuantity,
                        'CHA': item.chA_TotalDailyQuantity,
                        'STP': item.stP_TotalDailyQuantity,

                    });
				});

				this.data.dataset.push({
                    label: 'CHI',
					backgroundColor: '#B2DFF8',
                    borderColor: '#59BAEF', 
                    borderWidth: 1,
                    pointRadius: '0', 
                    pointBackgroundColor: '#390619',
					fill:true,
					tension:0,
					data: qty
                });
                
                this.data.dataset.push({
                    label: 'NY/PA',
					backgroundColor: '#96B8D5',
                    borderColor: '#1F66A6', 
                    borderWidth: 1,
                    pointRadius: '0', 
                    pointBackgroundColor: '#390619',
					fill:true,
					tension:0,
					data: qty2
                });
                
                this.data.dataset.push({
                    label: 'LA/MV',
					backgroundColor: '#CCF8D8',
                    borderColor: '#92EFAC', 
                    borderWidth: 1,
                    pointRadius: '0', 
                    pointBackgroundColor: '#390619',
					fill:true,
					tension:0,
					data: qty3
                });
                
                this.data.dataset.push({
                    label: 'SF',
					backgroundColor: '#94B8FE',
                    borderColor: '#1A66FD', 
                    borderWidth: 1,
                    pointRadius: '0', 
                    pointBackgroundColor: '#390619',
					fill:true,
					tension:0,
					data: qty4
                });
                
                this.data.dataset.push({
                    label: 'DA',
					backgroundColor: '#DADADA',
                    borderColor: '#645C5C', 
                    borderWidth: 1,
                    pointRadius: '0', 
                    pointBackgroundColor: '#390619',
					fill:true,
					tension:0,
					data: qty5
                });

                this.data.dataset.push({
                    label: 'CHA',
					backgroundColor: '#DADADA',
                    borderColor: '#645C5C', 
                    borderWidth: 1,
                    pointRadius: '0', 
                    pointBackgroundColor: '#390619',
					fill:true,
					tension:0,
					data: qty6
                });

                this.data.dataset.push({
                    label: 'STP',
					backgroundColor: '#DADADA',
                    borderColor: '#645C5C', 
                    borderWidth: 1,
                    pointRadius: '0', 
                    pointBackgroundColor: '#390619',
					fill:true,
					tension:0,
					data: qty7
                });

                this.$store.commit('setDataStorageHistorical', dataset.data);
                this.loading = false;
                this.$root.$emit('finishedFetch');               
			});
        }
    }
}
</script>
<template>
  <div class="overlay" :class="overlayBackground">
    <template v-if="!loading">
      <a v-if="!hideCloseButton" class="close" @click="closeModal()"></a>
      <div class="container">
        <div class="form-block">
          <div class="headerWrapper">
            <header>
              <slot name="header"></slot>
            </header>
            <div class="subHeader">
              <slot name="subHeader"></slot>
            </div>
          </div>
          <div class="formContentWrapper">
            <slot name="formContent">
               
            </slot>
            <div class="form-row">
              <div class="col d-flex" :class="buttonWrapperClass">
                <div class="form-group submit">
                  <sb-button 
                      variation="primary"
                      submit="submit"
                      v-if="!hideSubmitButton"
                      @click='modalSubmitted()'
                      :color="buttonColor"
                  >
                    {{submitText}}
                  </sb-button>
                  <slot name="extraFormButtons" :style="{'display': inline-block}"></slot>
                </div>
              </div> <!-- col -->
            </div> <!-- form-row -->
          </div> <!-- formContentWrapper -->
        </div>
        <slot name="afterForm"></slot>
      </div> <!-- container -->
      <div>
        <slot name="bottomOfScreen"></slot>
      </div>
    </template>
  </div> <!-- overlay -->
</template>

<script>
  export default {
    name: 'FullScreenModal',
    components: {
    },
    data () {
      return {
        mainContentMaxHeightOverwrite: '100vh',
        mainContentOverflowOverwrite: 'hidden'
      }
    },
    created: function () {
      this.styleMainContent(this.mainContentMaxHeightOverwrite, this.mainContentOverflowOverwrite)
    },
    destroyed: function () {
      this.resetMainContent()
    },
    props: {
      'submitText': {
        Type: String,
        default: 'Submit'
      },
      'buttonColor': {
        Type: String,
        default: 'blue'
      },
      'bgColor': {
        Type: String,
        default: 'white'
      },
      'buttonLocation': {
        Type: String,
        default: 'right'
      },
      'hideSubmitButton': {
        Type: Boolean,
        default: false
      },
      'loading': {
        Type: Boolean,
        default: false
      },
      'transitionTime': {
        Type: Number,
        default: 0
      },
      'hideCloseButton': {
        Type: Boolean,
        default: false
      },
    },
    methods: {
      closeModal () {
        this.$emit('close-modal')
      },
      modalSubmitted () {
        this.$emit('modal-submitted')
      },
      styleMainContent (maxHeight, overflow) {
        // var mainContent = document.getElementById('appContainer') 
        // if(mainContent.style!= undefined){
        //   if (mainContent.style.maxHeight !== this.mainContentMaxHeightOverwrite) {
        //     mainContent.dataset.maxHeight = mainContent.style.maxHeight
        //     mainContent.style.maxHeight = maxHeight
        //   }
        //   if (mainContent.style.overflow !== this.mainContentOverflowOverwrite) {
        //     mainContent.dataset.overflow = mainContent.style.overflow
        //     mainContent.style.overflow = overflow
        //   }
        // }
      },
      resetMainContent () {
        var mainContent = document.getElementById('appContainer') 

        setTimeout(() => {
          mainContent.style.maxHeight = mainContent.dataset.maxHeight
          mainContent.style.overflow = mainContent.dataset.overflow
          mainContent.dataset.maxHeight = ''
          mainContent.dataset.overflow = ''
        }, this.transitionTime)
      }
    },
    computed: {
      overlayBackground () {
        switch (this.bgColor) {
          case 'blue': return 'overlay-blue'
          case 'white':
          default: return 'overlay-white'
        }
      },
      buttonWrapperClass () {
        switch (this.buttonLocation) {
          case 'left': return 'buttons-left'
          case 'center': return 'buttons-center'
          case 'right':
          default:
            return 'buttons-right'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/shipbob-custom//components/layout/_full-screen-modal';
</style>



<template>
<div class="info custom-tooltip ml-2">
  <span class="icon icon-info-circle"></span>
   <sb-tooltip  class="tooltip headerStyle nameStyle qtyStyle" orientation="right"  >
      <p v-if="header != undefined" class="headerStyle">
        <strong>{{header}}</strong>
      </p>
      <p v-if="paragraph != undefined" class="nameStyle">{{paragraph}}</p> 
      <p v-if="paragraph1 != undefined" class="nameStyle">{{paragraph1}}</p> 
      <p v-if="paragraph2 != undefined" class="nameStyle">{{paragraph2}}</p> 
      <p v-if="paragraph3 != undefined" class="nameStyle">{{paragraph3}}</p> 
       <p v-if="footer != undefined" class="tooltip-footer">
                        {{footer}}
                    </p>
    </sb-tooltip>
    </div>
</template>
<script>

export default {
    props:['header', 'paragraph','paragraph1','paragraph2','paragraph3','footer']    
    
}
</script>

<style  lang="scss" scoped>
@import '@/../assets/scss/_variables.scss'; 
.tooltip {
  display: grid;
  padding: 1rem;
  grid-template-columns: "3fr 1fr"; 
  max-width: 300px;
}
.info { 
  //float:right;

}
.nameStyle {
  grid-column-start: 1;
  grid-column-end: 3;
  color: rgb(115, 120, 131);
  font-size: 0.875rem;
} 
</style>
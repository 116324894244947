<template>
  <div>
    <sb-table
      :columnDefinitions="columnDefinitions"
      :rows="tableHelper.pagedData"
      :loadingPromise="loadingPromise"
      @sort="handleSort"
      :defaultSortColumn="defaultSortColumn"
      :defaultSortOrdering="defaultSortOrdering"
    >
      <sb-toolbar slot="sb-toolbar"  >
        <!-- <h3 class="title"></h3> -->
        <!-- <div class="search-wrapper"></div> -->
         <from-date-filter></from-date-filter>
    <span class="date-filter-span"> to </span>
       <to-date-filter></to-date-filter> 
        <sb-dropdown-search
          v-model="selected1"
          :options="options1"
          @input="changeItem1()"
          :minSearchTermLength="0"
          @search="handleSearch"
          :showSelectedOptions="true"
          :comparator="comparator"
           
          class="select-size-400"
          placeholder="Search by inventory name, or ShipBob ID.."
        >
          <sb-selected-dropdown-search-option
            slot="selection"
            slot-scope="{ selection }"
            class="selection-placeholder"
            :value="selection.text || 'Search by inventory name, or ShipBob ID..'"
          />
          <sb-dropdown-option
            slot-scope="{ option }"
          >{{ option.value==0?option.text: option.value + " - " + option.text }}</sb-dropdown-option>
        </sb-dropdown-search>
        <sb-dropdown-search
          v-model="selected2"
          :options="options2"
          @input="changeItem2()"
          :minSearchTermLength="0"
          @search="handleSearch2"
          :showSelectedOptions="true"
          :comparator="comparator"
          
          class="select-size-400"
          placeholder="Lot Number"
        >
          <sb-selected-dropdown-search-option
            slot="selection"
            slot-scope="{ selection }"
            class="selection-placeholder"
            :value="selection.text || 'Lot Number'"
          />
          <sb-dropdown-option
            slot-scope="{ option }"
          >{{  option.text  }}</sb-dropdown-option>
        </sb-dropdown-search>
        <sb-button
          @click="load()"
          variation="primary"
          size="medium"
          @click.prevent
        >Apply</sb-button> 
       
      </sb-toolbar>
      <sb-table-row slot="sb-table-row" slot-scope="{row}">
        <sb-table-cell>{{row.snapshotdate}}</sb-table-cell>
        <sb-table-cell  >
          <a :href="app_link+row.inventoryId" target="_blank" rel="noopener noreferrer">
            <vs-tooltip :text="row.inventoryId + ' : ' + row.itemName" position="top">
              <p class="mb-0">{{row.itemName}}</p>
            </vs-tooltip>
          </a>
        </sb-table-cell>
        <sb-table-cell>{{row.allShipbbFCsExcludingTransfers}}</sb-table-cell>
        <sb-table-cell>{{row.chi}}</sb-table-cell>
        <sb-table-cell>{{row.nypa}}</sb-table-cell>
        <sb-table-cell>{{row.lamv}}</sb-table-cell>
        <sb-table-cell>{{row.da}}</sb-table-cell>
        <sb-table-cell>{{row.cha}}</sb-table-cell> 
        <sb-table-cell>{{row.stp}}</sb-table-cell> 
        <sb-table-cell>{{row.ott}}</sb-table-cell>
        <sb-table-cell>{{row.carr}}</sb-table-cell>
        <sb-table-cell>{{row.inInternalTransfer}}</sb-table-cell>
        <!-- <sb-table-cell>{{row.allShipbobFcsInteralTransfers}}</sb-table-cell>
        <sb-table-cell>{{row.inExternalTransfer}}</sb-table-cell>
        <sb-table-cell>{{row.amazon}}</sb-table-cell> -->
      </sb-table-row>
      <sb-table-empty-state slot="sb-table-empty-state" icon-name="products" label="No Results">
        <p>Select inventory to see the daily historical quantities.</p>
      </sb-table-empty-state>
      <pagination-wrapper
        slot="pagination"
        @fetchPage="handleFetchPage"
        :pageSize="tableHelper.pagination.pageSize"
        :currentPage="tableHelper.pagination.currentPage"
        :totalPages="tableHelper.pagination.totalPages"
        :totalRecords="tableHelper.pagination.totalRecords"
        :visibleRecords="tableHelper.pagination.visibleRecords"
      ></pagination-wrapper>
    </sb-table>
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import { cloneDeep } from "lodash";
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";
import Vue from "vue";

export default {
  components: {
    "pagination-wrapper": PaginationWrapper
  },
  data() {
    return {
      app_link: process.env.VUE_APP_SHIPBOB_LINK+'app/Merchant/#/Products/', 
      selected1: 0,
      selected2: 0,
      comparator(a, b) {
        return a.id === b.id;
      },
      recievedOptions1: [],
      recievedOptions2: [],
      options1: [],
      options2: [],
      loadingPromise: null,
      columnDefinitions: [
        { name: "Day", field: "snapshotdate", sortable: true },
        { name: "Inventory Name", field: "itemName", sortable: true },
        { name: "All FCs", field: "allShipbbFCsExcludingTransfers", sortable: true },
        { name: "Chi", field: "chi", sortable: true },
        { name: "PA", field: "nypa", sortable: true },
        { name: "MV", field: "lamv", sortable: true },
        { name: "DAL", field: "da", sortable: true },
        { name: "CHA", field: "cha", sortable: true },
        { name: "STP", field: "stp", sortable: true },
        { name: "OTT", field: "ott", sortable: true },
        { name: "CARR", field: "carr", sortable: true },
        { name: "SB Transfer", field: "inInternalTransfer", sortable: true }
        // ,
        // { name: "SB Network", field: "allShipbobFcsInteralTransfers", sortable: true },
        // { name: "Ext Transfer", field: "inExternalTransfer", sortable: true },
        // { name: "Amazon", field: "amazon", sortable: true }
      ],
      tableHelper: new tabler(this, 30)
    };
  }, 
  mounted()
  {
    this.loadInventoryList();
  //this.loadingPromise =  this.fetch(1);
  },
  destroyed() {},
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },
  methods: {
    handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    map(item) {
      return {
        snapshotdate: this.dateFormat(item.snapshotdate),
        inventoryId : item.inventoryId,
        itemName : item.itemName,
        allShipbbFCsExcludingTransfers: item.allShipbbFCsExcludingTransfers,
        chi: item.chi,
        nypa: item.nypa,
        lamv: item.lamv,
        da: item.da,
        cha: item.cha,
        stp: item.stp,
        carr: item.carr,
        ott: item.ott,
        inInternalTransfer: item.inInternalTransfer,
        allShipbobFcsInteralTransfers: item.allShipbobFcsInteralTransfers,
        inExternalTransfer: item.inExternalTransfer,
        amazon: item.amazon
      };
    },
    dateFormat(date) {
      return moment(new Date(date)).format("MM/DD/YYYY");
    },
    changeItem1() {
      this.$store.commit("setihid_itemID", this.selected1.value);
      this.updateLot();
    },
    changeItem2() {
      this.$store.commit("setihid_lotid", this.selected2.value);
    },
    handleSearch({ searchTerm }) {
      if (searchTerm.length > 1) {
       searchTerm = searchTerm.toLowerCase();
        this.options1 = cloneDeep(
          this.recievedOptions1.filter(
            x =>
              x.text.toLowerCase().indexOf(searchTerm) !== -1 ||
              x.value.toString().indexOf(searchTerm) !== -1
          )
        );
      } else this.options1 = this.recievedOptions1;
    },
    handleSearch2({ searchTerm }) {
      if (searchTerm.length > 1) {
       searchTerm = searchTerm.toLowerCase();
        this.options2 = cloneDeep(
          this.recievedOptions2.filter(
            x =>
              x.text.toLowerCase().indexOf(searchTerm) !== -1 ||
              x.value.toString().indexOf(searchTerm) !== -1
          )
        );
      } else this.options2 = this.recievedOptions2;
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    },
    load(){ 
        this.loadingPromise = this.fetch(1);
    },
    fetch: async function(p) {
      
      await new Promise(res => {
        this.tableHelper.preLoadReset(); 
        
        this.options2 = [];
        this.recievedOptions2 = [];
        var lot = this.$store.state.ihid_lotid;
        
        if (
          this.$store.state.ihid_lotid == undefined ||
          this.$store.state.ihid_lotid == 0
        ) {
          lot = null;
        }
        let inventoryHistoryItemConfig = {
          fromDate: moment(new Date(this.$store.state.from)).format(
            "MM/DD/YYYY"
          ),
          toDate: moment(new Date(this.$store.state.to)).format("MM/DD/YYYY"),
          lotId: lot
        };

        var paramsURL = "";
        if (
          this.$store.state.ihid_itemID == undefined ||
          this.$store.state.ihid_itemID == 0
        )
        {
           paramsURL =
            "inventory/history/all";
          //  Vue.$toast.open({
          //     message: "Select an inventory item to see the daily historical quantities.",
          //     type: "info",
          //     position: "top-right",
          //     duration: 5000
          //   });
        }
        if (
          this.$store.state.ihid_itemID != undefined &&
          this.$store.state.ihid_itemID != 0
        ) {
           paramsURL =
            "inventory/" + this.$store.state.ihid_itemID + "/history/";
        }

       

          axiosInstance(
            "GET",
            process.env.VUE_APP_ENDPOINT + paramsURL,
            inventoryHistoryItemConfig
          ).then(response => {
            let InventoryHistoryItems = response.data.data;
            this.tableHelper.loadData(InventoryHistoryItems, p);
 
            this.$store.commit(
              "setDataInventoryHistoryByItem",
              InventoryHistoryItems
            );
             this.$root.$emit("finishedFetch");
             res(true);
          });

       
      });
    },
    loadInventoryList()
    { 
      this.options1 = [];
         this.recievedOptions1 = [];

 let inventoryItemsConfig = {
          IsActive: true,
          IsMerged: false,
          IsBundle: false
        };
 
        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "inventory/",
          inventoryItemsConfig
        ).then(response => { 
          response.data.data.forEach(item => {
            this.recievedOptions1.push({ text: item.name, value: item.inventoryId });
            this.options1.push({ text: item.name, value: item.inventoryId });
          });
         
        });  
    },
    updateLot() {

       var paramsURL = "";
        if (
          this.$store.state.ihid_itemID == undefined ||
          this.$store.state.ihid_itemID == 0
        )
        { 
           Vue.$toast.open({
              message: "Select an inventory item to see the daily historical quantities for lots.",
              type: "info",
              position: "top-right",
              duration: 5000
            });
            return;
        }
         

      if (
        this.$store.state.ihid_itemID != undefined &&
        this.$store.state.ihid_itemID != 0
      ) {
        let inventoryHistoryLotConfig = {
          startDate: moment(new Date(this.$store.state.from)).format(
            "MM/DD/YYYY"
          ),
          endDate: moment(new Date(this.$store.state.to)).format("MM/DD/YYYY")
        };

        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT +
            "inventory/" +
            this.$store.state.ihid_itemID +
            "/history/lots",
          inventoryHistoryLotConfig
        ).then(response => {
          this.recievedOptions2 = [];
          this.options2 = [];
          this.recievedOptions2.push({ text: "All Lots", value: 0 });
          this.options2.push({ text: "All Lots", value: 0 });
          response.data.forEach(item => {
            this.recievedOptions2.push({ text: item.id, value: item.id });
            this.options2.push({ text: item.id, value: item.id });
          });
        });
      }
    }
  }
};
</script>
 

import { render, staticRenderFns } from "./Title.vue?vue&type=template&id=e8979bea&scoped=true&"
import script from "./Title.vue?vue&type=script&lang=js&"
export * from "./Title.vue?vue&type=script&lang=js&"
import style0 from "./Title.vue?vue&type=style&index=0&id=e8979bea&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8979bea",
  null
  
)

component.options.__file = "Title.vue"
export default component.exports
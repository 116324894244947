<template>
  <div class="main-content">
    <div class="on-hand-placeholder-text">
      On Hand inventory levels can be found in the <a :href="inventoryStatusPageUrl" target="_blank">Inventory Status</a> page.
      <img class="on-hand-placeholder-img" src="/img/on-hand-placeholder-image-only.png"/>
    </div>
  </div>
</template>
<script> 
export default {
  computed: {
    inventoryStatusPageUrl () {
      return process.env.VUE_APP_SHIPBOB_SITE + "/app/merchant/#/inventory/all?option=Active&pageNumber=1&pageSize=25"
    }
  }
};
</script>
<style>
.on-hand-placeholder-img {
  height: auto;
  width: 100%;
}
.on-hand-placeholder-text {
  text-align: center;
  font-weight: bolder;
  margin-top: 10px;
  color: black;
  width: 90%;
}
</style>

import { VendorsManagerBuilder, IntercomManagerConfigurer, User } from '@shipbob/vendors-manager'
import store from '@/store'

const getDataForVendors = async () => {
  const [ user ] = await Promise.all([
    store.dispatch('fetchDetailedUser')
  ])
  return {
    user
  }
}

class VendorsManager {
  constructor () {
    this._manager = null
  }

  async start () {
    if (this._manager) return
    // Get data for vendors manager
    const { user } = await getDataForVendors()
    const mappedUser = new User({
      id: user.UserId,
      email: user.Email,
      name: `${user.FirstName} ${user.LastName}`
    })

    // Setup vendors manager
    const intercomAppId = process.env.VUE_APP_INTERCOM_APP_ID
    const intercomConfigurer = new IntercomManagerConfigurer()
        .withAppId(intercomAppId)
        .withSettings({ app_id: intercomAppId })
        .withMessengerAttributes({
          'hide_default_launcher': true
        })

    const builder = new VendorsManagerBuilder()
                      .withUser(mappedUser)
                      .withIntercom(intercomConfigurer)

    this._manager = builder.build()

    // Start vendors
    this._manager.intercom.boot()
  }
  shutdown () {
    if (!this._manager) return
    this._manager.intercom.shutdown()
  }
}

export default new VendorsManager()

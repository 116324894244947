// import { HttpUtil } from '../utils/http'
import HttpWrapper from '../utils/http/httpWrapper'
//import ShipbobAppSettings from '../appSettings/appSetting'
import AuthTokenUtil from '../utils/http/authToken'

export default class AuthorizationService {
  static noop () {}

  static urls () {
    return {
      refreshToken: process.env.VUE_APP_SHIPBOB_SITE + '/api/account/refreshToken',
      // refreshToken: ShipbobAppSettings.refresh + '/api/user/refreshToken',
      authenticate: process.env.VUE_APP_SHIPBOB_AUTHENTICATE + '/connect/accesstokenintrospectionvalidation'
    }
  }
  static async refreshToken () {
    const { refreshtoken: refreshToken } = AuthTokenUtil.extractAuth()
    let auth = AuthTokenUtil.extractAuth()
     
    if (typeof refreshToken === 'undefined' || auth.refreshtoken == '') return false
     
    const request = {
      // Url: `${this.urls().refreshToken}?refreshToken=${refreshToken}`
      Url: this.urls().refreshToken,
      Params: { refreshToken }
    }
    return await HttpWrapper.Get(request, null, null, true)
  }

  static async authenticate () {
    const { accesstoken: token } = AuthTokenUtil.extractAuth()
    if (typeof token === 'undefined') return false
    const request = {
      Url: `${this.urls().authenticate}?token=${token}`
    }
    return await HttpWrapper.Get(request, null, null, true)
  }
}

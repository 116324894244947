<template>
    <div style="min-height:600px;"> 
        <!-- <vs-row vs-w="12" vs-justify="flex-start" vs-align="center">
            <vs-col vs-w="12" vs-justify="center" vs-align="center">
                <h3 class="h3 text-center">Selected dates:</h3>
                <h2 class="title-dates text-center mt-0">{{this.selectedDates}}</h2>
            </vs-col>
        </vs-row>  -->
        
        <vs-row vs-w="12" class="my-3" vs-type="flex" >
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <b-form inline vs-justify="space-around">
                    <sb-dropdown :options="options1" v-model="selectOptions"  class="dropdown-filter filter-shipping-method-small" placeholder="All shipping methods" >
                        <sb-selected-option slot="selection" slot-scope="{ selected }" >
                            {{selectOptions1.length }} Filters Selected
                        </sb-selected-option>
                        <sb-options>
                            <sb-option slot-scope="{ option }" :value="option">
                                {{ option.text }}
                            </sb-option>
                        </sb-options>
                    </sb-dropdown>
                    
                </b-form>
            </vs-col>
        </vs-row>
        <vs-row vs-w="12" class="mb-3" vs-type="flex" vs-justify="space-between" vs-align="center">
            <vs-col vs-w="12">
                <SbDismissibleChip v-for="f of selectOptions1" v-on:close="removeSelectedOption(f)"   class="SbDismissibleChip-wrapper">
                    {{f.text}}
                </SbDismissibleChip>
            </vs-col>
        </vs-row>
        <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        <div v-else  style="margin-top:15px;"  >
            <vs-row vs-justify="center">
                <vs-col vs-w="12">
                     <div class="deliverydays-Donut-canvas canvas">
                    <doughnut-chart :data="data.currentDistributionDataset" :options="data.currentDistributionOptions" :labels="data.currentDistributionLabels"></doughnut-chart>
                </div>
                </vs-col> 
            </vs-row>
             <vs-row    vs-justify="space-around" class="accotation-container">
                <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="12">
                    <div class="accotation-item">
                        <vs-row vs-justify="center">
                            <vs-col class="centered" vs-justify="center">
                                
                                <p>Percent of {{TotalDelivered}} orders</p>
                            </vs-col>
                        </vs-row>
                    </div>
                 </vs-col>
            </vs-row>
            <vs-row class="accotation-container">
                <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="12"> 
                    <accotation v-for="item in data.currentDistributionAccotations" :title="item.title + ' Days'" :percentage="item.percentage" :key="item.id" :color="item.colorClass"></accotation>
                </vs-col>
            </vs-row> 
        </div>
    </div>
   
</template>
<script>
import axiosInstance from '../../../helpers/ws';
import moment from 'moment';

export default {
    data() {
        return {
            options1: [],
            selectedDates: '',
            selectOptions1: [],
            data: {
                totalShippingCost: 0,
                numberOrders: 857,
                shippingCost: '$ 14.32',
                avgZone: 4.5,
                currentDistributionDataset:[],
                currentDistributionLabels:[],
                currentDistributionOptions:{              
                    responsive: true, 
                    maintainAspectRatio: false, 
                    bezierCurve : true,
                    legend:{display:false}, 
                    cutoutPercentage: 70 
                },
                currentDistributionAccotations: [],
                availableColors: ['green1', 'blue3', 'blue2', 'gray', 'gray2']
            },
            loading: false,
            TotalDelivered: ""
        }
    },
    mounted() {
        this.fetch();
        this.getSelectsOptions();
        this.getSelectedDates();
        this.$root.$on('update', this.update); 
    },
    computed:{
        selectOptions: {
                get () {
                return this.selectOptions1
                },
                set (selection) {   
                this.addSelection(selection);
                this.update();
                }
            } 
    },
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        update(){
            this.fetch();
            this.getSelectsOptions();
            this.getSelectedDates();
        },
        removeSelectedOption(filter){ 
            const index = this.selectOptions1.findIndex(f => f.text == filter.text)
            this.selectOptions1.splice(index, 1);
            this.fetch();
        },
         addSelection(selection)
        { 
            this.selectOptions1=[];
            selection.forEach(item=>{
                this.selectOptions1.push(item);
            }); 
        },
        getSelectsOptions () {
            this.options1 = [];

            let storesShippingConfig = {
				from: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				to: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY')
                // from: '2019-01-01',
				// to: '2019-01-01'
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'Lookup/storeshippingmethods', storesShippingConfig).then((response) => {
                let data = response.data; 
                data.data.forEach((item) => {
                    this.options1.push({ text: item.displayValue, value: item.storeShippingMethod})
                });
            });
        },
        getSelectedDates(){
             let config = {
                fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
				toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY')
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'inventory/sales/sum/0', config).then((response) => {
                let data = response.data.data;
                this.selectedDates= data.selectedDates;
                this.$store.commit('setTransitTimeDates', this.selectedDates);
                this.$root.$emit("finishedsetTransitTimeDates");
            });
        },
        fetch(){
            this.loading = true;
            this.data.currentDistributionDataset = [];
            this.data.currentDistributionLabels = [];
            this.data.currentDistributionAccotations = [];

            let date = new Date();
            let to = date.setDate(date.getDate() -1);
            let from = date.setDate(date.getDate() - 30);

            let strStoreShippingMethod  = '';
            if(this.selectOptions1.length > 0){
                this.selectOptions1.forEach((item, index) => {
                    strStoreShippingMethod += item.value;
                    if(index < (this.selectOptions1.length -1)){
                        strStoreShippingMethod += ',';
                    }
                });
            } else {
                strStoreShippingMethod  = '0';
            }

            let ordersConfig = {
                fromDate: moment(new Date(this.$store.state.from)).format('MM/DD/YYYY'),
                toDate: moment(new Date(this.$store.state.to)).format('MM/DD/YYYY'),
                // fromDate: '2019-01-01',
                // toDate: '2019-01-01',
                storeShippingMethod: strStoreShippingMethod
            }

            axiosInstance("GET", process.env.VUE_APP_ENDPOINT + 'orders/tracking/cummulativeDaysInTransit', ordersConfig).then((response) => {
                let data = response.data.data;

                let i = 0;
                let datacdd = [];
                let datacdl = [];
                let dataColors = [];

                for(let item in data) {
                    // console.log(data[item]);
                    this.data.currentDistributionAccotations.push({
                        id: i,
                        title: data[item].avgDaysInTransit.toUpperCase(),
                        percentage:  data[item].percentOfTotalOrders,
                        colorClass: this.getColor(data[item].avgDaysInTransit.toUpperCase(), true)                        
                    });

                    datacdd.push(data[item].percentOfTotalOrders);
                    datacdl.push(data[item].avgDaysInTransit.toUpperCase());
                    dataColors.push(this.getColor(data[item].avgDaysInTransit.toUpperCase(), false));
                    i++;
                }

                if (data.length>0)
                {
                    this.TotalDelivered = this.numberWithCommas(data[0].totalDelivered);
                }else
                {
                    this.TotalDelivered = "";
                }
                this.data.currentDistributionDataset = [{
                    data: datacdd,
                    backgroundColor: dataColors
                }];
                this.data.currentDistributionLabels = datacdl;

                this.loading = false;
            });
        },
        getColor(key, flag) {
            let color = "";
            switch (key) {
                case '1-2': color = (flag) ? 'color-chi' : '#2470AF'; break;
                case '3-5': color = (flag) ? 'color-da' : '#343E4F'; break;
                case '6-8': color = (flag) ? 'color-mv' : '#59BAEF'; break;
                case '9-12': color = (flag) ? 'color-pa' : '#92EFAC'; break;
                case '13+': color = (flag) ? 'color-sf' : '#509B63'; break;
                default: color = (flag) ? 'gray2' : '#888a8e';
            }
            return color;
        }
    }
}
</script>
<style scoped lang='scss'>
 
 .h6{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
 }
  .h6-Color{
     font-size: 1rem;
     margin-bottom: 0px;
    text-align:center;
    color: #b1b1b1;
 }

</style>
 
